<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'guide' } })

import GuideProductList from '@/pages/guide/components/GuideProductList.vue'
import IconArrow03 from '@/components/svg/IconArrow03.vue'
import IconArrow04 from '@/components/svg/IconArrow04.vue'

const tabs = [
  { label: '서비스 설명', value: 'tab1' },
  { label: '추천', value: 'tab2' },
  { label: '예시 화면', value: 'tab3' },
]

const currentTab = ref(tabs[0].value)

const galleryItems = {
  all: [
    {
      title: '만세력 이야기',
      subtitle: '임대표님이 알려주는',
      type: '학습자용',
      txt1: '성향분석',
      txt2: '사주로 보는 성향분석',
      txt3: '사주 오행 분석으로 나에 대해 알아보기',
      txt4: '#키워드',
      src: '/src/assets/images_manse/thumb01.png',
    },
    {
      title: '만세력 이야기',
      subtitle: '임대표님이 알려주는',
      type: '학습자용',
      txt1: '성향분석',
      txt2: '사주로 보는 성향분석',
      txt3: '사주 오행 분석으로 나에 대해 알아보기',
      txt4: '#키워드',
      src: '/src/assets/images_manse/thumb02.png',
    },
    {
      title: '만세력 이야기',
      subtitle: '임대표님이 알려주는',
      type: '학습자용',
      txt1: '성향분석',
      txt2: '사주로 보는 성향분석',
      txt3: '사주 오행 분석으로 나에 대해 알아보기',
      txt4: '#키워드',
      src: '/src/assets/images_manse/thumb01.png',
    },
  ],
  menu1: [
    { title: '만세력 이야기', src: '/src/assets/images_manse/thumb02.png' },
    { title: '만세력 이야기', src: '/src/assets/images_manse/thumb01.png' },
  ],
  menu2: [
    { title: '만세력 이야기', src: '/src/assets/images_manse/thumb01.png' },
    { title: '만세력 이야기', src: '/src/assets/images_manse/thumb01.png' },
  ],
}
const toggleSelectedClass = (isSelected) => {
  document.querySelectorAll('.btn-tab-guide').forEach((button) => {
    button.classList.toggle('selected', isSelected && button.dataset.value === currentTab.value)
  })

  const tabGuideList = document.querySelector('.tab-guide-list')
  if (tabGuideList) {
    tabGuideList.classList.toggle('selected', isSelected)
  }

  const tabGuideContent = document.querySelector('.guide-pro-cont')
  if (tabGuideContent) {
    tabGuideContent.classList.toggle('selected', isSelected)
  }
}

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId)
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' })
    currentTab.value = sectionId
    //toggleSelectedClass(true)
  }
}

// 스크롤 이벤트 리스너 추가
window.addEventListener('scroll', () => {
  const scrollPosition = window.scrollY // 현재 스크롤 위치
  const section = document.getElementById(currentTab.value) // 현재 탭에 해당하는 섹션

  if (section) {
    const sectionTop = section.getBoundingClientRect().top + window.scrollY // 섹션의 상단 위치

    if (scrollPosition < sectionTop - 200) {
      toggleSelectedClass(false)
    } else if (scrollPosition >= sectionTop + 200) {
      toggleSelectedClass(true)
    }
  }
})

const items = ['소장용', '소장용']
</script>

<template>
  <div class="my-info-wrap">
    <div class="my-tit-box com-bg-my-box h-small">
      <div class="tit">서비스 안내</div>
      <div class="path">
        <ul>
          <li>홈</li>
          <li>서비스 상품 안내</li>
          <li>서비스 안내</li>
        </ul>
      </div>
    </div>
    <div class="myinfo-box com-bg-my-box">
      <div class="guide-tab-content-box">
        <div class="guide-pro-top">
          <button class="txt-like">
            <IconLike class="icon-like active" />
          </button>
          <img src="/src/assets/images_manse/thumb-sub.png" alt="" />
          <div class="text-box">
            <div class="txt1"><span class="txt1-1">사주분석</span></div>
            <div class="txt2">임대표님이 알려주는 만세력 이야기</div>
            <div class="txt3">
              사주 오행 분석으로 나에 대해 알아보기<br />
              사주 오행 분석으로 나에 대해 알아보기
            </div>
            <div class="txt4"><span>#키워드</span><span>#키워드</span><span>#키워드</span></div>
            <div class="txt5">
              <div class="type-box">
                <VSelect :items="items" placeholder="소장용" eager />
              </div>

              <div class="price-box">
                <span class="pirce1">2,000원</span>
                <div class="">
                  <span class=""><span class="pirce2">16%</span></span>
                  <span class="pirce3">1,500원</span>
                </div>
              </div>

              <button class="btn-payment">구매하기</button>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-guide-list">
        <button
          v-for="tab in tabs"
          :key="tab.value"
          class="btn-tab-guide"
          :class="{ active: currentTab === tab.value }"
          @click="scrollToSection(tab.value)"
        >
          {{ tab.label }}
        </button>
      </div>
      <div class="guide-pro-cont">
        <section class="" id="tab1">
          <img src="/src/assets/images_manse/guide-0103.png" alt="" />
          <img src="/src/assets/images_manse/guide-0103.png" alt="" />
        </section>
        <div class="line-box"></div>
        <section class="" id="tab2">
          <img src="/src/assets/images_manse/guide-0102.png" alt="" />
          <img src="/src/assets/images_manse/guide-0102.png" alt="" />
          <img src="/src/assets/images_manse/guide-0102.png" alt="" />
        </section>
        <div class="line-box"></div>
        <section class="" id="tab3">
          <img src="/src/assets/images_manse/guide-0101.png" alt="" />
          <img src="/src/assets/images_manse/guide-0101.png" alt="" />
        </section>
        <div class="line-box"></div>
      </div>
      <section class="btn-com gap-xl ceneter guide-detail-btn-box">
        <button>
          <IconArrow03 />
          <div class="txt-box">
            <span class="txt1">사주로 보는 성향분석</span> <span class="txt2">이전 글 보기</span>
          </div>
        </button>
        <button class="btn-line w-large bg-w" style="width: 240px">목록으로</button>
        <button>
          <div class="txt-box">
            <span class="txt1">나의 사주원국을 쉽게 알아보는 법</span>
            <span class="txt2">다음 글 보기</span>
          </div>
          <IconArrow04 />
        </button>
      </section>
    </div>
  </div>
</template>
