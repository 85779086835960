<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'guide' } })
</script>

<template>
  <div class="my-info-wrap">
    <div class="my-tit-box com-bg-my-box h-small">
      <div class="tit">저장소 정기구독</div>
      <div class="path">
        <ul>
          <li>홈</li>
          <li>서비스 상품 안내</li>
          <li>저장소 정기구독</li>
        </ul>
      </div>
    </div>
    <div class="myinfo-box com-bg-my-box">준비중 입니다.</div>
  </div>
</template>
