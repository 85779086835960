<template>
  <VRow
    v-model="isConfirmDialogVisible"
    class="v-dialog-alert"
    :class="isConfirmDialogVisible ? 'active' : ''"
  >
    <div class="back-full-layer">
      <div class="alert-wrap bottom">
        <div class="tit-box">
          <div class="">{{ title }}</div>
          <div class="">{{ title2 }}</div>
        </div>
        <div class="btn-box bottom">
          <button class="btn-nor bottom left cancel" @click="cancel">취소</button>
          <button class="btn-nor" @click="closeConfirmDialog">{{ text }}</button>
        </div>
      </div>
    </div>
  </VRow>
</template>

<script setup>
import { ref, defineProps } from 'vue'

const router = useRouter()
const props = defineProps({
  title: String,
  title2: String,
  text: String,
  nextUrl: Function,
  closeOpt: String,
})

const isConfirmDialogVisible = ref(false)

const closeConfirmDialog = () => {
  if (props.nextUrl !== '') {
    isConfirmDialogVisible.value = false
    props.nextUrl()
  }
  isConfirmDialogVisible.value = false
}

const openConfirmDialog = () => {
  isConfirmDialogVisible.value = true
}

const cancel = () => {
  isConfirmDialogVisible.value = false
}

// 다이얼로그를 여는 메서드를 외부에서 호출할 수 있도록
defineExpose({ isConfirmDialogVisible, openConfirmDialog })
</script>
<style scoped>
.dialog-title {
  text-align: center; /* 가운데 정렬 */
}
</style>
