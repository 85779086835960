<!-- src/pages/MyPage.vue -->
<script setup>
import PurchaseComplete from './purchaseComplete.vue'
import AlertComponent from '@/pages/manse/components/Alert.vue'
import ConfirmComponent from '@/pages/manse/components/Confirm.vue'
import { getProducts, createOrder, subscrDeleteAll, orderConfirm } from '@/api/PaymentApi'
definePage({ meta: { layout: 'mypage' } })
import { useStore } from 'vuex'
const store = useStore()
const productId = computed(() => store.state.productId)
const { userInfo } = useAuth()
/*
const radioContent = [
  {
    title: '베이직',
    desc: '30건',
    value: 'p_24112700009',
    standard_3: 1,
    discounted_3: 300,
    percent_3: 1,
    standard_6: 600,
    discounted_6: 560,
    percent_6: 5,
    standard_9: 1,
    discounted_9: 900,
    percent_9: 1,
    icon_class: 'ico-basic icon-subscr',
    productId: 'basic',
  },
  {
    title: '클래식',
    desc: '100건',
    value: 'p_24112700010',
    standard_3: 1,
    discounted_3: 700,
    percent_3: 1,
    standard_6: 1300,
    discounted_6: 1200,
    percent_6: 5,
    standard_9: 1,
    discounted_9: 1800,
    percent_9: 1,
    icon_class: 'ico-classic icon-subscr',
    productId: 'classic',
  },
  {
    title: '에너지',
    desc: '200건',
    value: 'p_24112700011',
    standard_3: 1,
    discounted_3: 1000,
    percent_3: 1,
    standard_6: 2000,
    discounted_6: 1800,
    percent_6: 5,
    standard_9: 1,
    discounted_9: 3000,
    percent_9: 1,
    icon_class: 'ico-power icon-subscr',
    productId: 'power',
  },
  {
    title: '하모니',
    desc: '무제한',
    value: 'p_241127000112',
    standard_3: '',
    discounted_3: 2000,
    percent_3: 1,
    standard_6: 4000,
    discounted_6: 3700,
    percent_6: 5,
    standard_9: 1,
    discounted_9: 6000,
    percent_9: 1,
    icon_class: 'ico-orchestra icon-subscr',
    productId: 'orchestra',
  },
]
*/
const radioContent2 = [
  {
    title: '3개월',
    //discount: '',
    //per: '',
    //price: '월 900',
    value: 3,
    icon_class: '',
  },
  {
    title: '6개월',
    //discount: '11,800원',
    //per: '20%',
    //price: '연 9500',
    value: 6,
    icon_class: '',
  },
  {
    title: '9개월',
    //discount: '11,800원',
    //per: '20%',
    //price: '연 9500',
    value: 9,
    icon_class: '',
  },
  {
    title: '12개월',
    //discount: '11,800원',
    //per: '20%',
    //price: '연 9500',
    value: 12,
    icon_class: '',
  },
]

const selectedRadio = ref('p_24112700009')
const selectedRadio2 = ref(3)
const goodsName = ref('베이직 / 월 이용료')
const goodsAmt = ref(900)
const usePeriod = ref('')
const pointAmt = ref(1000)
const currentPoints = ref(1000)
const agree = ref(false)
const paymentComplete = ref(false) // 완료 페이지 관련 상태변수. 필요시 수정
const completeNotice = '월 이용권 구매'

const dialogAlert = ref(null)
const dialogConfirm = ref(null)
const dialogTitle = ref('')
const dialogTitle2 = ref('')
const dialogText = ref('')
const dialogNextUrl = ref('')

const calculateEndDate = (duration) => {
  const startDate = new Date() // 현재 날짜
  let endDate = ''

  endDate = new Date(startDate.setMonth(startDate.getMonth() + duration))
  endDate.setDate(endDate.getDate() - 1)

  // 날짜 형식을 YYYY.MM.DD로 변환
  return `${endDate.getFullYear()}.${String(endDate.getMonth() + 1).padStart(2, '0')}.${String(
    endDate.getDate(),
  ).padStart(2, '0')}`
}

const updateGoodsName = (value) => {
  updateGoods()
}
const updateGoodsName2 = (value) => {
  updateGoods()
}

onMounted(() => {
  updateGoods() // 컴포넌트가 마운트될 때 초기값에 따라 업데이트
})

const updateGoods = async () => {
  await goodsList()
  //첫번째값으로 선택
  const isProductSelected = filteredProducts.value.some(
    (item) => item.productId === selectedRadio.value,
  )

  // 일치하는 데이터가 없을 경우에만 첫 번째 상품으로 selectedRadio 설정
  if (!isProductSelected && filteredProducts.value.length > 0) {
    selectedRadio.value = filteredProducts.value[0].productId // 첫 번째 상품의 productId로 설정
  }
  //const selectedItem = filteredProducts.value.find((item) => item.productId === selectedRadio.value)
  const selectedItem = filteredProducts.value.find((item) => item.productId === selectedRadio.value)

  if (selectedItem) {
    goodsName.value = `${selectedItem.name}`
    goodsAmt.value = selectedItem.price
  } else {
    goodsName.value = ''
    goodsAmt.value = 0
  }
  usePeriod.value = `${new Date().toLocaleDateString()} ~ ${calculateEndDate(
    selectedItem.detail.period,
  )}`

  //이용약관 동의 false
  agree.value = false
}

const router = useRouter()
const goFinish = () => {
  router.push('/mypage/subscrFinish')
}

const orderParam = ref(null)
const popTossPayment = ref(null)
const openPayment = async () => {
  let orderFlag = false
  orderFlag = await getOrderNo()
  if (!orderFlag) return

  popTossPayment.value = true
  await goPayment()
}
const close = () => {
  popTossPayment.value = false
}

//TODO: 결제 초기화
//const clientKey = 'test_gck_oEjb0gm23POLLyEMkv1g8pGwBJn5'
const clientKey = import.meta.env.VITE_TOSS_CLIENTKEY_NOR

const tossPayments = TossPayments(clientKey)
const customerKey = '3H3NxXvLfVUfZ_DPFyQ_61'
const widgets = tossPayments.widgets({
  customerKey,
})

const goPayment = async () => {
  // ------ 주문의 결제 금액 설정 ------
  await widgets.setAmount({
    currency: 'KRW',
    value: orderParam.value.totalAmount,
  })

  await Promise.all([
    // ------  결제 UI 렌더링 ------
    widgets.renderPaymentMethods({
      selector: '#payment-method',
      variantKey: 'DEFAULT',
    }),
    // ------  이용약관 UI 렌더링 ------
    widgets.renderAgreement({ selector: '#agreement', variantKey: 'AGREEMENT' }),
  ])
}

//mypage/subscrFinish?paymentType=NORMAL&orderId=ord_241204000022&paymentKey=tnang20241204150859pLIc8&amount=2970
//01> 결제완료화면 monthFinish.vue / monthFinishFail.vue
//02> orderConfirm 호출해서 승인처리 후 finish 화면으로 이동

const doPayment = async () => {
  await widgets.requestPayment({
    orderId: orderParam.value.orderId,
    orderName: orderParam.value.orderName,
    successUrl: window.location.origin + '/mypage/subscrFinishMonth',
    failUrl: window.location.origin + '/mypage/subscrFinishMonthFail',
    customerEmail: null,
    customerName: userInfo?.name,
    customerMobilePhone: null,
  })
}

const transformedRadioContent = computed(() => {
  // const selectedKey = selectedRadio2.value // 3, 6, 9 중 하나

  // // 선택된 값에 따라 동적으로 필드명을 설정
  // return radioContent.map((item) => ({
  //   ...item,
  //   standard: item[`standard_${selectedKey}`],
  //   percent: item[`percent_${selectedKey}`],
  //   final: item[`discounted_${selectedKey}`],
  // }))
  return filteredProducts.value
})

const formatNumber = (num) => {
  if (num < 1000) return num
  return new Intl.NumberFormat().format(num)
}

const goodsData = ref(null)
let groupedData = ref(null)
let groupedData2 = ref([])
const filteredProducts = ref(null)
//TODO: 상품 목록
const goodsList = async () => {
  const params = { platform: 'WEB', productType: 'PLAN_TICKET', size: -1 }
  const data = await getProducts(params)
  let periodNo = selectedRadio2.value
  await groupData(data)
  filteredProducts.value = data.content.filter((product) => product.detail.period === periodNo)
  //filteredProducts.value = data.content
}

//TODO: createOrder
const getOrderNo = async () => {
  const params = {
    amount: goodsAmt.value,
    pointAmount: 0,
    totalAmount: goodsAmt.value,
    productId: selectedRadio.value,
    orderName: goodsName.value,
  }

  let orderData = null
  try {
    orderData = await createOrder(params)
    orderParam.value = orderData
    return true
  } catch (error) {
    console.log('error', error)
    if (error.response && error.response.status === 400) {
      const errorMessage = error.response.data.detail // 응답 메시지 추출
      showDialogAlert(errorMessage)
    } else {
      showDialogAlert(error.response.data.detail)
    }
    return false
  }
}
const memSubscrDel = async () => {
  const params = {}
  const data = await subscrDeleteAll(params)
  alert('삭제완료')
}

//FIX 알림창
const showDialogAlert = (title, text = '') => {
  dialogTitle.value = title
  if (text) dialogText.value = text
  dialogAlert.value.openAlertDialog()
}

const groupData = async (data) => {
  let acc = []
  groupedData.value = data.content.forEach((item) => {
    const period = item.detail.period
    if (!acc.some((obj) => obj.value === period)) {
      acc.push({
        title: `${period}개월`, // title을 'X개월' 형식으로 설정
        value: period,
      })
    }
    console.log('acc', acc)
    return acc
  }, {})
  groupedData.value = acc
}
</script>

<template>
  <div class="my-info-wrap">
    <div class="my-tit-box com-bg-my-box h-small">
      <div class="tit">월 이용권 구매</div>
      <div class="path">
        <ul>
          <li>홈</li>
          <li>마이페이지</li>
          <li>구독</li>
          <li>낭만 저장소</li>
        </ul>
      </div>
    </div>
    <!-- {{ productId }} -->
    <!-- {{ filteredProducts }} -->
    <!-- {{ selectedRadio }} -->
    <!-- {{ orderParam }} -->
    <!-- {{ groupedData2 }} -->

    <!-- <VBtn variant="plain" color="secondary" @click="goodsList()">상품목록</VBtn> -->
    <!-- <VBtn variant="plain" color="secondary" @click="getOrderNo()">주문번호생성</VBtn> -->
    <VBtn variant="plain" color="secondary" @click="memSubscrDel()">등록된 정보 전체삭제</VBtn>

    <!-- 완료화면 -->
    <div v-if="paymentComplete">
      <PurchaseComplete :product="completeNotice" />
    </div>
    <!-- 기본 구매 화면 -->
    <div v-else>
      <div class="myinfo-box com-bg-my-box pay-box">
        <div class="my-tit-box radio-wrap">
          <div v-for="(month, index) in groupedData" :key="month.value" class="radio-group">
            <input
              :id="month.value"
              type="radio"
              class="radio"
              :name="month.title"
              v-model="selectedRadio2"
              :value="month.value"
              @change="updateGoods"
            />
            <label :for="month.value" class="label" :class="{ last: month.value === 9 }">{{
              month.title
            }}</label>
          </div>
        </div>
        <div class="gap-xxl2"></div>
        <!-- './src/@core/components/app-form-elements/CustomRadiosWithIconImg.vue' 파일에 있음 -->
        <CustomRadiosWithIconImg
          class="radio-custom-01"
          v-model:selected-radio="selectedRadio"
          :radio-content="transformedRadioContent"
          :grid-column="{ sm: '3', cols: '12' }"
          @update:selectedRadio="updateGoodsName"
        />
        <!-- <div class="gap-xxxl"></div> -->
        <!-- <CustomRadiosWithIconImg
        class="radio-custom-01 st-repo-btm"
        v-model:selected-radio="selectedRadio2"
        :radio-content="radioContent2"
        :grid-column="{ sm: '3', cols: '12' }"
        @update:selectedRadio="updateGoodsName2"
      /> -->
        <div class="gap-xxl2"></div>
        <div class="my-line-01"></div>
        <div class="gap-xxl2"></div>
        <!-- <div class="text-right">
          <div class="point-use-box">
            <span class="tit"> 보유 낭만 포인트 </span>
            <div class="user-point">
              <i class="icon-point"></i>
              <span class="remain-point">{{ formatNumber(currentPoints) }} p</span>
              <span class="point-txt-box"
                ><button class="btn" @click="pointIns()">충전</button></span
              >
            </div>
          </div>
        </div> -->
      </div>

      <div class="gap-sm-2"></div>
      <div class="myinfo-box com-bg-my-box pay-box">
        <div class="my-pt-btm-2">
          <dl>
            <dt>• 상품명</dt>
            <dd class="txt1">{{ goodsName }}</dd>
          </dl>
          <dl>
            <dt>• 결제 금액</dt>
            <dd class="txt2">
              <span class="point-amt">{{ formatNumber(goodsAmt) }}원</span>
            </dd>
          </dl>
          <dl>
            <dt>• 이용 기간</dt>
            <dd class="txt1">{{ usePeriod }}</dd>
          </dl>
        </div>
        <VDialog
          v-model="popTossPayment"
          class="v-dialog-sm dialog-st1"
          max-width="800px"
          @click:outside="close"
        >
          <!-- 결제 UI -->
          <div id="payment-method"></div>

          <!-- 이용약관 UI -->
          <div id="agreement"></div>

          <!-- 결제하기 버튼 -->
          <VBtn type="submit" style="width: 80%; margin: 20px auto 40px auto" @click="doPayment">
            결제하기
          </VBtn>
        </VDialog>
        <div class="gap-xxl2"></div>
        <div class="bg-box-01">
          <input type="checkbox" class="checkbox" v-model="agree" />
          결제 내용에 동의합니다.
          <span class="txt-tos">이용약관</span>
        </div>
        <div class="gap-xxl"></div>
        <div class="com-btn-box">
          <VBtn
            type="submit"
            style="width: 260px"
            :disabled="agree === false"
            @click="openPayment()"
          >
            결제하기
          </VBtn>
        </div>
      </div>
      <div class="point-info-box">
        <div class="tit"><span>결제 안내</span> <i class="icon-info"></i></div>
        <ul>
          <li>
            선택하신 요금제는 구매일로부터 설정된 기간 동안 이용 가능합니다. 기간 종료 후에는
            자동으로 서비스가 종료됩니다.
          </li>
          <li>
            지속적인 이용을 원하실 경우, 추가 결제를 진행하실 수 있습니다. 서비스 종료 전 알림을
            통해 사전 안내드리니 안심하고 이용하실 수 있습니다.
          </li>
        </ul>
      </div>
    </div>
    <AlertComponent ref="dialogAlert" :title="dialogTitle" :text="dialogText" />
    <ConfirmComponent
      ref="dialogConfirm"
      :title="dialogTitle"
      :title2="dialogTitle2"
      :text="dialogText"
      :nextUrl="dialogNextUrl"
    />
  </div>
</template>
<style lang="scss" scoped>
.point-use-box {
  display: flex;
  align-items: center !important;
  justify-content: flex-end;
  .tit {
    color: var(--Gray-800, #333);
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 0 !important;
  }
  .txt1 {
    margin-right: 5px;
  }

  .v-field__input {
    text-align: right !important;
  }
}
</style>
<style>
.v-overlay__content {
  background-color: #fff;
}
</style>
