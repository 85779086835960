import { defineStore } from 'pinia';
import { useStore } from 'vuex';
import { getUserInfoAPI } from '@/api/myPage';
import { addUserSelectOptionAPI, getUserSelectOptionAPI, setUserSelectOptionAPI } from '@/api/userSelectOption';
import { DEV_MODE } from '@/main';

const systemId = import.meta.env.VITE_APP_SYSTEMID;

export const useMyInfoStore = defineStore('myinfo', () => {
  const store = useStore()

  const myInfo = reactive({
    name: '',
    nickname: '',
    isUseNickname: false,
    displayName: '',
  });

  /** 이름 또는 닉네임 중 선택된 항목 */
  const displayName = computed(() => {
    // return myInfo.isUseNickname ? myInfo.nickname : 
    return myInfo.name;
  });

  /** 사용자 선택 옵션중 서버 입력값 */
  const defaultOptionData = {
    createdAt: null,
    createdBy: null,
    updatedAt: null,
    updatedBy: null,
  }
  /** 사용자 선택 옵션중 사용자 입력값 */
  const defaultOptions = {
    systemId,
    /** 한국시 적용 여부 (적용 true) */
    isKoreaTime: true,
    /** 조자시, 야자시 여부 (적용: true) */
    earlyTime: true,
    /** 생일 절기(동지, 입춘, 모름) ('spring' | 'winter' | 'unknown') */
    birthType: 'winter',
    /** 역학달력 간지(한글표시: true) */
    useGanJi: true,
    /** 역학달력 음력(표시: true) */
    useLuna: true,
    /** 역학달력 절기(표시: true */
    useTwentyFourSeason: true,
    /** 역학달력 공휴일(표시: true) */
    useHolidays: true,
    /** 언어 표기법 / KOR: 한글 표기 / HANJA: 한자 표기 / MIX: 한자+한글 표기 */
    langType: 'MIX',
    /** 테마 선택 / BACKGROUND: 배경컬러 / TEXT: 글자컬러 / BLACKWHITE: 글자흑백 */
    fiveElementTheme: 'BACKGROUND',
    /** 원국 표기 - HHDDMMYYYY: 시일월년 순 / YYYYMMDDHH: 년월일시 순 */
    wonGukSort: 'HHDDMMYYYY',
  };

  /** 사용자 선택 옵션 */
  const selectedOptions = reactive({
    ...defaultOptionData,
    ...defaultOptions,
  });

  const setMyInfo = ({name, nickname, isUseNickname}) => {
    myInfo.name = name;
    myInfo.nickname = nickname;
    myInfo.isUseNickname = isUseNickname;
  };

  /** 사용자 선택 옵션 업데이트 - selectedOptions */
  const setSelectedOptions = (userSelectOption) => {
    Object.assign(selectedOptions, {
      ...defaultOptions,
      ...userSelectOption,
    });    
  };

  const fetchMyInfo = async () => {
    try {
      const result = await getUserInfoAPI();

      if (result) {
        setMyInfo({
          name: result.name,
          nickname: result.nickname,
          isUseNickname: result.isUseNickname,
        });

        // checkUserSelectOptions(result.id);
      } else {
        setMyInfo({
          name: '회원',
          nickname: '',
          isUseNickname: false,
        });
      };
    } catch (e) {
      DEV_MODE && console.log('useMyInfoStore fetchMyInfo error:', e);
    }
  };

  /** 사용자 옵션 유무 확인 및 업데이트 */
  const checkUserSelectOptions = async (id) => {
    if (!id) return;

    try {
      const savedOptions = await getUserSelectOptionAPI(id);      
      if (savedOptions) setSelectedOptions(savedOptions);
      else addUserSelectOption();
    } catch (e) {
      DEV_MODE && console.error('check option error:', e);
    };
  };

  /** 사용자(회원) 옵션 생성 */
  const addUserSelectOption = async () => {
    const data = { ...defaultOptions };
    try {
      const savedOptions = await addUserSelectOptionAPI(data);
      if (savedOptions) setSelectedOptions(savedOptions);    
    } catch (e) {
      DEV_MODE && console.error('add option error:', e);
    };
  };

  /** 사용자(회원) 옵션 업데이트 */
  const setUserSelectOption = async (key, value) => {
    // const userData = store.getters['user/id']
    const data = {
      ...selectedOptions,
      [key]: value,
    }
    setSelectedOptions(data)

    // if (!userData) {
    //   setSelectedOptions(data)
    // } else {
    //   try {
    //     const savedOptions = await setUserSelectOptionAPI(data);
    //     if (savedOptions) setSelectedOptions(savedOptions);
    //   } catch (e) {
    //     DEV_MODE && console.error('update option error:', e)
    //   }
    // }
  };

  const resetSelectedOption = () => {
    Object.assign(selectedOptions, {
      ...defaultOptionData,
      ...defaultOptions,
    });
  };

  return {
    myInfo,
    displayName,
    selectedOptions,
    setMyInfo,
    fetchMyInfo,
    checkUserSelectOptions,
    setUserSelectOption,
    resetSelectedOption,
  };
});