<script setup>
import { ref, onMounted, watch } from 'vue'
import useFunctions from '@/composables/useFunctions'
import IconPlus from '@/components/svg/IconPlus.vue'
import IconSearch from '@/components/svg/IconSearch.vue'
import { closeMemoList, closeMemoReg, isPopMemoList, isPopMemoReg, openMemoList } from '@/composables/useGroup'
import { delMemo, modMemo, saveMemo } from '@/api/UserGroup'
import AlertComponent from '@/pages/manse/components/Alert.vue'
import { DEV_MODE } from '@/main'

const { ...useFun } = useFunctions()

const props = defineProps({
  name: {
    type: String,
  },
  guestUserId: {
    type: String,
  },
  prMemoListData: {
    type: Object,
    default: null,
  },
  prSelectedMemo: {
    type: Object,
    default: null,
  },
  prMemoModeType: {
    type: String,
    default: null,
  },
  prSelectedMemoVal: {
    type: Object,
    default: null,
  },
  prTransdata: {
    type: Object,
    default: null,
  },
  prInputdata: {
    type: Object,
    default: null,
  },
})

const emit = defineEmits()

const isDialogVisibleSetting = ref(true)

const selectedGuestUserId = ref(null)
const guestUserId = computed(() => {
  const newGuestUserId =
    props.guestUserId ||
    props.prSelectedMemo?.guestUserId ||
    props.prSelectedMemoVal?.guestUserId

  if (newGuestUserId !== undefined && newGuestUserId !== null) {
    selectedGuestUserId.value = newGuestUserId
  }

  return selectedGuestUserId.value
})

/** 메모 검색어 */
const searchKeyword = ref('')
/** 메모 목록의 스크롤 여부 상태값 */
const hasScroll = ref(false);
const listWrapper = ref(null); 

/** 메모 본문 */
const content = ref('')

/** 확인 팝업 상태값 */
const dialogAlert = ref(null)
const dialogTitle = ref('')
const dialogText = ref('')

/** 삭제 확인 팝업 상태값 */
const isConfirmDialogVisibleMemo = ref(false)

/** 작성중인 메모 초기화 */
const resetMemo = () => content.value = ''

/** 스크롤 확인 함수 */
const checkScroll = async () => {
  await nextTick();
  if (listWrapper.value) {
    hasScroll.value =
      listWrapper.value.scrollHeight > listWrapper.value.clientHeight
  }  
}

watch(props.prMemoListData, (newData) => {
  checkScroll()
})

onMounted(async () => {
  // '사주입력' > '메모' 임시 등록할 때 목록호출 방지
  if (props.prMemoModeType !== 'reg_first') await getMemoList()

  if (isPopMemoList) {
    // ResizeObserver 초기화
    const observer = new ResizeObserver(() => {
      checkScroll(); // 메모 목록 크기 변경 시 확인
    });
    // 메모 목록 관찰
    if (listWrapper.value) observer.observe(listWrapper.value);
    // 초기 확인
    checkScroll();
  }
})

watchEffect(() => {
  checkScroll();
});

watch(searchKeyword, () => {
  searchMemoList()
})

watch(
  isPopMemoReg,
  (newState) => {
    if (newState) {
      if (props.prSelectedMemoVal && props.prSelectedMemoVal.content) {
        if (props.prMemoModeType !== 'reg') content.value = props.prSelectedMemoVal.content
      }
    } else {
      resetMemo()
    }
  },
  {immediate: true}
)

/** 메모 목록 불러오기 */
const getMemoList = async (keyword) => {  
  await emit('getMemoList', keyword)
}

/** 메모 검색 목록 불러오기 */
const searchMemoList = async () => {
  await getMemoList(searchKeyword.value)
}

/** 검색어 하이라이트 함수 */
const highlightText = (content, highlight) => {
  if (!highlight) return content // 하이라이트할 텍스트가 없으면 원본 반환
  const regex = new RegExp(`(${highlight})`, 'gi') // 대소문자 구분 없이 하이라이트할 텍스트를 정규 표현식으로 설정
  return content.replace(regex, '<span class="highlight">$1</span>') // 하이라이트된 텍스트에 스타일 적용
}

/** 메모 아이템 클릭 */
const clickMemoItem = (item) => {  
  emit('openMemoView', props.prSelectedMemoVal, item)
}

/** 메모 목록에서 등록 버튼 클릭 */
const clickRegistButton = () => {
  emit('openMemoReg', props.prSelectedMemo)
}

/** 확인 팝업 열기 */
const showDialogAlert = (title, text) => {
  dialogTitle.value = title
  dialogText.value = text
  dialogAlert.value.openAlertDialog()
}

/** 삭제 안내 팝업 닫기 */
const closeConfirm = () => {
  isConfirmDialogVisibleMemo.value = false
}

/** 메모 갯수가 0인지 확인 */
const isEmptyMemoList = (obj) => Object.keys(obj).length === 0

/** '메모 작성'에서 뒤로가기 */
const backPopMemo = () => {
  // const isEmpty = isEmptyMemoList(props.prMemoListData)
  // if (isEmpty) {
  //   closePopMemo()
  // } else {
  //   closeMemoReg()
  //   openMemoList()
  // }
  closeMemoReg()
  openMemoList()
}

/** 메모 팝업 닫기 */
const closePopMemo = () => {
  closeMemoReg()
  closeMemoList()
}

/** 메모 삭제 API 요청 */
const deleteMemo = async () => {
  const formParams = {
    guestUserMemoIds: props.prSelectedMemoVal.guestUserMemoId
  }

  try {
    await delMemo(formParams)
    await emit('listGroupMemoDel')
    closeConfirm()
    backPopMemo()
  } catch (e) {
    DEV_MODE && console.error('deleteMemo error:', e)
  }
}

/** '메모 작성'에서 삭제 버튼 클릭 */
const clickDeleteButton = () => {
  isConfirmDialogVisibleMemo.value = true
}

/** 메모 등록/수정 전 유효성 확인 */
const checkContentLength = () => {
  if (content.value.length === 0) {
    showDialogAlert('내용을 입력해주세요.')
    return
  }
}

/** '메모 작성'에서 수정 버튼 클릭 */
const clickUpdateMemo = async () => {  
  checkContentLength()

  const param = {
    guestUser: {
      guestUserId: guestUserId.value,
    },
    content: content.value,
    guestUserMemoId: props.prSelectedMemoVal.guestUserMemoId,
  };
  
  try {
    await modMemo(param)
    emit('listGroupMemo', '')
    showDialogAlert('수정되었습니다.')
    backPopMemo()
  } catch (e) {
    DEV_MODE && console.error('updateMemo error:', e)
  }
}

/** '메모 작성'에서 등록 버튼 클릭 */
const clickSaveMemo = async () => {
  checkContentLength()

  const param = {
    guestUser: {
      guestUserId: guestUserId.value,
    },
    content: content.value,
  };

  try {
    await saveMemo(param)
    await emit('saveMemoFirst', content.value)
    await emit('listGroupMemo', props.prSelectedMemoVal, selectedGuestUserId.value)
    showDialogAlert('등록되었습니다.')
    backPopMemo()
  } catch (e) {
    DEV_MODE && console.error('saveMemo error:', e)
  }
}

/** '사주입력'에서 메모 임시 저장 */
const clickSaveTempMemo = () => {
  emit('saveMemoFirst', content.value)
  closePopMemo()
}

const nameText = useFun.getUserNameTxt(
  props.name || props.prSelectedMemo.guestUserName
)

const genderText = useFun.getGenderTxt(
  props.prInputdata?.gender
    || props.prSelectedMemo?.gender
    || props.prSelectedMemoVal?.gender
)

const korAgeText = props.prTransdata?.kor_age
  || props.prSelectedMemo?.ageKor
  || props.prSelectedMemoVal?.ageKor

const manAgeText = props.prTransdata?.man_age
  || props.prSelectedMemo?.age
  || props.prSelectedMemoVal?.age

const solarDate = useFun.formatCustomDateTxt(
  props.prTransdata?.solar
    || props.prSelectedMemo?.birth
    || props.prSelectedMemoVal?.birth
) || ''

const solarWeekDay = useFun.getDayOfWeekOne(
  props.prTransdata?.solar
    || props.prSelectedMemo?.birth
    || props.prSelectedMemoVal?.birth
) || ''

const formatNumber = (num) => String(num).padStart(2, '0');
const birthTime = props.prInputdata
  && `${formatNumber(props.prInputdata.hour)}:${formatNumber(props.prInputdata.min)}`
  || props.prSelectedMemo?.birthTime
  || props.prSelectedMemoVal?.birthTime

const solarTime = useFun.getBirthTimeTxt(birthTime) || ''

const solarDateTimeText = ` ${solarDate} (${solarWeekDay}) ${solarTime}`

const lunarDateTimeText = useFun.formatCustomDateTxt(
  props.prTransdata?.lunar
    || props.prSelectedMemo?.lunar
    || props.prSelectedMemoVal?.lunar
)

/** '메모 작성' 탭 하단 버튼 */
const buttons = computed(() => [
  {
    mode: 'edit-delete',
    class: 'btn-del st-memo',
    text: '삭제',
    action: clickDeleteButton,
    disabled: false,
    show: props.prMemoModeType === 'edit',
  },
  {
    mode: 'edit-submit',
    class: 'btn-nor submit',
    text: '수정',
    action: clickUpdateMemo,
    disabled: !props.prSelectedMemoVal || !content.value,
    show: props.prMemoModeType === 'edit',
  },
  {
    mode: 'reg-submit',
    class: 'btn-nor submit',
    text: '등록',
    action: clickSaveMemo,
    disabled: !props.prMemoListData || !content.value,
    show: ['reg', 'list'].includes(props.prMemoModeType),
  },
  {
    mode: 'reg-first-submit',
    class: 'btn-nor submit',
    text: '등록',
    action: clickSaveTempMemo,
    disabled: !props.prSelectedMemo || !content.value,
    show: props.prMemoModeType === 'reg_first',
  },
].filter(btn => btn.show))
</script>

<template>
  <VDialog
    v-model="isDialogVisibleSetting"
    class="v-dialog-sm dialog-st1"
    max-width="400px"
    persistent
  >
    <!-- 메모 목록 화면 -->
    <VCard v-if="!isPopMemoReg" class="br16">
      <div class="memo-inner" style="height: 600px">
        <div class="memo-header">
          <div class="title">
            <span class="name">
              {{ nameText }} 
            </span>
            <span>님 메모</span>
          </div>
          <div class="close" @click="closePopMemo"></div>
        </div>

        <div class="memo-body list">
          <div class="memo-search-section">
            <div class="search-icon">
              <IconSearch @click="searchMemoList" />
            </div>
            <VTextField
              v-model="searchKeyword"
              class="input-box"
              variant="underlined"
              clearable
              maxlength="15"
              placeholder="내용을 검색하세요"
              @keyup.enter="searchMemoList"
              @focus="wordFocus = true"
              @blur="wordFocus = false"
            />
          </div>

          <div 
            ref="listWrapper"
            class="memo-list-section"
            :style="{marginRight: hasScroll ? '6px' : '32px'}">
            <div
              v-for="(monthData, month) in prMemoListData"
              :key="month"
              class="month-row-box">
              <div class="month-title">{{ month }}</div>
              <div
                v-for="data in monthData"
                :key="data?.guestUserMemoId"
                class="month-item-box"
                @click="clickMemoItem(data)">
                <div class="month-item-title" v-html="highlightText(data.content, searchKeyword)"></div>
                <div class="month-item-date">{{ useFun.formatDateHH(data?.createdAt) }}</div>
              </div>
            </div>
          </div>
        </div>

        <button class="memo-add-button" @click="clickRegistButton">
          <IconPlus class="icon" /><span>등록</span>
        </button>
      </div>
    </VCard>

    <!-- 메모 작성/수정 화면 -->
    <VCard v-if="isPopMemoReg" class="br16">
      <div class="memo-inner" style="height: 600px">
        <div class="memo-header">
          <div v-if="prMemoModeType !== 'reg_first'" class="go-back" @click="backPopMemo">
            <IconArrow02 />
          </div>
          <div class="title">메모 작성</div>
          <div class="close" @click="closePopMemo"></div>
        </div>

        <div class="memo-body">
          <div class="memo-info-section">
            <div class="name-content">
              <span class="name">{{ nameText }} 님</span>

              <span class="age">{{`${genderText} ${korAgeText}세 (만${manAgeText}세)`}}</span>
            </div>
            <div class="birth-content">
              <div>
                <span class="text solar">양력</span>
                <span class="text">{{ solarDateTimeText }}</span>
              </div>
              <div>
                <span class="text lunar">음력</span>
                <span class="text">{{ ` ${lunarDateTimeText}` }}</span>
              </div>
            </div>
          </div>

          <div class="memo-input-section">
            <div
              v-if="
                prMemoModeType === 'reg' ||
                prMemoModeType === 'reg_first' ||
                prMemoModeType === 'edit' ||
                prMemoModeType === 'list'
              "
              class="memo-input-box"
            >
              <textarea v-model="content" class="textarea" maxlength="2000" />
              <div class="count">{{ content?.length }} / 2000</div>
            </div>
          </div>

          <div class="btn-com">
            <button
              v-for="btn in buttons"
              :key="btn.mode"
              :class="[btn.class, { 'disabled': btn.disabled } ]"
              :disabled="btn.disabled"
              @click="btn.action"
            >
              {{ btn.text }}
            </button>
          </div>
        </div>
      </div>
    </VCard>
  </VDialog>

  <AlertComponent ref="dialogAlert" :title="dialogTitle" :text="dialogText" />

  <VDialog
    v-model="isConfirmDialogVisibleMemo"
    class="v-dialog-alert"
    :class="isConfirmDialogVisibleMemo ? 'active' : ''"
  >
    <div class="back-full-layer">
      <div class="alert-wrap bottom">
        <div class="tit-box">
          <div class="">삭제하시겠습니까?</div>
        </div>
        <div class="btn-box bottom">
          <button class="btn-nor bottom left cancel" @click="closeConfirm">취소</button>
          <button class="btn-nor" @click="deleteMemo">삭제</button>
        </div>
      </div>
    </div>
  </VDialog>
</template>