<script setup>
import SajuPalza from './components/SajuPalza.vue';
import Services from './components/Services.vue';
import PopSummerTime from './components/PopSummerTime.vue';
definePage({ meta: { layout: 'white' } })

const goYoutube = () => {
  window.open('https://www.youtube.com/@nangmancalendar', '_blank')
}

const goKakao = () => {
  window.open('https://pf.kakao.com/_iyNaG', '_blank')
}

const isPopSummerTime = ref(false);
const openSummer = () => {
  isPopSummerTime.value = true;
}
const closeSummer = () => {
  isPopSummerTime.value = false;
  console.log('호출?')
}
</script>

<template>
<div class="top-area"></div>

  <div class="overlap-box">
    <div class="accurate-saju">
    <div class="title-section first">
      <span class="number">01</span>
      <span class="title">정확한 사주 팔자를 뽑기 위한 노력!</span>
    </div>

    <div class="content">
      <div class="con">
        사주 해석을 위해 가장 먼저 이루어지는 작업은 정확한 사주팔자를 뽑는 것입니다.<br/>
        때문에 <b>사주를 잘못 찾게 되면 엉뚱한 해석으로 역학인과 사주의 주인공 모두가 혼란을 겪을 수 있습니다.</b><br/>
        잘못된 기초 데이터는 해석의 방향을 왜곡시키며, 결국 ‘팥’을 ‘콩’으로 오인하는 오류를 일으키고, <br/>
        신뢰성에 의문을 품게 만드는 씁쓸한 경험을 남길 수 있습니다.<br/>
        사주에는 주인공의 기질, 체질과 같은 고유한 특성, 내면의 에너지, 그리고 숨겨진 가능성을 담고 있다고 보기 때문입니다.
      </div>
      <div class="con">
        명리학은 단순한 길흉 점술이 아닌, <b>음양오행과 우주의 원리로 인간의 삶을 이해하고자 하는 동양 철학의 정수</b>입니다.<br/>
        천문 관측을 통해 시간을 측정하고 기록하는 정교한 역법을 바탕으로 수천 년간 발전해 온 깊이 있는 학문으로,<br/>
        수많은 외세의 침략 속에서도 사상의 의미를 이어가려는 학자들에 의해 오늘날까지 전해져 왔습니다.
      </div>
      <div class="con">
        “오랜 세월 동안 변함없이 이어져 온 명리학의 핵심은 자신을 깊이 이해하고 음양오행의 원리를 통해 세상의 흐름을 파악하는 것입니다.”<br/>
        이는 자신과 세상에 대한 깊은 이해를 바탕으로 내적 조화를 이루고, 외부 환경에 유연하게 대응할 수 있는 힘을 얻기 위함입니다.
      </div>
      <div class="con">
        명리학은 인간의 성격, 재능, 운세의 흐름 등을 분석해 자신의 강점과 약점을 인식하고, 변화하는 환경 속에서 올바른 선택을 할 수 있도록 돕습니다.<br/>
        또한, 음양오행을 통해 세상과의 상호작용을 이해함으로써 나와 타인, 그리고 자연과 조화롭게 연결되는 길을 찾게 됩니다.
      </div>
      <div class="con">
        이러한 과정을 통해 우리는 삶의 방향성을 잃지 않고 외부의 어려움과 혼란 속에서도 자신만의 균형과 평온을 유지하는 지혜를 얻을 수 있습니다.
      </div>
    </div>

    <div class="purpose">
      <div class="quotation-mark left"></div>
      <div class="txt">
        따라서 사주를 잘못 찾아 발생하는 오류는 최대한 없어야 하며,<br />
        바로 이 지점이 <b>낭만 만세력 소명의 시작점</b>입니다.
        <div class="overlay-line"></div>
      </div>
      <div class="quotation-mark right"></div>
    </div>
    </div>
  </div>

  <section class="dark-section">
    <div class="saju-palza">
      <!--설명-->
      <div class="description">
        <div class="title-section second">
        <span class="number">02</span>
        <span class="title">사주팔자란?</span>
      </div>

      <div class="content">
        <div class="con">
          사주팔자는 성별과 생년 월시를<br/>
          <b>음과 양, 오행으로 변환하여 각종 운명을 살피는 학문</b>입니다.
        </div>
        <div class="con">
          <b>사주</b>는 네 개의 기둥을 뜻하며, <br/>
          <b>팔자</b>는 각 기둥을 구성하는 <b>천간과 지지로 이루어진 여덟 글자</b>를 의미합니다.<br/>
          이 여덟 글자가 모여 네 개의 기둥을 형성하므로, 이를 <b>사주팔자</b>라 부릅니다.<br/>
          이 네 기둥은 각각 <b>년, 월, 일, 시</b>를 나타냅니다.
        </div>
        <div class="con">
          사주팔자의 해석은 매우 복잡하고 다채로워, 역술인마다 해석 방식이 달라질 수 있습니다. <br/>
          <b>낭만 만세력은 이러한 차이를 줄이고, 일관되고 표준화된 해석을 제공할 수 있도록 돕고자 합니다.</b>
        </div>
        <div class="con">
          사주팔자는 그 사람의 타고난 배경을 나타낼 뿐, <b>운명은 뜻이 있는 사람을 안내하고,</b><br/>
          <b>뜻이 없는 사람은 질질 끌고 다닌다</b>는 철학자 클레안테스의 말처럼, <br/>
          <b>스스로의 의지에 따라 운명의 흐름은 달라질 것입니다.</b>
        </div>
      </div>
      </div>
      <!-- 화면 예시 -->
      <SajuPalza />
    </div>
  </section>

  <section class="bottom">
    <div class="nangman">
      <div class="title-section first">
        <span class="number">03</span>
        <span class="title">낭만만세력 기본분석</span>
      </div>
      <div class="nangman-description">
        정확한 사주 감정을 위해 낭만 만세력은 만세력의 기본 기능에 더해 여섯 가지의 편의 기능을 제공하여 이용에 도움을 주고자 합니다.<br />
        1900년부터 2100년까지의 육십간지를 태양력(그레고리력)을 기준으로 계산하고, <br />
        사주 해석에 중요한 절입일과 절입시간까지 정확히 반영하여 제공합니다.<br />
      </div>
      <!-- 제공 기능 -->
      <Services 
        @openSummer="openSummer"  
      />
    </div>
    <div class="logo-area">
      <div class="logo"></div>
      <div class="commitment-section">
        <div class="com">
          전문가 및 사주를 연구하는 모든 분들이 정확하고 유용한<br />
          만세력 서비스를 이용하실 수 있도록 낭만만세력이 함께 노력하겠습니다.<br />
          <br />
          감사합니다.
        </div>
      </div>
    </div>
    <div class="sns-area">
      <span class="checkout">낭만만세력 SNS 채널 둘러보기</span>
      <div class="sns-icon">
        <div class="youtube" @click="goYoutube">
          <div class="y-icon"></div>
        </div>
        <!-- <div class="instagram">
          <div class="i-icon"></div>
        </div> -->
        <div class="kakaotalk" @click="goKakao"></div>
      </div>
    </div>
  </section>

<PopSummerTime
  v-if="isPopSummerTime"
  @close="closeSummer()"
  @click:outside="closeSummer()"
/>
</template>
<style lang="scss" scoped>
// TODO: 나중에 분리

.top-area{
// position: relative;
  background: url('@/assets/images_manse/TOP.png') center center no-repeat;
  background-size: 100% auto;
  height: 480px;
  width: 1920px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -64px;
  z-index: 0;
}

.overlap-box{
  display: flex;
  width: 1360px;
  height: 1054px;
  padding: 100px 0px 160px 0px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 40px 40px 0px 0px;
  background: var(--gray-100-white, #FFF);
  z-index: 10;
  margin: -80px auto 0;
  position: relative;
}
.accurate-saju{
  display: flex;
  width: 1160px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  // height: 794px;
  .content{
    display: flex;
    width: 1160px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    padding: 0px;
    .con{
      color: var(--Gray-700, #575757);
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      b{
        color: var(--Gray-700, #575757);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }
    }
  }
}
.purpose{
  width: 720px;
  height: 100px;
  margin-top: 28px;
  position: relative;
  .txt{
    color: var(--Gray-800, #333);
    text-align: center;
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    position: relative;
    b{
      color: var(--Gray-800, #333);
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }
    .overlay-line{
      width: 246px !important;
      height: 14px;
      flex-shrink: 0;
      background: var(--Gray-300, #EBEBEB);
      position: absolute;
      bottom: 4px;
      right: 200px;
      width: 100%;
      z-index: -1;
    }
  }
  .quotation-mark{
    position: absolute;
    background: url('@manseImages/icon_quotation.svg') center center no-repeat;
    background-size: auto 100%;
    width: 40px;
    height: 32px;
    flex-shrink: 0;
    fill: var(--Gray-300, #EBEBEB);
    &.left{
      top: 1px;
      left: 20px;
    }
    &.right{
      bottom: 3px;
      right: 20px;
    }
  }
}
.title-section{
  display: flex;
  flex-direction: column;
  gap: 8px;
  &.first{
    align-items: center;
    .number{
    color: var(--Gray-500, #949494);
    text-align: center;
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 36px;
  }
  .title{
    color: var(--Gray-800, #333);
    text-align: center;
    font-family: Pretendard;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }
  }
  &.second{
    align-items: flex-start;
    .number{
      color: var(--Gray-300, #EBEBEB);
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 36px;
    }
    .title{
      color: var(--gray-100-white, #FFF);
      font-family: Pretendard;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }
  }

}

.dark-section{
  display: flex;
  width: 1920px;
  height: 891px;
  padding: 160px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: var(--Gray-800, #333);
}
.saju-palza{
  display: flex;
  width: 1360px;
  justify-content: space-between;
  align-items: center;
  height: 571px;
  .description{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    height: 542px;
  }
  .content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    .con{
      color: var(--Gray-200, #F8F8F8);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      b{
        font-weight: 700;
      }
    }
  }
}

.bottom{
  display: flex;
  padding: 160px 0px;
  flex-direction: column;
  align-items: center;
  gap: 160px;
  align-self: stretch;
}

.nangman{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  .nangman-description{
    color: var(--Gray-700, #575757);
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 28px;
  }
}

.logo-area{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 80px;
  width: 448px;
  .logo{
    background: url('@manseImages/logo_b.png') center center no-repeat;
    background-size: auto 100%;
    display: flex;
    width: 195px;
    height: 72px;
    justify-content: center;
    align-items: center;
  }
  .commitment-section{
    color: var(--Gray-700, #575757);
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
}

.sns-area{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 224px;
  .checkout{
    color: var(--Gray-800, #333);
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

}
.sns-icon{
  display: flex;
  align-items: center;
  gap: 40px;
  .youtube{
    display: flex;
    width: 48px;
    height: 48px;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    background: #FB0027;
    cursor: pointer;
    .y-icon{
      background: url('@manseImages/icon_sns_youtube.svg') center center no-repeat;
      background-size: auto 100%;
      width: 19.999px;
      height: 15.999px;
      flex-shrink: 0;
      fill: var(--gray-100-white, #FFF);
    }
  }
  .instagram{
    display: flex;
    width: 48px;
    height: 48px;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    background: linear-gradient(225deg, #8D00D9 13.54%, #F10049 49.22%, #F5800B 84.9%);
    cursor: pointer;
    .i-icon{
      background: url('@manseImages/icon_sns_instagram.svg') center center no-repeat;
      background-size: auto 100%;
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
  }
  .kakaotalk{
    background: url('@manseImages/icon_sns_kakao.svg') center center no-repeat;
    background-size: auto 100%;
    width: 48px;
    height: 48px;
    cursor: pointer;
  }
}

</style>