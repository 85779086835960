<script setup>
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'
import { DEV_MODE } from '@/main';

const { ...five } = fiveMixin()
const { ...useFun } = useFunctions()
const props = defineProps({
  prData: {
    type: Object,
    default: null,
  },
  prIndex: {
    type: Number,
    default: 0,
  },
  prGalleryDataTitle: {
    type: Array,
    default: [],
  },
  prBirthSkyTxt: {
    type: Array,
    default: [],
  },
  prBirthEarthTxt: {
    type: Array,
    default: [],
  },
  prGalleryTitles: {
    type: Array,
    default: [],
  },
  prTaewonData: {
    type: Array,
    default: [],
  },
  prGongmangData: {
    type: Array,
    default: [],
  },
})

const skyTxt = ref(props.prBirthSkyTxt)
const earthTxt = ref(props.prBirthEarthTxt)
const galleryTitles = ref(props.prGalleryTitles)

const taewonData = ref(props.prTaewonData.map((item) => item.value).join(''))
const gongmangData = ref(props.prGongmangData.map((item) => item.value).join(''))

// const isEmptyArray = (arrays) => {
//   return arrays.every((arr) => arr.length === 0)
// }
const isEmptyArray = (arrays) => {
  // 배열 여부를 먼저 확인하고, 배열이 아니면 false를 반환
  if (!Array.isArray(arrays)) return false;

  const areAllEmpty = arrays.every((arr) => Array.isArray(arr) && arr.length === 0);
  if (areAllEmpty) return true;

  const [first, ...rest] = arrays;
  const last = rest.pop(); // 마지막 요소 추출

  const isFirstValid = Array.isArray(first) && first.length === 1 && first[0] === '-';
  const isLastValid = Array.isArray(last) && last.length === 1 && last[0] === '-';

  // 첫 번째 또는 마지막이 '-'이고 나머지 요소가 모두 빈 배열인지 확인
  const areRestEmpty = rest.every((arr) => Array.isArray(arr) && arr.length === 0);

  return (isFirstValid || isLastValid) && areRestEmpty;
  // return arrays.every((arr) => Array.isArray(arr) && arr.length === 0);
};


onMounted(() => {
  //dataSet(props.prDataAll); // 초기 데이터 설정
})

watch(
  () => props.prBirthSkyTxt,
  (newVal) => {
    skyTxt.value = newVal
  },
  { deep: true },
)

watch(
  () => props.prBirthEarthTxt,
  (newVal) => {
    earthTxt.value = newVal
  },
  { deep: true },
)

watch(() => props.prTaewonData, (newData) => {
  if (newData) taewonData.value = newData.map((item) => item.value).join('')
}, {immediate: true})

watch(() => props.prGongmangData, (newData) => {
  if (newData) gongmangData.value = newData.map((item) => item.value).join('')
}, {immediate: true})
</script>
<template>
  <div class="data-detail-top">
    <span class="txt1">원진</span>
    <span class="data-none" v-if="isEmptyArray(prData[0])"> 원진이 없습니다. </span>
    <div v-else class="card-top-4fr-list">
      <span
        v-for="(charItem, charIdx) in earthTxt"
        :key="charIdx"
        class="txt2 txt-round detail"
        :class="[
          five.getColorFive(earthTxt[charIdx]),
          prLangMode === 'CHNKOR' ? 'chnkor' : '',
          prData[0][charIdx].length === 0 ? 'none' : '',
        ]"
      >
        {{ charItem }}
      </span>
    </div>
    <div class="card-top-4fr-list">
      <div v-for="(group, groupIndex) in prData[0]" :key="groupIndex" class="txt3">
        <div v-for="(item, itemIndex) in group" :key="itemIndex">
          {{ item === '-' ? '' : item }}
        </div>
      </div>
    </div>
    <div class="detail-gap"></div>
    <span class="txt1">귀문</span>
    <span class="data-none" v-if="isEmptyArray(prData[1])"> 귀문이 없습니다. </span>
    <div v-else class="card-top-4fr-list">
      <span
        v-for="(charItem, charIdx) in earthTxt"
        :key="charIdx"
        class="txt2 txt-round detail"
        :class="[
          five.getColorFive(earthTxt[charIdx]),
          prLangMode === 'CHNKOR' ? 'chnkor' : '',
          prData[1][charIdx].length === 0 ? 'none' : '',
        ]"
      >
        {{ charItem }}
      </span>
    </div>
    <div class="card-top-4fr-list">
      <div v-for="(group, groupIndex) in prData[1]" :key="groupIndex" class="txt3">
        <div v-for="(item, itemIndex) in group" :key="itemIndex">
          {{ item === '-' ? '' : item }}
        </div>
      </div>
    </div>
    <!-- <div class="detail-gap"></div>
    <span class="txt1">천간현침</span>
    <span class="data-none" v-if="isEmptyArray(prData[2])"> 천간현침이 없습니다. </span>
    <div class="card-top-4fr-list" v-if="!isEmptyArray(prData[2])">
      <div v-for="(group, groupIndex) in prData[2]" :key="groupIndex" class="txt3">
        <div v-if="group.length === 0">
          <span>-</span>
        </div>
        <div v-else>
          <div v-for="(item, itemIndex) in group" :key="itemIndex">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="detail-gap"></div>
    <span class="txt1">지지현침</span>
    <span class="data-none" v-if="isEmptyArray(prData[3])"> 지지현침이 없습니다. </span>
    <div class="card-top-4fr-list" v-if="!isEmptyArray(prData[3])">
      <div v-for="(group, groupIndex) in prData[3]" :key="groupIndex" class="txt3">
        <div v-if="group.length === 0">
          <span>-</span>
        </div>
        <div v-else>
          <div v-for="(item, itemIndex) in group" :key="itemIndex">
            {{ item }}
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <div class="detail-gap"></div>
  <div class="data-detail-flex">
    <div class="detail-box">
      <span class="txt1">공망</span>
      <div class="card-2fr-list">
        <span
          v-for="(charItem, charIdx) in gongmangData"
          :key="charIdx"
          class="txt2 txt-round detail"
          :class="[
            five.getColorFive(charItem),
            prLangMode === 'CHNKOR' ? 'chnkor' : '',
            prData[1][prData[1]?.length - 1][valueIndex] === 0 ? 'none' : '',
          ]"
        >
          {{ charItem }}
        </span>
      </div>
    </div>
    <div class="gong-div-tae" />
    <div class="detail-box">
      <span class="txt1">태월</span>
      <div class="card-2fr-list">
        <span
          v-for="(charItem, charIdx) in taewonData"
          :key="charIdx"
          class="txt2 txt-round detail"
          :class="[
            five.getColorFive(charItem),
            prLangMode === 'CHNKOR' ? 'chnkor' : '',
            prData[1][prData[1]?.length - 1][valueIndex] === 0 ? 'none' : '',
          ]"
        >
          {{ charItem }}
        </span>
      </div>
    </div>
  </div>
</template>
