import axios from 'axios'
import { DEV_MODE } from '@/main'

const systemId = import.meta.env.VITE_APP_SYSTEMID

export const useApiClient = () => {
  const get3 = (url) => {
    return axios
      .get(url)
      .then((response) => response)
      .catch((error) => {
        console.error(error)
        throw error
      })
  }

  const get = (url, params) => {
    DEV_MODE && console.log('params')
    DEV_MODE && console.log(params)
    const queryParams = params ? new URLSearchParams(params) : {}
    queryParams.append('systemId', systemId)

    return axios
      .get(url, { params: queryParams })
      .then((response) => response)
      .catch((error) => {
        console.error(error)
        throw error
      })
  }

  const get2 = (url, params) => {
    params.systemId = systemId
    return axios
      .get(url, { params })
      .then((response) => response)
      .catch((error) => {
        console.error(error)
        throw error
      })
  }

  const post = async (url, data) => {
    return await axios
      .post(url, { ...data, systemId })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error)
        throw error
      })
  }
  const post2 = async (url, data) => {
    return await axios
      .post(url, { ...data, systemId })
      .then((response) => response)
      .catch((error) => {
        console.error(error)
        throw error
      })
  }

  const put = (url, data) => {
    return axios
      .put(url, { ...data, systemId })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error)
        throw error
      })
  }

  const deleteRequest = (url, params) => {
    const queryParams = params ? new URLSearchParams(params) : {}
    queryParams.append('systemId', systemId)

    return axios
      .delete(url, { params: queryParams })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error)
        throw error
      })
  }

  return { get, get2, put, post, post2, del: deleteRequest, get3 }
}
