
<script setup>
import { ref, computed } from 'vue';

/**
 * 12지지 데이터
 */
const zodiacSigns = [
  { name: '申', angle: 330, color: '#9E9E9E' },
  { name: '酉', angle: 0, color: '#9E9E9E' },
  { name: '戌', angle: 30, color: '#9E9E9E' },
  { name: '亥', angle: 60, color: '#333333' },
  { name: '子', angle: 90, color: '#333333' },
  { name: '丑', angle: 120, color: '#333333' },
  { name: '寅', angle: 150, color: '#4CAF50' },
  { name: '卯', angle: 180, color: '#4CAF50' },
  { name: '辰', angle: 210, color: '#4CAF50' },
  { name: '巳', angle: 240, color: '#FF4444' },
  { name: '午', angle: 270, color: '#FF4444' },
  { name: '未', angle: 300, color: '#FF4444' }
];

/**
 * 24절기 데이터
 */
const seasons = [
  { name: '입춘', start: '02-04', end: '02-18', angle: 135, color: '#4CAF50', opacity: 0.7 },
  { name: '우수', start: '02-19', end: '03-04', angle: 150, color: '#4CAF50', opacity: 0.7 },
  { name: '경칩', start: '03-05', end: '03-19', angle: 165, color: '#4CAF50', opacity: 0.7 },
  { name: '춘분', start: '03-20', end: '04-03', angle: 180, color: '#4CAF50', opacity: 0.7 },
  { name: '청명', start: '04-04', end: '04-19', angle: 195, color: '#4CAF50', opacity: 0.7 },
  { name: '곡우', start: '04-20', end: '05-04', angle: 210, color: '#4CAF50', opacity: 0.7 },
  { name: '입하', start: '05-05', end: '05-20', angle: 225, color: '#FF4444', opacity: 0.7 },
  { name: '소만', start: '05-21', end: '06-04', angle: 240, color: '#FF4444', opacity: 0.7 },
  { name: '망종', start: '06-05', end: '06-20', angle: 255, color: '#FF4444', opacity: 0.7 },
  { name: '하지', start: '06-21', end: '07-06', angle: 270, color: '#FF4444', opacity: 0.7 },
  { name: '소서', start: '07-07', end: '07-22', angle: 285, color: '#FF4444', opacity: 0.7 },
  { name: '대서', start: '07-23', end: '08-06', angle: 300, color: '#FF4444', opacity: 0.7 },
  { name: '입추', start: '08-07', end: '08-22', angle: 315, color: '#9E9E9E', opacity: 0.7 },
  { name: '처서', start: '08-23', end: '09-07', angle: 330, color: '#9E9E9E', opacity: 0.7 },
  { name: '백로', start: '09-08', end: '09-22', angle: 345, color: '#9E9E9E', opacity: 0.7 },
  { name: '추분', start: '09-23', end: '10-07', angle: 0, color: '#9E9E9E', opacity: 0.7 },
  { name: '한로', start: '10-08', end: '10-22', angle: 15, color: '#9E9E9E', opacity: 0.7 },
  { name: '상강', start: '10-23', end: '11-06', angle: 30, color: '#9E9E9E', opacity: 0.7 },
  { name: '입동', start: '11-07', end: '11-21', angle: 45, color: '#333333', opacity: 0.7 },
  { name: '소설', start: '11-22', end: '12-06', angle: 60, color: '#333333', opacity: 0.7 },
  { name: '대설', start: '12-07', end: '12-21', angle: 75, color: '#333333', opacity: 0.7 },
  { name: '동지', start: '12-22', end: '01-04', angle: 90, color: '#333333', opacity: 0.7 },
  { name: '소한', start: '01-05', end: '01-19', angle: 105, color: '#333333', opacity: 0.7 },
  { name: '대한', start: '01-20', end: '02-03', angle: 120, color: '#333333', opacity: 0.7 }
];

/** 
 * 현재 날짜 가져오기
 */
const now = new Date();
const month = String(now.getMonth() + 1).padStart(2, '0');
const day = String(now.getDate()).padStart(2, '0');
const currentDate = `${month}-${day}`;

/**
 * 주어진 날짜 범위 안에 속하는지 판단
 */
function isWithinRange(date, start, end) {
  // 일반적으로 start <= end이면 (ex: 03-05 ~ 03-19)
  if (start <= end) {
    return date >= start && date <= end;
  }
  // 년도 넘어가는 케이스(예: 12-22 ~ 01-04)
  return date >= start || date <= end;
}

/**
 * 현재 절기 인덱스
 */
const currentSeasonIndex = computed(() => {
  return seasons.findIndex((season) =>
    isWithinRange(currentDate, season.start, season.end)
  );
});

/**
 * SVG 사이즈 및 기본 좌표
 */
const size = ref(350);
const centerX = computed(() => size.value / 2);
const centerY = computed(() => size.value / 2);
const outerRadius = computed(() => size.value / 2 * 0.75);
const innerRadius = computed(() => outerRadius.value * 0.6);

/**
 * 각 절기(부채꼴) Path를 생성하는 함수
 */
function generatePath(season) {
  // 절기 하나 당 +15° (앞뒤로 7.5°씩)
  const startAngle = (season.angle - 7.5) * (Math.PI / 180);
  const endAngle = (season.angle + 7.5) * (Math.PI / 180);

  const path = [
    `M ${centerX.value + innerRadius.value * Math.cos(startAngle)} ${
      centerY.value + innerRadius.value * Math.sin(startAngle)
    }`,
    `L ${centerX.value + outerRadius.value * Math.cos(startAngle)} ${
      centerY.value + outerRadius.value * Math.sin(startAngle)
    }`,
    `A ${outerRadius.value} ${outerRadius.value} 0 0 1 ${
      centerX.value + outerRadius.value * Math.cos(endAngle)
    } ${centerY.value + outerRadius.value * Math.sin(endAngle)}`,
    `L ${centerX.value + innerRadius.value * Math.cos(endAngle)} ${
      centerY.value + innerRadius.value * Math.sin(endAngle)
    }`,
    `A ${innerRadius.value} ${innerRadius.value} 0 0 0 ${
      centerX.value + innerRadius.value * Math.cos(startAngle)
    } ${centerY.value + innerRadius.value * Math.sin(startAngle)}`,
    'Z'
  ].join(' ');

  return path;
}

/**
 * 절기 텍스트 x, y 좌표
 */
function getSeasonTextPositionX(angle) {
  const angleRad = angle * (Math.PI / 180);
  const textRadius = outerRadius.value - 25;
  return centerX.value + textRadius * Math.cos(angleRad);
}
function getSeasonTextPositionY(angle) {
  const angleRad = angle * (Math.PI / 180);
  const textRadius = outerRadius.value - 25;
  return centerY.value + textRadius * Math.sin(angleRad);
}

/**
 * 12지지 텍스트 x, y 좌표
 */
function getZodiacTextPositionX(angle) {
  const angleRad = angle * (Math.PI / 180);
  return centerX.value + (innerRadius.value - 40) * Math.cos(angleRad);
}
function getZodiacTextPositionY(angle) {
  const angleRad = angle * (Math.PI / 180);
  return centerY.value + (innerRadius.value - 40) * Math.sin(angleRad);
}

onMounted(() => {
  const updateWidth = () => {
    const chartContainer = document.querySelector('.chart-item');
    if (chartContainer) {
      const newWidth = chartContainer.offsetWidth;
      if (newWidth > 500) size.value = newWidth;
    }
  };

  window.addEventListener('resize', updateWidth);
  updateWidth(); // 초기 크기 설정

  onUnmounted(() => {
    window.removeEventListener('resize', updateWidth);
  });
});


</script>

<template>
  <VCol cols="12" sm="12" md="6" lg="4" xl="4">
    <VCard class="chart-card">
      <div class="chart-header">
        <div class="chart-title">24절기 & 12지지</div>
      </div>
      <div class="w-full p-4 flex flex-col items-center bg-white rounded-lg shadow chart-item solar-terms relative">
        <svg :width="size" :height="size" :viewBox="`0 0 ${size} ${size}`">
          <!-- 배경 원 -->
          <circle
            :cx="centerX"
            :cy="centerY"
            :r="outerRadius"
            fill="none"
            stroke="#E5E7EB"
            stroke-width="1"
          />
          <circle
            :cx="centerX"
            :cy="centerY"
            :r="innerRadius"
            fill="none"
            stroke="#E5E7EB"
            stroke-width="1"
          />

          <!-- 십자 구분선 -->
          <line
            :x1="centerX"
            :y1="centerY - outerRadius"
            :x2="centerX"
            :y2="centerY + outerRadius"
            stroke="#E5E7EB"
            stroke-width="1"
            stroke-dasharray="4 4"
          />
          <line
            :x1="centerX - outerRadius"
            :y1="centerY"
            :x2="centerX + outerRadius"
            :y2="centerY"
            stroke="#E5E7EB"
            stroke-width="1"
            stroke-dasharray="4 4"
          />

          <!-- 대각선 구분선 -->
          <line
            :x1="centerX - outerRadius * Math.cos(Math.PI / 4)"
            :y1="centerY - outerRadius * Math.sin(Math.PI / 4)"
            :x2="centerX + outerRadius * Math.cos(Math.PI / 4)"
            :y2="centerY + outerRadius * Math.sin(Math.PI / 4)"
            stroke="#E5E7EB"
            stroke-width="1"
            stroke-dasharray="4 4"
          />
          <line
            :x1="centerX - outerRadius * Math.cos(Math.PI / 4)"
            :y1="centerY + outerRadius * Math.sin(Math.PI / 4)"
            :x2="centerX + outerRadius * Math.cos(Math.PI / 4)"
            :y2="centerY - outerRadius * Math.sin(Math.PI / 4)"
            stroke="#E5E7EB"
            stroke-width="1"
            stroke-dasharray="4 4"
          />

          <!-- 24절기 -->
          <template v-for="(season, index) in seasons" :key="season.name">
            <g>
              <!-- SVG Path(부채꼴) 그리기 -->
              <path
                :d="generatePath(season)"
                :fill="season.color"
                :opacity="index === currentSeasonIndex ? 1 : season.opacity"
                :stroke="index === currentSeasonIndex ? '#FF0000' : 'white'"
                :stroke-width="index === currentSeasonIndex ? '2' : '1'"
              />
              <!-- 텍스트(절기 이름) -->
              <text
                :x="getSeasonTextPositionX(season.angle)"
                :y="getSeasonTextPositionY(season.angle)"
                text-anchor="middle"
                dominant-baseline="middle"
                font-size="14"
                :fill="index === currentSeasonIndex ? 'white' : '#444'"
                :transform="`rotate(${season.angle + 90}, ${getSeasonTextPositionX(season.angle)}, ${getSeasonTextPositionY(season.angle)})`"
              >
                {{ season.name }}
              </text>
            </g>
          </template>

          <!-- 12지지 -->
          <template v-for="sign in zodiacSigns" :key="sign.name">
            <g>
              <text
                :x="getZodiacTextPositionX(sign.angle)"
                :y="getZodiacTextPositionY(sign.angle)"
                text-anchor="middle"
                dominant-baseline="middle"
                font-size="20"
                font-weight="bold"
                :fill="sign.color"
              >
                {{ sign.name }}
              </text>
            </g>
          </template>

          <!-- 방위 표시 -->
          <text
            :x="centerX"
            y="30"
            text-anchor="middle"
            font-size="24"
            font-weight="bold"
            fill="#FF4444"
          >
            南
          </text>
          <text
            x="30"
            :y="centerY"
            text-anchor="middle"
            font-size="24"
            font-weight="bold"
            fill="#4CAF50"
          >
            東
          </text>
          <text
            :x="size - 30"
            :y="centerY"
            text-anchor="middle"
            font-size="24"
            font-weight="bold"
            fill="#9E9E9E"
          >
            西
          </text>
          <text
            :x="centerX"
            :y="size - 30"
            text-anchor="middle"
            font-size="24"
            font-weight="bold"
            fill="#333333"
          >
            北
          </text>
        </svg>

        <!-- 현재 절기 정보 표시 -->
        <div v-if="currentSeasonIndex !== -1" class="chart-season-info">
          <div>
            <span class="font-medium">현재 절기: </span>
            <span class="text-red-500 font-bold">
              {{ seasons[currentSeasonIndex].name }}
            </span>
          </div>
          <div class="text-sm text-gray-500">
            ({{ seasons[currentSeasonIndex].start }} ~ {{ seasons[currentSeasonIndex].end }})
          </div>
        </div>
      </div>
    </VCard>
  </VCol>
</template>

<style scoped>
.chart-season-info {
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: white;
  padding: 8px;
  text-align: center;
}
</style>

