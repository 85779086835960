<script setup>
import { ref } from 'vue';
import VueApexCharts from 'vue3-apexcharts';

// (1) 데이터 정의
const rawData = {
  type: 'day_scores',
  ym_date: '202412',
  results: [
    { date: '20241201', dl: '기해', dl_cal_pnt: 3.69 },
    { date: '20241202', dl: '경자', dl_cal_pnt: 3.69 },
    { date: '20241203', dl: '신축', dl_cal_pnt: 3.84 },
    { date: '20241204', dl: '임인', dl_cal_pnt: 2.99 },
    { date: '20241205', dl: '계묘', dl_cal_pnt: 2.99 },
    { date: '20241206', dl: '갑진', dl_cal_pnt: 3.54 },
    { date: '20241207', dl: '을사', dl_cal_pnt: 2.44 },
    { date: '20241208', dl: '병오', dl_cal_pnt: 2.44 },
    { date: '20241209', dl: '정미', dl_cal_pnt: 3.27 },
    { date: '20241210', dl: '무신', dl_cal_pnt: 4.04 },
    { date: '20241211', dl: '기유', dl_cal_pnt: 4.04 },
    { date: '20241212', dl: '경술', dl_cal_pnt: 3.69 },
    { date: '20241213', dl: '신해', dl_cal_pnt: 3.84 },
    { date: '20241214', dl: '임자', dl_cal_pnt: 3.69 },
    { date: '20241215', dl: '계축', dl_cal_pnt: 3.69 },
    { date: '20241216', dl: '갑인', dl_cal_pnt: 2.84 },
    { date: '20241217', dl: '을묘', dl_cal_pnt: 2.69 },
    { date: '20241218', dl: '병진', dl_cal_pnt: 3.27 },
    { date: '20241219', dl: '정사', dl_cal_pnt: 2.44 },
    { date: '20241220', dl: '무오', dl_cal_pnt: 2.71 },
    { date: '20241221', dl: '기미', dl_cal_pnt: 3.69 },
    { date: '20241222', dl: '경신', dl_cal_pnt: 4.04 },
    { date: '20241223', dl: '신유', dl_cal_pnt: 4.14 },
    { date: '20241224', dl: '임술', dl_cal_pnt: 3.69 },
    { date: '20241225', dl: '계해', dl_cal_pnt: 3.69 },
    { date: '20241226', dl: '갑자', dl_cal_pnt: 3.54 },
    { date: '20241227', dl: '을축', dl_cal_pnt: 3.39 },
    { date: '20241228', dl: '병인', dl_cal_pnt: 2.57 },
    { date: '20241229', dl: '정묘', dl_cal_pnt: 2.57 },
    { date: '20241230', dl: '무진', dl_cal_pnt: 3.69 },
    { date: '20241231', dl: '기사', dl_cal_pnt: 2.71 },
  ],
};

// (2) 차트 데이터 준비
const categories = rawData.results.map((item) => item.date.slice(-2)); // 날짜 (일)
const dailyScores = rawData.results.map((item) => item.dl_cal_pnt); // 일운 점수

const chartOptions = ref({
  chart: {
    id: 'dailyFortuneChart',
    parentHeightOffset: 0,
    toolbar: { show: false },
    zoom: {
      enabled: false,
    },
  },
  xaxis: {
    axisBorder: { show: false },
    axisTicks: { show: false },
    categories,
  },
  yaxis: {
    min: 0,
    max: 5,
    labels: {
      formatter: value => Math.round(value).toString()
    }
  },
  stroke: {
    curve: 'smooth', // 선을 부드럽게 표시
    colors: '#8E44AD',
    width: '3',
  },
  markers: {
    size: 5, // 도트 크기
    colors: ['#8E44AD'], // 도트 색상
    strokeColors: '#FFFFFF', // 외곽선 색상
    strokeWidth: 2, // 외곽선 두께
    hover: {
      size: 7, // 호버 시 도트 크기
    },
  },
  tooltip: {
    enabled: true,
    y: {
      formatter: (value) => `${value.toFixed(2)} 점`,
    },
  },
});

const series = ref([
  {
    name: '일운 점수',
    data: dailyScores,
  },
]);
</script>

<template>
  <VCol cols="12" sm="12" md="8" lg="8" xl="8">
    <VCard class="chart-card">
      <div class="chart-header">
        <div class="chart-title">일운 흐름 분석</div>
      </div>

      <div class="chart-item">
        <VueApexCharts
          type="line"
          height="240"
          :options="chartOptions"
          :series="series"
        />
      </div>
    </VCard>
  </VCol>
</template>