<script setup>
import { computed } from 'vue';
import VueApexCharts from 'vue3-apexcharts';

const props = defineProps({
  height: {
    type: String,
  },
})

const gaugeOptions = computed(() => {
  return {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      toolbar: { show: false },
    },
    grid: {
      padding: {
        // top: 50,
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -130,
        endAngle: 130,
        hollow: {
          margin: 0,
          size: '50%',
          background: 'transparent',
        },
        track: {
          background: '#ebebeb',
          strokeWidth: '100%',
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: 20,
            show: true,
            color: '#888',
            fontSize: '16px',
            formatter: () => '운기 점수',
          },
          value: {
            offsetY: -10,
            color: '#111',
            fontSize: '22px',
            formatter: (val) => score.toFixed(2),
          },
        },
      },
    },
    fill: {
      colors: ['#EB4C10'], // 단일 색상
    },
    stroke: {
      lineCap: 'round',
    },
    labels: ['점수'],
  };
});

const score = 3.43; // Example score value
const series = [Math.sqrt(score / 5) * 100]; // Convert to percentage for radialBar
</script>

<template>
  <VCol>
    <VCard class="chart-card" :style="{height}">
      <div class="chart-content">
        <VueApexCharts
          type="radialBar"
          :options="gaugeOptions"
          :series="series"
          :height="240"
          :width="200"
        />
        <div class="chart-description">
          <p>
            홍길동님의 정유(丁酉) 대운은 안정적인 흐름 속에서 긍정적인 경험과 어려움이 혼재하는 시기입니다. 정유(丁酉)대 십년 동안 전체 운의 점수는 <strong>3.43</strong>이며 긍정 가능성이 <strong>65.19%</strong>로,
            긍정적인 기운으로 좋은 결과를 기대할 수 있는 상태입니다.
          </p>
        </div>
      </div>
    </VCard>
  </VCol>
</template>

<style scoped>
.chart-card {
  /* height: 180px !important; */
  padding: 0 32px !important;
  justify-content: center;
  max-width: 660px;
  overflow-x: auto;
}

.chart-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chart-description {
  font-size: 14px;
  color: #555;
  line-height: 1.6;
  overflow-y: auto;
  padding-right: 20px;
}

.chart-description strong {
  color: #f44336;
}

.vue-apexcharts {
  margin-top: 10px;
  margin-left: -20px;
}
</style>
