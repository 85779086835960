<!-- src/pages/MyPage.vue -->
<script setup>
import PurchaseComplete from './purchaseComplete.vue'
definePage({ meta: { layout: 'mypage' } })

import useAuth from '@/composables/useAuth'
import useFunctions from '@/composables/useFunctions'
import { getProducts, createOrder, subscrDeleteAll, orderConfirm, ownPoint } from '@/api/PaymentApi'
const { ...useFun } = useFunctions()
const { userInfo } = useAuth()

/*
const radioContent = [
  {
    title: '3,000원',
    desc: '',
    value: '3000',
    subtitle: '',
  },
  {
    title: '5,000원',
    subtitle: '+5% 보너스',
    desc: '',
    value: '5000',
  },
  {
    title: '10,000원',
    subtitle: '+10% 보너스',
    desc: '',
    value: '10000',
  },
  {
    title: '25,000원',
    subtitle: '+10% 보너스',
    desc: '',
    value: '25000',
  },
  {
    title: '30,000원',
    subtitle: '+15% 보너스',
    desc: '',
    value: '30000',
  },
]
*/
const radioContent = ref(null)
const selectedRadio = ref('3000')
const goodsAmt = ref(3000)
const goodsName = ref('')
const goodsOrderName = ref('')

const currentPoints = ref(0)
const agree = ref(false)
const completeNotice = '낭만 포인트 충전'
const paymentComplete = ref(false)

const router = useRouter()

const orderParam = ref(null)
const popTossPayment = ref(null)
const openPayment = async () => {
  let orderFlag = false
  orderFlag = await getOrderNo()
  if (!orderFlag) return

  popTossPayment.value = true
  await goPayment()
}
const close = () => {
  popTossPayment.value = false
}
// ------  결제위젯 초기화 ------
//const clientKey = 'test_gck_oEjb0gm23POLLyEMkv1g8pGwBJn5'
const clientKey = import.meta.env.VITE_TOSS_CLIENTKEY_NOR

const tossPayments = TossPayments(clientKey)
// 회원 결제
const customerKey = '3H3NxXvLfVUfZ_DPFyQ_61'
const widgets = tossPayments.widgets({
  customerKey,
})

const goPayment = async () => {
  // ------ 주문의 결제 금액 설정 ------
  await widgets.setAmount({
    currency: 'KRW',
    value: orderParam.value.totalAmount,
  })

  await Promise.all([
    // ------  결제 UI 렌더링 ------
    widgets.renderPaymentMethods({
      selector: '#payment-method',
      variantKey: 'DEFAULT',
    }),
    // ------  이용약관 UI 렌더링 ------
    widgets.renderAgreement({ selector: '#agreement', variantKey: 'AGREEMENT' }),
  ])
}

const doPayment = async () => {
  await widgets.requestPayment({
    orderId: orderParam.value.orderId,
    orderName: orderParam.value.orderName,
    successUrl: window.location.origin + '/mypage/subscrFinishPoint',
    failUrl: window.location.origin + '/mypage/subscrFinishPointFail',
    customerEmail: null,
    customerName: userInfo?.name,
    customerMobilePhone: null,
  })
}

//TODO: createOrder
const getOrderNo = async () => {
  const params = {
    amount: goodsAmt.value,
    pointAmount: 0,
    totalAmount: goodsAmt.value,
    productId: selectedRadio.value,
    orderName: goodsName.value,
  }

  let orderData = null
  try {
    orderData = await createOrder(params)
    orderParam.value = orderData
    return true
  } catch (error) {
    console.log('error', error)
    if (error.response && error.response.status === 400) {
      const errorMessage = error.response.data.detail // 응답 메시지 추출
      showDialogAlert(errorMessage)
    } else {
      showDialogAlert(error.response.data.detail)
    }
    return false
  }
}

const formatNumber = (num) => {
  if (num < 1000) return num
  return new Intl.NumberFormat().format(num)
}

const goodsData = ref(null)
let groupedData = ref(null)
let groupedData2 = ref([])
const filteredProducts = ref(null)
//TODO: 상품 목록
const goodsList = async () => {
  const params = { platform: 'WEB', productType: 'POINT', size: -1 }
  const data = await getProducts(params)
  radioContent.value = data.content
  selectedRadio.value = radioContent.value[0].productId
  updateGoods()
}

//TODO: 보유 포인트
const getPoint = async () => {
  const params = {}
  const data = await ownPoint(params)
  currentPoints.value = data
}

onMounted(async () => {
  await goodsList()
  getPoint()
})

const updateGoods = async () => {
  console.log('radioContent', radioContent.value)
  console.log('selectedRadio', selectedRadio.value)

  //첫번째값으로 선택
  //selectedRadio.value = radioContent.value[0].productId
  //const selectedItem = filteredProducts.value.find((item) => item.productId === selectedRadio.value)
  const selectedItem = radioContent.value.find((item) => item.productId === selectedRadio.value)

  if (selectedItem) {
    goodsName.value = `${formatNumber(selectedItem.detail.point)} p (+${
      selectedItem.detail.bonusPoint
    } P 보너스)`
    goodsOrderName.value = `${selectedItem.name}`
    goodsAmt.value = selectedItem.price
  } else {
    goodsName.value = ''
    goodsOrderName.value = ''
    goodsAmt.value = 0
  }

  //이용약관 동의 false
  agree.value = false
}
</script>

<template>
  <div class="my-info-wrap">
    <div class="my-tit-box com-bg-my-box h-small">
      <div class="tit">낭만 포인트 충전</div>
      <div class="path">
        <ul>
          <li>홈</li>
          <li>마이페이지</li>
          <li>낭만 포인트 충전</li>
        </ul>
      </div>
    </div>
    <!-- 완료화면 -->
    <div v-if="paymentComplete">
      <PurchaseComplete :product="completeNotice" />
    </div>
    <!-- 기본 구매 화면 -->
    <div v-else>
      <div class="myinfo-box com-bg-my-box pay-box">
        <CustomRadiosPoint
          class="radio-custom-01 st-point"
          v-model:selected-radio="selectedRadio"
          :radio-content="radioContent"
          :grid-column="{ sm: '3', cols: '5' }"
          @update:selectedRadio="updateGoods"
        />
        <div class="gap-xxl2"></div>
        <div class="my-line-01"></div>
        <div class="gap-xxl2"></div>
        <div class="my-tit-box st-right">
          <div class="tit">보유 낭만 포인트</div>
          <div class="sec-right">
            <i class="icon-point"></i><span>{{ formatNumber(currentPoints) }} P</span>
          </div>
        </div>
      </div>

      <div class="gap-sm-2"></div>
      <div class="myinfo-box com-bg-my-box pay-box">
        <div class="my-pt-btm">
          <dl>
            <dt><span class="txt-block">• 충전 포인트</span></dt>
            <dd class="txt1">{{ goodsName }}</dd>
          </dl>
          <dl>
            <dt><span class="txt-block">• 결제 금액</span></dt>
            <dd class="txt2">{{ formatNumber(goodsAmt) }} 원</dd>
          </dl>
        </div>

        <VDialog
          v-model="popTossPayment"
          class="v-dialog-sm dialog-st1"
          max-width="800px"
          @click:outside="close"
        >
          <!-- 결제 UI -->
          <div id="payment-method"></div>

          <!-- 이용약관 UI -->
          <div id="agreement"></div>

          <!-- 결제하기 버튼 -->
          <VBtn type="submit" style="width: 80%; margin: 20px auto 40px auto" @click="doPayment">
            결제하기
          </VBtn>
        </VDialog>
        <div class="gap-xxl2"></div>
        <div class="bg-box-01">
          <input type="checkbox" class="checkbox" v-model="agree" />
          결제 내용에 동의합니다.
          <span class="txt-tos">이용약관</span>
        </div>
        <div class="gap-xxl"></div>
        <div class="com-btn-box">
          <VBtn type="submit" style="width: 260px" @click="openPayment" :disabled="agree === false">
            결제하기
          </VBtn>
        </div>
      </div>
      <div class="point-info-box charge">
        <div class="tit"><span>포인트 충전 안내</span> <i class="icon-info"></i></div>
        <ul>
          <li>포인트는 선불 결제 방식으로 충전되며, 원하는 금액만큼 충전 가능합니다.</li>
          <li>
            충전된 포인트는 만세력 서비스 내에서 유효하며, 특정 서비스 및 콘텐츠 결제에 사용
            가능합니다.
          </li>
          <li>충전된 포인트는 타인에게 양도하거나 현금으로 전환할 수 없습니다.</li>
          <li>
            보너스 포인트는 일정 조건에서 제공되며, 지급받은 포인트는 사용기간, 사용 대상에서 제한이
            있을 수 있습니다.
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<style>
.v-overlay__content {
  background-color: #fff;
}
</style>
