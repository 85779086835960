<script setup>
const emit = defineEmits()

const props = defineProps({
  toggleProMode: {
    type: String,
    default: '',
  },
})

const PopMansePrintBox = ref(true)
const close = () => {
  emit('close')
}
function open() {
  PopMansePrintBox.value = true
}
const printFrame = ref(null)

function printDiv() {
  const printWindow = printFrame.value.contentWindow || printFrame.value.contentDocument.defaultView
  printWindow.print()
}
</script>
<template>
  <VDialog
    v-model="PopMansePrintBox"
    max-width="100%"
    class="dialog-print"
    :class="{ 'st-pro': toggleProMode === 'pro' }"
    :style="{ overflow: 'hidden', 'user-select': 'none' }"
    @click:outside="close"
  >
    <div class="prt-btn-box">
      <!-- <div class="pre-tit">사주 결과 미리보기</div> -->
      <div class="com-btn-box">
        <div class="btn-top">
          <span class="top-txt1">👉🏻</span>
          <span class="top-txt2">{{ toggleProMode === 'pro' ? '전문가용' : '일반용' }}</span>
        </div>
        <button class="btn-long" type="button" @click="printDiv">출력하기</button>
      </div>
      <button class="btn-close" @click="close"></button>
    </div>
    <VCard class="prt-body">
      <div class="pop-print-inner">
        <div class="pop-body-1">
          <div class="print-wrap">
            <iframe
              v-if="toggleProMode === 'nor'"
              ref="printFrame"
              name="printDiv"
              src="/manse/print"
              width="100%"
              frameborder="0"
              allowfullscreen
              style="overflow: hidden; display: block; width: 100%; height: 100%"
            />
            <iframe
              v-if="toggleProMode === 'pro'"
              ref="printFrame"
              name="printDiv"
              src="/manse/printpro"
              width="100%"
              frameborder="0"
              allowfullscreen
              style="overflow: hidden; display: block; width: 100%; height: 100%"
            />
          </div>
        </div>
      </div>
    </VCard>
  </VDialog>
</template>
<style scoped>
.dialog-content {
  max-height: 500px; /* 원하는 최대 높이 설정 */
  overflow: hidden; /* 스크롤바 숨기기 */
}
</style>
