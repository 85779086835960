<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'mypage' } })

import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { billingReg, subscrReg } from '@/api/PaymentApi'

const router = useRouter()
const route = useRoute()
const customerKey = ref('')
const authKey = ref('')
const productId = ref('')

//TODO 빌링키 등록
const getBillKey = async () => {
  try {
    const paramSubscr = {
      customerKey: customerKey.value,
      authKey: authKey.value,
    }
    const subscrData = await billingReg(paramSubscr)
    console.log('빌링 등록 성공')
    //TODO 빌링키로 실결제 요청
    const paramSubscr2 = { productId: productId.value }
    const subscrDataReg = await subscrReg(paramSubscr2)
    //alert(subscrDataReg.state)
    router.push('/mypage/subscrFinishBillEnd')
  } catch (error) {
    console.log(error)
    router.push('/mypage/subscrFinishBillFail')
    //alert(error)
  }
}

//TODO 컴포넌트가 마운트될 때 getBillKey 호출
onMounted(() => {
  customerKey.value = route.query.customerKey
  authKey.value = route.query.authKey
  productId.value = route.query.pid
  getBillKey()
})
</script>
