<!-- src/pages/MyPage.vue -->
<script setup>
import { ref } from 'vue'
import PurchaseComplete from './purchaseComplete.vue'
import AlertComponent from '@/pages/manse/components/Alert.vue'
import { getProducts, getBilling, subscrReg, subscrDelete } from '@/api/PaymentApi'
definePage({ meta: { layout: 'mypage' } })
const { userInfo } = useAuth()
/*
const radioContent = [
  {
    title: '베이직',
    desc: '30건',
    value: 'p_24112700001',
    monthly_price: 1,
    monthly_discount: 990,
    monthly_percent: 1,
    yearly_price: 11880,
    yearly_discount: 9900,
    yearly_percent: 16,
    icon_class: 'ico-basic icon-subscr',
    productId: 'basic',
  },
  {
    title: '클래식',
    desc: '100건',
    value: 'p_24112700002',
    monthly_price: 2200,
    monthly_discount: 1980,
    monthly_percent: 10,
    yearly_price: 26400,
    yearly_discount: 23760,
    yearly_percent: 10,
    icon_class: 'ico-classic icon-subscr',
    productId: 'classic',
  },
  {
    title: '에너지',
    desc: '200건',
    value: 'p_24112700003',
    monthly_price: 3300,
    monthly_discount: 2970,
    monthly_percent: 10,
    yearly_price: 3960,
    yearly_discount: 35640,
    yearly_percent: 10,
    icon_class: 'ico-power icon-subscr',
    productId: 'power',
  },
  {
    title: '하모니',
    desc: '무제한',
    value: 'p_24112700004',
    monthly_price: 6600,
    monthly_discount: 5900,
    monthly_percent: 11,
    yearly_price: 79200,
    yearly_discount: 67320,
    yearly_percent: 15,
    icon_class: 'ico-orchestra icon-subscr',
    productId: 'orchestra',
  },
]
*/
const radioContent2 = [
  {
    title: '월간 정기 구독권',
    //discount: '',
    //per: '',
    //price: '월 900',
    value: 'month',
    icon_class: '',
  },
  {
    title: '연간 정기 구독권',
    //discount: '11,800원',
    //per: '20%',
    //price: '연 9500',
    value: 'year',
    icon_class: '',
  },
]

const selectedRadio = ref('p_24112700001')
const selectedRadio2 = ref('month')
const goodsName = ref('베이직 / 월 이용료')
const goodsAmt = ref(0)
const usePeriod = ref('')
const selectedPeriod = ref('monthly')
const agree = ref(false)
const completeNotice = '저장소 정기 구독 신청' // 완료 화면으로 보낼 문구

// 완료 페이지 확인 위한 상태변수. 결제 완료 로직 구현 후 수정 필요
const paymentComplete = ref(false)

const paymentDate = ref('')
const endDate = ref('')

const dialogAlert = ref(null)
const dialogConfirm = ref(null)
const dialogTitle = ref('')
const dialogTitle2 = ref('')
const dialogText = ref('')
const dialogNextUrl = ref('')

const formatDate = (date, type = 'dot') => {
  if (type === 'dot') {
    return `${date.getFullYear()}.${String(date.getMonth() + 1).padStart(2, '0')}.${String(
      date.getDate(),
    ).padStart(2, '0')}`
  } else if (type === 'korean') {
    return `${date.getFullYear()}년 ${String(date.getMonth() + 1).padStart(2, '0')}월 ${String(
      date.getDate(),
    ).padStart(2, '0')}일`
  }
}

const calculateEndDate = (duration) => {
  const startDate = new Date() // 현재 날짜
  let paymentDateRaw

  if (duration === 12) {
    paymentDateRaw = new Date(startDate.setFullYear(startDate.getFullYear() + 1)) // 1년 후
  } else if (duration === 1) {
    paymentDateRaw = new Date(startDate.setMonth(startDate.getMonth() + 1)) // 1개월 후
  }

  const endDateRaw = new Date(paymentDateRaw)
  endDateRaw.setDate(endDateRaw.getDate() - 1)

  paymentDate.value = formatDate(paymentDateRaw, 'korean') // 결제일 (한국어 형식)
  endDate.value = formatDate(endDateRaw, 'dot') // 종료일 (점 형식)
}

const updateGoodsName = (value) => {
  updateGoods()
}
const updateGoodsName2 = (value) => {
  updateGoods()
}

onMounted(() => {
  updateGoods() // 컴포넌트가 마운트될 때 초기값에 따라 업데이트
})

const updateGoods = async () => {
  await goodsList()
  const selectedItem = filteredProducts.value.find((item) => item.productId === selectedRadio.value)
  // const selectedItem2 = radioContent2.find((item) => item.value === selectedRadio2.value)
  //const period = computed(() => (selectedPeriod.value === 'monthly' ? '월간' : '연간'))
  calculateEndDate(selectedItem.detail.period)
  if (selectedItem) {
    //goodsName.value = `${period.value} / ${selectedItem.title}(사주 저장 ${selectedItem.desc})`
    goodsName.value = `${selectedItem.name}`
    /*
    if (period.value === '연간') {
      goodsAmt.value = selectedItem.yearly_discount
    } else {
      goodsAmt.value = selectedItem.monthly_discount
    }
    */
    goodsAmt.value = selectedItem.price
  } else {
    goodsName.value = ''
    goodsAmt.value = 0
  }
  usePeriod.value = `${new Date().toLocaleDateString()} ~ ${endDate.value}`

  //이용약관 동의 false
  agree.value = false
}

const router = useRouter()
const goFinish = () => {
  router.push('/mypage/subscrFinish')
}
// ------  결제위젯 초기화 ------
//const clientKey = 'test_ck_DpexMgkW36ZA2M2K2wME3GbR5ozO'
const clientKey = import.meta.env.VITE_TOSS_CLIENTKEY_BILL
// 회원 결제 (Bill_+금액+기간+userid)
//const userid = sessionStorage.getItem('authUserId')
//const customerKey = 'Bill_1_1_' + userid
const tossPayments = TossPayments(clientKey)

//TODO 빌링결제
const doBill = async () => {
  //10 > 빌링 체크
  const params = {}
  const data = await getBilling(params)

  if (data.hasBillingKey) {
    //30 > 빌링 결제
    const customerKey = data.customerKey
    const paramSubscr = { productId: selectedRadio.value }
    const subscrData = await subscrReg(paramSubscr)
    console.log('Subscription Data:', subscrData)
    if (subscrData.message) showDialogAlert(subscrData.message)

    //router.push('/mypage/subscrFinishBillEnd')
  } else {
    // if (data.customerKey) {
    //   //20-1 > 빌링키 있음
    //   const paramSubscr = {
    //     customerKey: customerKey.value,
    //     authKey: authKey.value,
    //   }
    //   const subscrData = await billingReg(paramSubscr)
    // } else {
    //20-2 > 빌링키 없음 등록창 호출
    const payment = tossPayments.payment({
      customerKey: data.customerKey,
    })
    await payment.requestBillingAuth({
      method: 'CARD', // 자동결제(빌링)는 카드만 지원합니다
      successUrl: window.location.origin + `/mypage/subscrFinishBill?pid=${selectedRadio.value}`, // 요청이 성공하면 리다이렉트되는 URL
      failUrl: window.location.origin + '/mypage/subscrFinishBillFail', // 요청이 실패하면 리다이렉트되는 URL
      customerEmail: 'customer123@gmail.com',
      customerName: userInfo?.name,
    })
    //}
  }
}
//mypage/subscrFinishBill?customerKey=3H3NxXvLfVUfZ_DPFyQ_61&authKey=bln_0dqwov9qwdx
//mypage/subscrFinishBill?customerKey=Bill_15078&authKey=bln_KKEp0AMP7wE

/*
const transformedRadioContent = computed(() => {
  // `selectedPeriod`에 따라 데이터를 변환
  return radioContent.map((item) => ({
    ...item,
    price: selectedPeriod.value === 'monthly' ? item.monthly_price : item.yearly_price,
    discount: selectedPeriod.value === 'monthly' ? item.monthly_discount : item.yearly_discount,
    percent: selectedPeriod.value === 'monthly' ? item.monthly_percent : item.yearly_percent,
  }))
})
  */
//TODO 상품목록
const transformedRadioContentBill = computed(() => {
  // `selectedPeriod`에 따라 데이터를 변환
  return filteredProducts.value
})

// 숫자에 천 단위 구분 쉼표를 추가하는 함수
const formatNumber = (num) => {
  if (num < 1000) return num
  return new Intl.NumberFormat().format(num)
}

const goodsData = ref(null)
const filteredProducts = ref(null)
//TODO: 상품 목록
const goodsList = async () => {
  const params = { platform: 'WEB', productType: 'SUBSCRIPTION', size: -1 }
  const data = await getProducts(params)
  let periodNo = 1
  if (selectedPeriod.value !== 'monthly') {
    periodNo = 12
  }
  filteredProducts.value = data.content.filter((product) => product.detail.period === periodNo)
}

//TODO: 정기 결제 해지
const subsrcOut = async () => {
  const params = {}
  const data = await subscrDelete(params)
  showDialogAlert('해지 되었습니다.')
}

const memberInfo = ref(null)
const getSubsrcMember = async () => {
  const params = {}
  memberInfo.value = await getBilling(params)
}

//FIX 알림창
const showDialogAlert = (title, text = '') => {
  dialogTitle.value = title
  if (text) dialogText.value = text
  dialogAlert.value.openAlertDialog()
}
</script>

<template>
  <div class="my-info-wrap">
    <div class="my-tit-box com-bg-my-box h-small">
      <div class="tit">저장소 정기구독</div>
      <div class="path">
        <ul>
          <li>홈</li>
          <li>마이페이지</li>
          <li>구독</li>
          <li>낭만 저장소</li>
        </ul>
      </div>
    </div>
    <!-- 완료화면 -->
    <div v-if="paymentComplete">
      <PurchaseComplete :product="completeNotice" />
    </div>
    <!-- 기본 구매 화면 -->
    <div v-else>
      <div class="myinfo-box com-bg-my-box pay-box">
        <!-- {{ filteredProducts }} -->
        <!-- 구독키값 : {{ selectedRadio }}<br />
        회원구독정보 <br />
        {{ memberInfo }} -->
        <!-- <ul>
          <li v-for="product in filteredProducts" :key="product.productId">
            {{ product.name }} - {{ product.description }} - {{ product.productId }}
          </li>
        </ul> -->
        <!--<button @click="goodsList()">상품목록</button>-->
        <!-- <VBtn variant="plain" color="secondary" @click="subsrcOut()">정기구독해지</VBtn>
        <VBtn variant="plain" color="secondary" @click="getSubsrcMember()">회원정보확인</VBtn> -->
        <div class="period-area">
          <div class="radio-wrap">
            <input
              id="monthly"
              type="radio"
              class="radio"
              name="period"
              v-model="selectedPeriod"
              value="monthly"
              @change="updateGoods"
            />
            <label for="monthly" class="label">월간</label>
            <input
              id="yearly"
              type="radio"
              class="radio"
              name="period"
              v-model="selectedPeriod"
              value="yearly"
              @change="updateGoods"
            />
            <label for="yearly" class="label">
              연간
              <div class="tooltip">분석권 제공</div>
            </label>
          </div>
        </div>
        <!-- './src/@core/components/app-form-elements/CustomRadiosWithIconImg.vue' 파일에 있음 -->
        <!-- <CustomRadiosWithIconImg
          class="radio-custom-01"
          v-model:selected-radio="selectedRadio"
          :radio-content="transformedRadioContent"
          :grid-column="{ sm: '3', cols: '12' }"
          @update:selectedRadio="updateGoodsName"
        /> -->
        <CustomRadiosWithIconImg
          class="radio-custom-01"
          v-model:selected-radio="selectedRadio"
          :radio-content="transformedRadioContentBill"
          :grid-column="{ sm: '3', cols: '12' }"
          @update:selectedRadio="updateGoodsName"
        />
      </div>

      <div class="gap-sm-2"></div>
      <div class="myinfo-box com-bg-my-box pay-box">
        <div class="my-pt-btm-2">
          <dl>
            <dt>• 상품명</dt>
            <dd class="txt1">{{ goodsName }}</dd>
          </dl>
          <dl>
            <dt>• 결제 금액</dt>
            <dd class="txt2">
              <span class="point-amt"
                ><p class="txt3">매월</p>
                {{ formatNumber(goodsAmt) }}원</span
              >
            </dd>
          </dl>
          <dl>
            <dt>• 이용 기간</dt>
            <dd class="txt1">{{ usePeriod }}</dd>
          </dl>
          <dl>
            <dt></dt>
            <dd class="txt2 pay-notice">다음 결제일은 {{ paymentDate }} 입니다.</dd>
          </dl>
        </div>
        <div class="gap-xxl2"></div>
        <div class="bg-box-01">
          <input type="checkbox" class="checkbox" v-model="agree" />
          매월 자동결제에 동의합니다.
          <span class="txt-tos">이용약관</span>
        </div>
        <div class="gap-xxl"></div>
        <div class="com-btn-box">
          <VBtn
            class="pay-btn"
            type="submit"
            style="width: 260px"
            @click="doBill()"
            :disabled="agree === false"
          >
            결제하기
          </VBtn>
        </div>
      </div>
      <div class="point-info-box">
        <div class="tit"><span>자동 결제 안내</span> <i class="icon-info"></i></div>
        <ul>
          <li>
            "쉽고 편리하게, 월간/연간 자동결제!" 정기 구독 신청 후 지정된 날짜에 자동으로
            결제됩니다. 따로 신경 쓰지 않아도 필요한 서비스를 편리하게 이용하실 수 있습니다.
          </li>
          <li>
            연간 정기 구독은 장기적인 서비스 이용을 약정하는 상품입니다. 구매 후 취소가 불가하오니
            구독 전, 환불 정책과 서비스 내용을 충분히 검토하시길 권장 드립니다.
          </li>
        </ul>
      </div>
    </div>
  </div>
  <AlertComponent ref="dialogAlert" :title="dialogTitle" :text="dialogText" />
</template>
