import { createApp } from 'vue'
import App from '@/App.vue'
import { registerPlugins } from '@core/utils/plugins'
import store from '@/store'
import router from '@/plugins/1.router'
import { setupInterceptors } from '@/api/interceptor/interceptor.js'

// Styles
import '@core/scss/template/index.scss'
import '@styles/styles.scss'

import axios from 'axios'

export const DEV_MODE = import.meta.env.VITE_APP_MODE === 'development'

axios.defaults.baseURL = import.meta.env.VITE_APP_GW_URL // 개발서버 API 경로

// Create vue app
const app = createApp(App)

// Register plugins
registerPlugins(app)

// Vuex and Vue Router
app.use(store)
app.use(router)

// Setup Axios interceptors
setupInterceptors(router)

// 디버그 모드에서 [Vue warn] 메시지 숨기고 싶을 때 활성화
app.config.warnHandler = () => {};

// Mount vue app
app.mount('#app')
