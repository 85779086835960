<script setup>
const props = defineProps({
  /** daily, mychart */
  mode: {
    type: String,
  },
});

const options = computed(() => {
  return {
    chart: {
      stacked: true,
      parentHeightOffset: 0,
      toolbar: { show: false },
      zoom: { // 드래그로 확대/축소 기능
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusWhenStacked: 'last', // all or last
        distributed: true,
        columnWidth: '40px',
        dataLabels: {
          position: 'top'
        },
      },
    },
    // 그래프 범례
    legend: { show: false },
    dataLabels: { 
      enabled: true,
      offsetY: -20,
      style: {
        colors: [
        'rgba(var(--v-theme-fiveColor03), 1)',
        'rgba(var(--v-theme-fiveColor01), 1)',
        'rgba(var(--v-theme-fiveColor05), 1)',
        'rgba(var(--v-theme-fiveColor04), 1)',
        'rgba(var(--v-theme-fiveColor02), 1)',
        ]
      },
      formatter: (val) => {
        return `${val}%`
      }
    },
    // 막대 색상
    colors: [
      'rgba(var(--v-theme-fiveColor03), 1)',
      'rgba(var(--v-theme-fiveColor01), 1)',
      'rgba(var(--v-theme-fiveColor05), 1)',
      'rgba(var(--v-theme-fiveColor04), 1)',
      'rgba(var(--v-theme-fiveColor02), 1)',
    ],
    states: {
      hover: { filter: { type: 'none' } },
      active: { filter: { type: 'none' } },
    },
    xaxis: {
      categories: [
        '목 木',
        '화 火',
        '토 土',
        '금 金',
        '수 水',
      ],
      axisTicks: { show: false },
      crosshairs: { opacity: 0 },
      axisBorder: { show: false },
      tickPlacement: 'on',
      labels: {
        style: {
          colors: [
            'rgba(var(--v-theme-fiveColor03), 1)',
            'rgba(var(--v-theme-fiveColor01), 1)',
            'rgba(var(--v-theme-fiveColor05), 1)',
            'rgba(var(--v-theme-fiveColor04), 1)',
            'rgba(var(--v-theme-fiveColor02), 1)',
          ],
          fontFamily: 'Pretendard',
          fontSize: '14px',
          fontWeight: '500',
        },
      },
    },
    yaxis: {
      tickAmount: 4,
      labels: {
        padding: 8,
        // formatter: function (val) {
        //   return val.toFixed(0); // 정수로 표시
        // },
      }
    },
    grid: {
      padding: {
        top: -15,
        // left: -10,
        // right: -10,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    tooltip: { enabled: true },
  }
})

const mychartSeries = [{
  data: [
    6.3,
    17.9,
    17.2,
    26.2,
    32.4,
  ],
}]

const dailySeries = [{
  data: [
    0,
    0,
    50,
    50,
    0,
  ],
}]

const chartTitle = props.mode === 'daily' ? '오늘의 오행' : '나의 오행분포도'
</script>

<template>
  <VCol cols="12" sm="12" md="6" lg="4" xl="4">
    <VCard class="chart-card">
      <div class="chart-header">
        <div class="chart-title">{{ chartTitle }}</div>
        <!-- <div class="chart-subtitle">골고루 분포된 사주입니다. 😊</div> -->
        <div class="chart-subtitle">
          <button>기본오행분포</button>
          <button>지장오행분포</button>
        </div>
      </div>
  
      <div class="chart-item">
        <VueApexCharts
          type="bar"
          :options="options"
          :series="props.mode === 'daily' ? dailySeries : mychartSeries"
          :height="240"
        />
      </div>
    </VCard>
  </VCol>
</template>