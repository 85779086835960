import { useApiClient } from '@/api/ApiClient'
const { get2, post, del } = useApiClient()

/* 조회 */
export const fastSearch = async (params) => {
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([key, value]) => value != 'null'), // null과 undefined 제외
  )

  let path = `/fast/manseForce/`
  const { data } = await get2(path, filteredParams)
  return data
}
/* 대운조회 */
export const yearMonth = async (params) => {
  /*
  const params = body ? new URLSearchParams(clarify(body)) : {};
  params.append('systemId', this.systemId);
  return axios.get(`${this.endpoint}/fast/yearMonth/`, { params }).then((response) => response.data);
  */
  let path = `/fast/yearMonth/`
  const { data } = await get2(path, params)
  return data
}

/* 저장 */
export const userGroupSave = async (data) => {
  let path = `/users/groupGuestUsers`
  const response = await post(path, data)
  return response
}

/* 저장 */
export const userGroupSave2 = async (params) => {
  let path = `/users/groupGuestUsers`
  const data = await post(path, params)
  return data
}

//삭제
export const userGroupDel = async (params) => {
  let path = `/users/groupGuestUsers`
  const { data } = await del(path, params)
  return data
}
//그룹정보 조회
export const userGroups = async (params) => {
  let path = `/users/userGroups`
  const { data } = await get2(path, params)
  return data
}

//사주한글조회
export const sajuKor = async (params) => {
  let path = `/fast/sajuKor/`
  const { data } = await get2(path, params)
  return data
}

//지역검색
export const cities = async (params) => {
  let path = `/eoway/cities`
  const { data } = await get2(path, params)
  return data
}
