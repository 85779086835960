<script setup>
import moment from 'moment'
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'

import FiveProBodyComponent from '@/pages/manse/components/FiveProBodyComponent.vue'

const { ...five } = fiveMixin()
const { ...useFun } = useFunctions()

definePage({ meta: { layout: 'print' } })

const dataOrder = ref(['hour', 'day', 'month', 'year'])
const dataOrderTit = ref(['현재대운', '시', '일', '월', '년'])
const rsData = ref({})
const rsFormData = ref({})
const firstSegment = ref({})
const secondSegment = ref({})
const thirdSegment = ref({})
const fourthSegment = ref({})
const storedLangMode = ref(null)
const storedToggleProMode = ref('')
const birthTitle = ref('시일월년')
const isPrint = true;

const hapDataValEarth = ref(null)
const hapDataVal = ref(null)

const daeunsData = ref({})

const birthEightTxt = ref('')

onMounted(async () => {
  storedLangMode.value = sessionStorage.getItem('langMode')
  storedToggleProMode.value = sessionStorage.getItem('toggleProMode')
  await setData() // 초기 데이터 설정
  console.log('storedToggleProMode:', storedToggleProMode.value)
})

const formattedDate = () => {
  const curDate = moment()
  return curDate.format('YYYY.MM.DD HH:mm:ss')
}

const currentDate = ref(formattedDate())

async function setData() {
  const sortMode = sessionStorage.getItem('sortMode')
  if (sortMode === 'asc') {
    dataOrder.value = [dataOrder.value[0], ...dataOrder.value.slice(1).reverse()]
    dataOrderTit.value = [dataOrderTit.value[0], ...dataOrderTit.value.slice(1).reverse()]
  }

  rsData.value = JSON.parse(sessionStorage.getItem('printData'))
  rsFormData.value = JSON.parse(sessionStorage.getItem('printformData'))

  const skyValues = [
    five.getHanjaTxt(rsData.value.birthganji.sky.year.value, 'sky'),
    five.getHanjaTxt(rsData.value.birthganji.sky.month.value, 'sky'),
    five.getHanjaTxt(rsData.value.birthganji.sky.day.value, 'sky'),
    rsData.value.birthganji.sky.hour?.value !== '-'
      ? five.getHanjaTxt(rsData.value.birthganji.sky.hour.value, 'sky')
      : '-',
  ]
  const earthValues = [
    five.getHanjaTxt(rsData.value.birthganji.earth.year.value, 'earth'),
    five.getHanjaTxt(rsData.value.birthganji.earth.month.value, 'earth'),
    five.getHanjaTxt(rsData.value.birthganji.earth.day.value, 'earth'),
    rsData.value.birthganji.earth.hour?.value !== '-'
      ? five.getHanjaTxt(rsData.value.birthganji.earth.hour.value, 'earth')
      : '-',
  ]
  birthEightTxt.value = skyValues.join('') + earthValues.join('')

  if (rsData.value) {
    const totalLength = rsData.value.hundredsyears.reduce(
      (acc, item) => acc + (item.value ? item.value.length : 0),
      0,
    )

    const segmentLength = 25
    firstSegment.value = rsData.value?.hundredsyears.reduce((acc, item) => {
      const filteredValue = item.value.filter((v) => v.age <= segmentLength)
      if (filteredValue.length > 0) {
        acc.push({ ...item, value: filteredValue })
      }
      return acc
    }, [])
    secondSegment.value = rsData.value?.hundredsyears.reduce((acc, item) => {
      const filteredValue = item.value.filter(
        (v) => v.age > segmentLength && v.age <= segmentLength * 2,
      )
      if (filteredValue.length > 0) {
        acc.push({ ...item, value: filteredValue })
      }
      return acc
    }, [])

    thirdSegment.value = rsData.value?.hundredsyears.reduce((acc, item) => {
      const filteredValue = item.value.filter(
        (v) => v.age > segmentLength * 2 && v.age <= segmentLength * 3,
      )
      if (filteredValue.length > 0) {
        acc.push({ ...item, value: filteredValue })
      }
      return acc
    }, [])

    fourthSegment.value = rsData.value?.hundredsyears.reduce((acc, item) => {
      //const filteredValue = item.value.filter((v) => v.age > segmentLength * 3)
      const filteredValue = item.value.filter((v) => v.age > segmentLength * 3 && v.age <= 100)
      if (filteredValue.length > 0) {
        acc.push({ ...item, value: filteredValue })
      }
      return acc
    }, [])
  }

  hapDataVal.value = five
    .convertHap({ hap: rsData.value?.hap, isSky: true, langMode: sortMode })
    .join('ㆍ')

  hapDataValEarth.value = five
    .convertHap({ hap: rsData.value?.hap, isSky: false, langMode: sortMode })
    .join('ㆍ')
}

/** 한글과 한자 혼용 */
// 한자/한글 버전일 경우 프린트는 일부 한자 일부 한글
const showHanja = (value, position) => {
  return storedLangMode.value === 'CHNKOR' ? five.getHanjaTxt(value, position) : value
}
</script>
<template>
  <div class="print-wrap1 printDiv">
    <div class="inner-box">
      <div class="logo" />
      <div class="name-box">
        <div class="name-age-box">
          <div v-if="rsData.inputdata?.name" class="name3">
            {{ rsData.inputdata?.name }}
          </div>
          <div v-else class="name3">-</div>
          <div class="age">
            <!-- {{ inputdata.name }} -->
            <!-- {{ rsData?.transdata?.age }} 세 -->
            {{ rsData?.inputdata?.gender === 1 || rsData?.inputdata?.gender === '1' ? '남' : '여' }}
            {{ rsData?.transdata?.kor_age }}세 (만{{ rsData?.transdata?.man_age }}세)
          </div>
        </div>
        <div class="birth-box">
          <div class="">
            <span class="tits orange">양력</span>
            <span class="txts ml5"
              >{{ useFun.formatCustomDate(rsData?.transdata?.solar) }} ({{
                rsData?.transdata?.week
              }})

              <!-- {{ useFun.getTimeTxt2(rsData?.inputdata?.hour) }}:{{
                useFun.getTimeTxt2(rsData?.inputdata?.min)
              }} -->

              {{
                useFun.getBirthTimeTxt(
                  useFun.getTimeTxt2(rsData?.inputdata?.hour) +
                    ':' +
                    useFun.getTimeTxt2(rsData?.inputdata?.min),
                )
              }}
            </span>
          </div>
          <div class="">
            <span class="tits blue">음력</span>
            <span class="txts ml5">{{ useFun.formatCustomDate(rsData?.transdata?.lunar) }}</span>
          </div>
          <div class="w-wide">
            <span class="tits">옵션</span>
            <span class="txts ml5">
              <span v-if="rsData?.transdata?.bornArea?.county">
                {{ rsData?.transdata?.bornArea.county }}, {{ rsData?.transdata?.bornArea.city }},
              </span>
              {{ useFun.formatOption4Txt(rsFormData?.option4) }}
              {{ useFun.formatOption1Txt(rsFormData?.option1) }}
              {{ useFun.formatOption2Txt(rsFormData?.option2) }}
            </span>
          </div>
        </div>
      </div>
      <FiveProBodyComponent
        :birthTitle="birthTitle"
        :dataOrder="dataOrder"
        :rsDataAll="rsData"
        :langMode="storedLangMode"
        :toggleProMode="storedToggleProMode"
        :firstSegment="firstSegment"
        :secondSegment="secondSegment"
        :thirdSegment="thirdSegment"
        :fourthSegment="fourthSegment"
        :storedLangMode="storedLangMode"
        :birthEightTxt="birthEightTxt"
        :birthData="rsData.birthganji"
        :hapDataVal="hapDataVal"
        :hapBlDataSky="hapBlDataSky"
        :hapDataValEarth="hapDataValEarth"
        :isLoading="isLoading"
        :isPrint="isPrint"
        @open-ilgan="openIlgan()"
        @close-ilgan="closePopIlgan()"
      />
    </div>
  </div>
</template>
<style>
body {
  -webkit-print-color-adjust: exact;
  padding: 0;
  background: transparent;
}
.v-application {
  background: transparent;
  padding: 0;
  margin: 0;
}
/* 스크롤바 스타일 적용 */
body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  border-radius: 12px;
  background: #fff;
}

body::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background: #ebebeb;
}

.print-wrap1 .print-btm-box {
  border-top: none;
}

@media print {
  @page {
    size: landscape; /* 가로 방향으로 설정 */
  }
  body {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 790px;
    overflow: hidden;
    width: 100% !important;
    margin: 0 auto;
  }
  .print-wrap1 {
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
    transform: scale(1.1);
    transform-origin: center top !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* margin-top: 10px !important; */
    padding: 0;
  }
  .inner-box {
    height: auto;
    padding: 0 !important;
    max-width: 930px;
  }
  .inner-box .logo {
    position: absolute;
    right: 15px;
    top: 2px !important;
  }
  .print-wrap1 .print-btm-box .pt-row-box .text-box {
    height: 17px;
  }
  .printDiv .date-pro-root-box .pro-five-box {
    height: 342px;
  }
  .printDiv .date-pro-root-box .pro-five-wrap .data-five-state .state-row {
    margin-bottom: 3px;
  }
  /* .v-application__wrap {
    display: block;
  } */
}
</style>
