<script setup>
import { hexToRgb } from '@layouts/utils';
import { useTheme } from 'vuetify';
import { ref, computed } from 'vue'

// 예시 데이터
const vuetifyTheme = useTheme()
const mbtiDistribution = ['ENTJ', 'ENFP', 'ENFP', 'ENFP', 'ENFP', 'ENFP', 'ENFP', 'ENFP', 'ENFP', 'ENFP']

const series = [
  {
    name: '감성적',
    color: 'rgba(var(--v-theme-fiveColor03), 1)',
    data: [61, 97, 0, 87, 31, 12, 10],
  },
  {
    name: '열정적',
    color: 'rgba(var(--v-theme-fiveColor01), 1)',
    data: [21, 2, 12, 13, 3, 20, 46],
  },
  {
    name: '이성적',
    color: 'rgba(var(--v-theme-fiveColor05), 1)',
    data: [7, 1, 23, 0, 59, 59, 17],
  },
  {
    name: '논리적',
    color: 'rgba(var(--v-theme-fiveColor04), 1)',
    data: [7, 0, 39, 0, 2, 4, 0],
  },
  {
    name: '예술적',
    color: 'rgba(var(--v-theme-fiveColor02), 1)',
    data: [4, 0, 26, 0, 5, 5, 27],
  },
]

const xaxisCategories = [
  // ['무진', '2002'],
  // ['기유', '2012'],
  // ['경술', '2022'],
  // ['신해', '2032'],
  // ['임자', '2042'],
  // ['계축', '2052'],
  // ['갑인', '2062'],
  // ['을묘', '2072'],
  // ['병진', '2082'],
  // ['정사', '2092'],
  '일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'
]

// 1) 각 xaxis(카테고리)에 대해 시리즈들의 합을 구해 Annotations를 만드는 계산식
const annotationPoints = computed(() => {
  return xaxisCategories.map((category, idx) => {
    // 시리즈별 data[idx]를 모두 더해서 “스택 전체 높이”를 구한다.
    const totalValue = series.reduce((acc, s) => acc + s.data[idx], 0)

    return {
      // x축 위치
      x: category,
      // y축 위치(스택 위)
      y: totalValue,
      // 마커(포인트) 표시 스타일(필요 없다면 size:0)
      marker: { size: 0 },
      // 실제로 표시되는 라벨
      label: {
        // text: mbtiDistribution[idx],       // ENTJ, ENFP 등
        borderColor: 'transparent',        // 테두리 색
        style: {
          color: '#333',                   // 라벨 글자색
          background: 'rgba(255,255,255,0.7)', // 라벨 배경
          fontSize: '13px',
          fontFamily: 'inter'
        },
        offsetY: -10,                      // 막대 상단 위로 살짝 올리기
      },
    }
  })
})

// 2) 기본 차트 옵션
const options = computed(() => {
  const currentTheme = ref(vuetifyTheme.current.value.colors)
  const variableTheme = ref(vuetifyTheme.current.value.variables)
  const disabledColor = `rgba(${ hexToRgb(currentTheme.value['on-surface']) },${ variableTheme.value['disabled-opacity'] })`
  const borderColor = '#EBEBEB'

  return {
    chart: {
      stacked: true,
      parentHeightOffset: 0,
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        // borderRadius: 2,
        borderRadiusWhenStacked: 'last',
        columnWidth: '50px',
        dataLabels: {
          position: 'top'
        },
      },
    },
    legend: {
      show: true,
      itemMargin: {
        vertical: 20,
      }
    },
    dataLabels: { enabled: false }, // 숫자 데이터 라벨 비활성화
    // ★ annotations 속성에 points 배열 주입
    annotations: {
      points: annotationPoints.value
    },
    xaxis: {
      categories: xaxisCategories,
      axisTicks: { show: false },
      crosshairs: { opacity: 0 },
      axisBorder: { show: false },
      labels: {
        style: {
          fontSize: '13px',
          colors: disabledColor,
          fontFamily: 'inter',
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 5,
      labels: {
        offsetY: 4,
        style: {
          fontSize: '13px',
          colors: disabledColor,
          fontFamily: 'inter',
        },
        formatter: (value) => (value > 999 ? `${(value / 1000).toFixed(0)}k` : `${value}`),
      },
    },
    colors: [
      'rgba(var(--v-theme-primary),1)',
      currentTheme.value.success,
      currentTheme.value.secondary,
    ],
    grid: {
      padding: { bottom: -10 },
      xaxis: { lines: { show: false } },
      yaxis: { lines: { show: true } },
      borderColor,
      strokeDashArray: 7,
    },
    stroke: {
      curve: 'smooth',
      lineCap: 'round',
      colors: [currentTheme.value.surface],
    },
    states: {
      hover: { filter: { type: 'none' } },
      active: { filter: { type: 'none' } },
    },
  }
})

const chartTitle = '주간 에너지';
</script>

<template>
  <VCol cols="12" sm="12" md="8" lg="8" xl="8">
    <VCard class="chart-card">
      <div class="chart-header">
        <div class="chart-title">{{ chartTitle }}</div>
      </div>
      <div class="chart-item" :style="{marginTop: '-20px'}">
        <VueApexCharts
          type="bar"
          :options="options"
          :series="series"
          :height="300"
        />
      </div>
    </VCard>
  </VCol>
</template>