<script setup>
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'

const { ...five } = fiveMixin()
const { ...useFun } = useFunctions()
const props = defineProps({
  birthData: {
    type: Object,
    default: null,
  },
  birthEightTxt: {
    type: String,
    default: '',
  },
  isPrint: {
    type: Boolean,
    default: false,
  }
})
onMounted(() => {

})


const isMatch = (char) => {
  return props.birthEightTxt.includes(char)
}

const getChars = (char) => {
  const matchedChars = props.birthEightTxt?.split('').filter((c) => c === char)
  return matchedChars.length > 0 ? matchedChars : [char]
}

// 1219테스트 (hgdev)
// 3번째 데이터를 추적
const getSkyDayData = () => {
  const data = props.birthEightTxt?.split('');
  return {
    char: data[2], // 3번째 데이터의 값 (0-based index)
    index: 2,      // 3번째 데이터의 위치
  };
};
// const skyDayData = getSkyDayData(); 
const skyDayData = ref('')
watch(
  () => props.birthEightTxt, // 감시 대상
  (newVal) => {
    console.log('birthEightTxt 변경 감지:', newVal);
    skyDayData.value = getSkyDayData(newVal); // skyDayData 업데이트
    console.log('🗺 sky', skyDayData.value)
  },
  { immediate: true } // 컴포넌트가 마운트될 때도 즉시 호출
);
console.log(skyDayData)


// birthEightTxt에 대한 index
const globalIndex = (localIndex, char) => {
  const data = props.birthEightTxt?.split('');
  let count = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i] === char) {
      if (count === localIndex) {
        return i; // 로컬 인덱스에 대응하는 전역 인덱스 반환
      }
      count++;
    }
  }
  return -1; // 해당 문자가 없으면 -1 반환
};

// skyDay 추상화
const isSkyDay = (char, index, charType, isReversed = false) => {
  const chars = getProcessedChars(charType, isReversed);
  const processedIndex = isReversed ? originalIndex(index, chars.length) : index;

  return char === skyDayData.char && processedIndex === skyDayData.index;
};

// TODO: index.vue -> fiveprobodycomponent.vue 의 dataorer 활용 고려
// 역순이 필요한 자리에 넣을 함수
const getProcessedChars = (charType, isReversed = false) => {
  const chars = getChars(charType).map((char, index) => ({ char, index })); // 데이터와 인덱스 묶기
  if (isReversed) {
    return chars.reverse(); // 화면 순서 반전
  }
  return chars; // 원래 순서
};

// 부모로 이벤트 emit
const emit = defineEmits(['skyDay-click', 'open-ilgan', 'skyDay-reset', 'resetSelectedState']); // 부모 컴포넌트에서 'skyDay-click'을 받아야 함


const CJClickedIdx = ref(null);
const selectedChar = ref(null);
const selectedIndex = ref(null)
const updateClickIdx = (char, index) => {
selectedChar.value = char;
CJClickedIdx.value = index;
}
/** 천간, 지지 클릭시 */
const handleClick = (day, char, index) => {
  if(props.isPrint){
    return
  }
  // five pro top component에 표시 해제
  if(CJClickedIdx.value === index && selectedChar.value === char){
    CJClickedIdx.value = null;
    selectedChar.value = null;
    emit('skyDay-reset');
    return;
  }
  updateClickIdx(char, index); // 뒷 배경 표시
  // five pro top component에 표시
  const globalIdx = globalIndex(index, char);
  const isSkyDayClicked = char === skyDayData.value.char && globalIdx === skyDayData.value.index;
  const whichDay = day;
  emit('skyDay-click', {
    whichDay,
    isSkyDayClicked,
    globalIdx,
  });
  console.log('star컴포넌트에서 클릭된 데이터', char, whichDay, globalIdx)
  if(isSkyDayClicked){
    // 일간 클릭했을 경우 팝업 띄우기
    emit('open-ilgan')
  } else {
    emit('close-ilgan')
  }
};

// 격국
const isGyeokClicked = ref(false);
const openGyeokguk = () => {
  isGyeokClicked.value = true;
}
const closeGyeokguk = () => {
  isGyeokClicked.value = false;
}


// 용신
const isYongClicked = ref(false);
const openYongsin = () => {
  isYongClicked.value = true;
}
const closeYongsin = () => {
  isYongClicked.value = false;
}

const resetSelectedState = () => {
  CJClickedIdx.value = null;
  selectedChar.value = null;
  // emit('skyDay-reset');
};

defineExpose({
  resetSelectedState,
});

</script>
<template>
  <div class="pro-star-box">
    <div class="div-back five-back01" v-if="birthData?.five?.five2?.num > 0"></div>
    <!-- tue -->
    <div class="div-back five-back02" v-if="birthData?.five?.five5?.num > 0"></div>
    <!-- wed -->
    <div class="div-back five-back03" v-if="birthData?.five?.five1?.num > 0"></div>
    <!-- thu -->
    <div class="div-back five-back04" v-if="birthData?.five?.five4?.num > 0"></div>
    <!-- fri -->
    <div class="div-back five-back05" v-if="birthData?.five?.five3?.num > 0"></div>
    <!-- sat -->
    <div class="div-txt st-fri" :class="{ on: birthData?.five?.five4?.num > 0 }">金</div>
    <div class="div-txt st-sat" :class="{ on: birthData?.five?.five3?.num > 0 }">土</div>
    <div class="div-txt st-tue" :class="{ on: birthData?.five?.five2?.num > 0 }">火</div>
    <div class="div-txt st-wed" :class="{ on: birthData?.five?.five5?.num > 0 }">水</div>
    <div class="div-txt st-thu" :class="{ on: birthData?.five?.five1?.num > 0 }">木</div>
    <!--클릭/마우스오버 인식 필요해서 레이어 순서 변경-->

    <!-- TODO: 격국 옆 데이터는 받는 데이터 -->
     <!-- <div class="right-btn">
        <button class="gyeok-yong" @click="openGyeokguk()">격국 : 정재격 <div class="arrow"></div></button>
        <button class="gyeok-yong" @click="openYongsin()">용신 <div class="arrow"></div></button>
     </div> -->

     <!-- TODO: 리팩토링? -->
    <!-- 토 -->
    <div class="txt-five-box st-05">
      <div class="txt-top">
        <div class="al-left-box">
            <div class="char-wrapper"  v-for="({ char, index }, displayIndex) in getProcessedChars('戊', true)" :key="index">
              <!-- "일간" 표시 -->
              <div
                v-if="char === skyDayData.char && globalIndex(index, '戊') === skyDayData.index"
                class="skyDay-label"
                :class="{isPrint: isPrint}"

              >
                일간
              </div>
              <!-- 문자 표시 -->
              <span
                :class="{ 
                  on: isMatch('戊'), 
                  skyDay: char === skyDayData.char && globalIndex(index, '戊') === skyDayData.index,
                  active: CJClickedIdx === index && selectedChar === char, 
                  isPrint: isPrint}"
                @click="handleClick('sat', char, index)"
              >
                {{ char }}
              </span>
            </div>
        </div>
        <div class="al-right-box">
            <div class="char-wrapper"  v-for="({ char, index }, displayIndex) in getProcessedChars('己', false)" :key="index">
              <!-- "일간" 표시 -->
              <div
                v-if="char === skyDayData.char && globalIndex(index, '己') === skyDayData.index"
                class="skyDay-label"
                :class="{isPrint: isPrint}"
              >
                일간
              </div>
              <!-- 문자 표시 -->
              <span
                :class="{ on: isMatch('己'), 
                skyDay: char === skyDayData.char && globalIndex(index, '己') === skyDayData.index,
                active: CJClickedIdx === index && selectedChar === char, 
                isPrint: isPrint}"
                @click="handleClick('sat', char, index)"
              >
                {{ char }}
              </span>
            </div>
        </div>
      </div>
      <div class="txt-line-box" :class="{isPrint: isPrint}"></div>
      <div class="txt-btm">
        <div class="al-left-box">
          <span
          v-for="({ char, index }, displayIndex) in getProcessedChars('辰', true)"
            :key="index"
            :class="{ on: isMatch('辰'), active: CJClickedIdx === index && selectedChar === char, 
              isPrint: isPrint
            }"
            @click="handleClick('sat', char, index)"
            >{{ char }}</span
          >
          <span
          v-for="({ char, index }, displayIndex) in getProcessedChars('戌', true)"
            :key="index"
            :class="{ on: isMatch('戌'), active: CJClickedIdx === index && selectedChar === char, 
              isPrint: isPrint
            }"
            @click="handleClick('sat', char, index)"
            >{{ char }}</span
          >
        </div>
        <div class="al-right-box">
          <span
             v-for="({ char, index }, displayIndex) in getProcessedChars('丑', false)"
            :key="index"
            :class="{ on: isMatch('丑'), active: CJClickedIdx === index && selectedChar === char, 
              isPrint: isPrint
            }"
            @click="handleClick('sat', char, index)"
            >{{ char }}</span
          >
          <span
             v-for="({ char, index }, displayIndex) in getProcessedChars('未', false)"
            :key="index"
            :class="{ on: isMatch('未'), active: CJClickedIdx === index && selectedChar === char, 
              isPrint: isPrint
            }"
            @click="handleClick('sat', char, index)"
            >{{ char }}</span
          >
        </div>
      </div>
    </div>
    <!-- 금 -->
    <div class="txt-five-box st-04">
      <div class="txt-top">
        <div class="al-left-box">
            <div class="char-wrapper"  v-for="({ char, index }, displayIndex) in getProcessedChars('庚', true)" :key="index">
              <!-- "일간" 표시 -->
              <div
                v-if="char === skyDayData.char && globalIndex(index, '庚') === skyDayData.index"
                class="skyDay-label-left"
                :class="{isPrint: isPrint}"
              >
                일간
              </div>
              <!-- 문자 표시 -->
              <span
                :class="{ on: isMatch('庚'), 
                skyDay: char === skyDayData.char && globalIndex(index, '庚') === skyDayData.index,
                active: CJClickedIdx === index && selectedChar === char,
                isPrint: isPrint
                 }"
                @click="handleClick('fri', char, index)"
              >
                {{ char }}
              </span>
            </div>
        </div>
        <div class="al-right-box">
            <div class="char-wrapper"  v-for="({ char, index }, displayIndex) in getProcessedChars('辛', true)" :key="index">
              <!-- "일간" 표시 -->
              <div
                v-if="char === skyDayData.char && globalIndex(index, '辛') === skyDayData.index"
                class="skyDay-label-right"
                :class="{isPrint: isPrint}"
              >
                일간
              </div>
              <!-- 문자 표시 -->
              <span
                :class="{ on: isMatch('辛'), 
                skyDay: char === skyDayData.char && globalIndex(index, '辛') === skyDayData.index,
                active: CJClickedIdx === index && selectedChar === char, 
                isPrint: isPrint}"
                @click="handleClick('fri', char, index)"
              >
                {{ char }}
              </span>
            </div>
        </div>
      </div>
      <div class="txt-line-box" :class="{isPrint: isPrint}"></div>
      <div class="txt-btm">
        <div class="al-left-box">
          <span
             v-for="({ char, index }, displayIndex) in getProcessedChars('申', false)"
            :key="index"
            :class="{ on: isMatch('申'), active: CJClickedIdx === index && selectedChar === char, 
              isPrint: isPrint
            }"
            @click="handleClick('fri', char, index)"
            >{{ char }}</span
          >
        </div>
        <div class="al-right-box">
          <span
             v-for="({ char, index }, displayIndex) in getProcessedChars('酉', false)"
            :key="index"
            :class="{ on: isMatch('酉'), active: CJClickedIdx === index && selectedChar === char, 
              isPrint: isPrint
            }"
            @click="handleClick('fri', char, index)"
            >{{ char }}</span
          >
        </div>
      </div>
    </div>

    <!-- 수 -->
    <div class="txt-five-box st-02">
      <div class="inner-center-box">
        <div class="txt-top">
          <div class="al-left-box">
            <div class="char-wrapper"  v-for="({ char, index }, displayIndex) in getProcessedChars('壬', true)" :key="index">
              <!-- "일간" 표시 -->
              <div
                v-if="char === skyDayData.char && globalIndex(index, '壬') === skyDayData.index"
                class="skyDay-label"
                :class="{isPrint: isPrint}"
              >
                일간
              </div>
              <!-- 문자 표시 -->
              <span
                :class="{ on: isMatch('壬'), 
                skyDay: char === skyDayData.char && globalIndex(index, '壬') === skyDayData.index,
                active: CJClickedIdx === index && selectedChar === char, 
                isPrint: isPrint}"
                @click="handleClick('wed', char, index)"
              >
                {{ char }}
              </span>
            </div>
          </div>
          <div class="al-right-box">
            <div class="char-wrapper"  v-for="({ char, index }, displayIndex) in getProcessedChars('癸', false)" :key="index">
              <!-- "일간" 표시 -->
              <div
                v-if="char === skyDayData.char && globalIndex(index, '癸') === skyDayData.index"
                class="skyDay-label"
                :class="{isPrint: isPrint}"
              >
                일간
              </div>
              <!-- 문자 표시 -->
              <span
                :class="{ on: isMatch('癸'), 
                skyDay: char === skyDayData.char && globalIndex(index, '癸') === skyDayData.index,
                active: CJClickedIdx === index && selectedChar === char, 
                isPrint: isPrint}"
                @click="handleClick('wed', char, index)"
              >
                {{ char }}
              </span>
            </div>
          </div>
        </div>
        <div class="txt-line-box" :class="{isPrint: isPrint}"></div>
        <div class="txt-btm">
          <div class="al-left-box">
            <span
               v-for="({ char, index }, displayIndex) in getProcessedChars('亥', true)"
              :key="index"
              :class="{ on: isMatch('亥'), active: CJClickedIdx === index && selectedChar === char, 
                isPrint: isPrint
              }"
              @click="handleClick('wed', char, index)"
              >{{ char }}</span
            >
          </div>
          <div class="al-right-box">
            <span
               v-for="({ char, index }, displayIndex) in getProcessedChars('子', false)"
              :key="index"
              :class="{ on: isMatch('子'), active: CJClickedIdx === index && selectedChar === char, 
                isPrint: isPrint
              }"
              @click="handleClick('wed', char, index)"
              >{{ char }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <!-- 목 -->
    <div class="txt-five-box st-03">
      <div class="inner-center-box">
        <div class="txt-top">
          <div class="al-left-box">
            <div class="char-wrapper" v-for="({ char, index }, displayIndex) in getProcessedChars('甲', true)" :key="index">
              <!-- "일간" 표시 -->
              <div
                v-if="char === skyDayData.char && globalIndex(index, '甲') === skyDayData.index"
                class="skyDay-label"
                :class="{isPrint: isPrint}"
              >
                일간
              </div>
              <!-- 문자 표시 -->
              <span
                :class="{ on: isMatch('甲'), 
                skyDay: char === skyDayData.char && globalIndex(index, '甲') === skyDayData.index,
                active: CJClickedIdx === index && selectedChar === char, 
                isPrint: isPrint}"
                @click="handleClick('thu', char, index)"
              >
                {{ char }}
              </span>
            </div>
          </div>
          <div class="al-right-box">
            <div class="char-wrapper"  v-for="({ char, index }, displayIndex) in getProcessedChars('乙', false)" :key="index">
              <!-- "일간" 표시 -->
              <div
                v-if="char === skyDayData.char && globalIndex(index, '乙') === skyDayData.index"
                class="skyDay-label"
                :class="{isPrint: isPrint}"
              >
                일간
              </div>
              <!-- 문자 표시 -->
              <span
                :class="{ on: isMatch('乙'), 
                skyDay: char === skyDayData.char && globalIndex(index, '乙') === skyDayData.index,
                active: CJClickedIdx === index && selectedChar === char, 
                isPrint: isPrint}"
                @click="handleClick('thu', char, index)"
              >
                {{ char }}
              </span>
            </div>

          </div>
        </div>
        <div class="txt-line-box" :class="{isPrint: isPrint}"></div>
        <div class="txt-btm">
          <div class="al-left-box">
            <span
               v-for="({ char, index }, displayIndex) in getProcessedChars('寅', true)"
              :key="index"
              :class="{ on: isMatch('寅'), active: CJClickedIdx === index && selectedChar === char, 
                isPrint: isPrint
              }"
              @click="handleClick('thu', char, index)"
              >{{ char }}</span
            >
          </div>
          <div class="al-right-box">
            <span
               v-for="({ char, index }, displayIndex) in getProcessedChars('卯', false)"
              :key="index"
              :class="{ on: isMatch('卯'), active: CJClickedIdx === index && selectedChar === char, 
                isPrint: isPrint
              }"
              @click="handleClick('thu', char, index)"
              >{{ char }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <!-- 화 -->
    <div class="txt-five-box st-01">
      <div class="inner-center-box">
        <div class="txt-top">
          <div class="al-left-box">
            <div class="char-wrapper"  v-for="({ char, index }, displayIndex) in getProcessedChars('丙', true)" :key="index">
              <!-- "일간" 표시 -->
              <div
                v-if="char === skyDayData.char && globalIndex(index, '丙') === skyDayData.index"
                class="skyDay-label-left"
                :class="{isPrint: isPrint}"
              >
                일간
              </div>
              <!-- 문자 표시 -->
              <span
                :class="{ on: isMatch('丙'), 
                skyDay: char === skyDayData.char && globalIndex(index, '丙') === skyDayData.index,
                active: CJClickedIdx === index && selectedChar === char, 
                isPrint: isPrint}"
                @click="handleClick('tue', char, index)"
              >
                {{ char }}
              </span>
            </div>
          </div>
          <div class="al-right-box">
            <div class="char-wrapper"  v-for="({ char, index }, displayIndex) in getProcessedChars('丁', true)" :key="index">
              <!-- "일간" 표시 -->
              <div
                v-if="char === skyDayData.char && globalIndex(index, '丁') === skyDayData.index"
                class="skyDay-label-right"
                :class="{isPrint: isPrint}"
              >
                일간
              </div>
              <!-- 문자 표시 -->
              <span
                :class="{ on: isMatch('丁'), 
                skyDay: char === skyDayData.char && globalIndex(index, '丁') === skyDayData.index,
                active: CJClickedIdx === index && selectedChar === char, 
                isPrint: isPrint}"
                @click="handleClick('tue', char, index)"
              >
                {{ char }}
              </span>
            </div>
          </div>
        </div>
        <div class="txt-line-box" :class="{isPrint: isPrint}"></div>
        <div class="txt-btm">
          <div class="al-left-box">
            <span
               v-for="({ char, index }, displayIndex) in getProcessedChars('巳', false)"
              :key="index"
              :class="{ on: isMatch('巳'), active: CJClickedIdx === index && selectedChar === char, 
                isPrint: isPrint
              }"
              @click="handleClick('tue', char, index)"
              >{{ char }}</span
            >
          </div>
          <div class="al-right-box">
            <span
               v-for="({ char, index }, displayIndex) in getProcessedChars('午', false)"
              :key="index"
              :class="{ on: isMatch('午'), active: CJClickedIdx === index && selectedChar === char, 
                isPrint: isPrint
              }"
              @click="handleClick('tue', char, index)"
              >{{ char }}</span
            >
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
