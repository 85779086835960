<script setup>
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'

import PrintItemComponentFour from '@/pages/manse/components/PrintItemComponentFour.vue'

import FiveStartComponent from '@/pages/manse/components/FiveStartComponent.vue'
import FiveProTopComponent from '@/pages/manse/components/FiveProTopComponent.vue'

const { ...five } = fiveMixin()
const { ...useFun } = useFunctions()
const props = defineProps({
  birthTitle: {
    type: Object,
    default: null,
  },
  dataOrder: {
    type: Object,
    default: null,
  },
  rsDataAll: {
    type: Object,
    default: null,
  },
  langMode: {
    type: Object,
    default: null,
  },
  birthData: {
    type: Object,
    default: null,
  },
  birthEightTxt: {
    type: Object,
    default: null,
  },
  birthFiveCnt: {
    type: Object,
    default: null,
  },
  toggleProMode: {
    type: Object,
    default: null,
  },
  firstSegment: {
    type: Object,
    default: null,
  },
  secondSegment: {
    type: Object,
    default: null,
  },
  thirdSegment: {
    type: Object,
    default: null,
  },
  fourthSegment: {
    type: Object,
    default: null,
  },
  storedLangMode: {
    type: Object,
    default: null,
  },
  birthEightTxt: {
    type: Object,
    default: null,
  },
  birthData: {
    type: Object,
    default: null,
  },
  hapDataVal: {
    type: Object,
    default: null,
  },
  hapBlDataSky: {
    type: Object,
    default: null,
  },
  hapDataValEarth: {
    type: Object,
    default: null,
  },
  isLoading: {
    type: Object,
    default: null,
  },
  isPrint: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['open-ilgan', 'resetAll'])
onMounted(() => {})
const showHanja = (value, position) => {
  return props.langMode === 'CHNKOR' ? five.getHanjaTxt(value, position) : value
}
const gongmangTxt = (gong) => {
  if (props.toggleProMode === 'pro') {
    //return gong?.map((item) => five.getHanjaTxt2(item?.value)).join('ㆍ')
    return gong?.map((item) => five.getHanjaTxt2(item?.value)).join('')
  } else {
    return gong?.map((item) => item?.value).join('')
  }
}
const taeTxt = (tae) => {
  if (props.toggleProMode === 'pro') {
    return tae?.map((item) => five.getHanjaTxt2(item?.value)).join('')
  } else {
    return tae?.map((item) => item?.value).join('')
  }
}

// 1219테스트 (hgdev)
const isSkyDay = ref(false)
const ilganData = ref('')
const paljaIdx = ref(0)
const handleSkyDayClick = ({ whichDay, isSkyDayClicked, globalIdx }) => {
  console.log('이벤트 데이터:', { whichDay, isSkyDayClicked, globalIdx })
  if (isSkyDayClicked) {
    isSkyDay.value = true
    ilganData.value = whichDay
    paljaIdx.value = globalIdx
  } else {
    isSkyDay.value = false
    ilganData.value = whichDay
    paljaIdx.value = globalIdx
    emit('close-ilgan')
  }
}

// 일간 팝업 열기 요청
const openIlgan = () => {
  emit('open-ilgan')
}

// 스타일 되돌리기
const resetSkyDay = () => {
  console.log('resetSkyDay 호출됨')
  isSkyDay.value = false
  ilganData.value = null
  paljaIdx.value = null
  emit('close-ilgan')
}

const starRef = ref(null)
const resetAll = () => {
  starRef.value.resetSelectedState()
  resetSkyDay()
}

defineExpose({
  resetAll,
})
</script>
<template>
  <div class="date-pro-root-box">
    <FiveProTopComponent
      :birthTitle="birthTitle"
      :dataOrder="dataOrder"
      :rsDataAll="rsDataAll"
      :langMode="langMode"
      :isSkyDay="isSkyDay"
      :ilganData="ilganData"
      :paljaIdx="paljaIdx"
    />

    <div class="pro-five-wrap">
      <div class="pro-five-box">
        <!-- //TODO : five -->
        <FiveStartComponent 
        ref="starRef"
        :isPrint="isPrint"
        :birthData="birthData" 
        :birthEightTxt="birthEightTxt" 
        @skyDay-click="handleSkyDayClick"
        @skyDay-reset="resetSkyDay"
        @open-ilgan="openIlgan()"/>
        <div class="data-five-state">
          <div class="state-row sec-daeun-default">
            <div class="name">사주 오행</div>
            <div class="five-count-box data">
              <span class="item-box"
                ><em class="txt five03 ganji-txt">木</em
                ><em class="cnt">{{ birthData?.five?.five1?.num }}</em></span
              >
              <span class="item-box"
                ><em class="txt five01 ganji-txt">火</em
                ><em class="cnt">{{ birthData?.five?.five2?.num }}</em></span
              >
              <span class="item-box"
                ><em class="txt five05 ganji-txt">土</em
                ><em class="cnt">{{ birthData?.five?.five3?.num }}</em></span
              >

              <span class="item-box"
                ><em class="txt five04 ganji-txt">金</em
                ><em class="cnt">{{ birthData?.five?.five4?.num }}</em></span
              >
              <span class="item-box"
                ><em class="txt five02 ganji-txt">水</em
                ><em class="cnt">{{ birthData?.five?.five5?.num }}</em></span
              >
            </div>
          </div>
          <div class="state-row sec-daeun-selected">
            <div class="name"><i class="icon-frame" /><span>대운 적용</span></div>
            <div class="five-count-box data">
              <span class="item-box"
                ><em class="txt five03 ganji-txt">木</em
                ><em class="cnt">{{ birthFiveCnt?.five1?.num }}</em></span
              >
              <span class="item-box"
                ><em class="txt five01 ganji-txt">火</em
                ><em class="cnt">{{ birthFiveCnt?.five2?.num }}</em></span
              >
              <span class="item-box"
                ><em class="txt five05 ganji-txt">土</em
                ><em class="cnt">{{ birthFiveCnt?.five3?.num }}</em></span
              >

              <span class="item-box"
                ><em class="txt five04 ganji-txt">金</em
                ><em class="cnt">{{ birthFiveCnt?.five4?.num }}</em></span
              >
              <span class="item-box"
                ><em class="txt five02 ganji-txt">水</em
                ><em class="cnt">{{ birthFiveCnt?.five5?.num }}</em></span
              >
            </div>
          </div>
          <!-- <div class="state-row"> -->
          <div class="state-row" :class="{ 'sec-daeun-default': isApplyDaeun }">
            <div class="name">천간 변화</div>
            <div class="data" :class="{ 'none-data': hapDataVal === '' }">
              {{ hapDataVal || '원국 천간변화가 없습니다.' }}
            </div>
          </div>
          <div v-if="isApplyDaeun" class="state-row sec-daeun-selected">
            <div class="name"><i class="icon-frame" /><span>대운 적용</span></div>
            <div class="data">
              {{ isLoading ? '' : hapBlDataSky || '원국 천간변화가 없습니다.' }}
            </div>
          </div>

          <!-- <div class="state-row"> -->
          <div class="state-row" :class="{ 'sec-daeun-default': isApplyDaeun }">
            <div class="name">지지 변화</div>
            <div class="data" :class="{ 'none-data': hapDataValEarth === '' }">
              {{ hapDataValEarth || '원국 지지변화가 없습니다.' }}
            </div>
          </div>
          <div class="state-row">
            <div class="name">공망/태월</div>
            <div class="data">
              {{ gongmangTxt(rsDataAll.gongmang) }} / {{ taeTxt(rsDataAll.taewon) }}
            </div>
          </div>
        </div>
      </div>
      <div class="pro-age-box">
        <div class="print-btm-box st-width-4">
          <div class="box01">
            <PrintItemComponentFour
              class="box-box"
              :pr-item="firstSegment"
              :storedLangMode="storedLangMode"
              :pr-item-mode="'one'"
              :pr-selectdaeun="rsDataAll?.selectdaeun"
              :isPrint="isPrint"
            />
          </div>
          <div class="box01">
            <PrintItemComponentFour
              class="box-box"
              :pr-item="secondSegment"
              :storedLangMode="storedLangMode"
              :pr-item-mode="'two'"
              :pr-selectdaeun="rsDataAll?.selectdaeun"
              :isPrint="isPrint"
            />
          </div>
          <div class="box01">
            <PrintItemComponentFour
              class="box-box"
              :pr-item="thirdSegment"
              :storedLangMode="storedLangMode"
              :pr-item-mode="'three'"
              :pr-selectdaeun="rsDataAll?.selectdaeun"
              :isPrint="isPrint"
            />
          </div>
          <div class="box01">
            <PrintItemComponentFour
              class="box-box"
              :pr-item="fourthSegment"
              :storedLangMode="storedLangMode"
              :pr-item-mode="'four'"
              :pr-selectdaeun="rsDataAll?.selectdaeun"
              :isPrint="isPrint"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
