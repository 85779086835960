<script setup>
import { nextTick, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'

import { DEV_MODE } from '@/main'
import { dailyPersonalData, monthlyLuckData } from './tempData'

/** API */
import { getDailyCategoryAPI, getDailyFortuneDetailAPI, getDailyFortunePriceAPI,  getMySajuAPI,  getMySajuDetailAPI, getMySajuGroupAPI, purchaseDailyCategoryAPI, purchaseDailyFortuneAPI, setMySajuAPI } from '@/api/calendarApi'

/** pinia */
import { usePaymentsStore } from '@/store/modules/payments'

/** composables */
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'
import { useCalendar, useCalendarFilter, useGesture } from '@/composables'
import { useSnackbar } from '@/composables/useSnackbar'
import { useScrollLock } from '@/composables/useScrollLock'
import { createYinYangCount } from '@/composables/useSajuFunction'

/** 컴포넌트 */
import FullCalendar from '@fullcalendar/vue3'
import SubscribeInfo from '@/components/calendar/SubscriptionInfo.vue'
import SajuClockPopup from '@/components/calendar/SajuClockPopup.vue'
import ComLatest from '@/components/manse/ComLatest.vue'
import { VNavigationDrawer } from 'vuetify/lib/components/index.mjs'
import CalendarSidebar from '@/components/calendar/CalendarSidebar.vue'
import CalendarHeader from '@/components/calendar/CalendarHeader.vue'
import PopMySaju from '@/components/calendar/PopMySaju.vue'
import AlertComponent from '@/pages/manse/components/Alert.vue'
import ConfirmComponent from '@/pages/manse/components/Confirm.vue'
import PointConfirm from '@/components/calendar/PointConfirm.vue'
import Snackbar from '@/components/common/Snackbar.vue'
import { useDailyFortune } from '@/composables/calendar/useDailyFortune'
import { useMySaju } from '@/composables/calendar/useMySaju'

definePage({ meta: { layout: 'manse' } })

const store = useStore()
const userId = ref(null)
userId.value = store.getters['user/id']

const paymentsStore = usePaymentsStore();

const router = useRouter();

const { getFiveClass, getGanjiColorFive, ...five } = fiveMixin();
const { formatNumber } = useFunctions();

/** 로딩 인디케이터 상태 */
const isLoading = ref(false);

const isDailyFortuneLoading = ref(false);
// /** '내 사주' 로딩 상태 */
// const isMySajuLoading = ref(false);
/** '사주시계' 팝업 상태 */
const isPopSajuClock = ref(false);
/** '사주시계' 참조 */
const currentClockRef = ref(null);
/** alert 참조 */
const dialogAlertRef = ref(null);
/** confirmAlert 참조 */
const dialogConfirmRef = ref(null);
/** 포인트 결제 Alert 참조 */
const pointConfirmRef = ref(null);
const dialogTitle = ref('');
const dialogTitle2 = ref('');
const dialogText = ref('');
const dialogNextUrl = ref(null);
// /** '내 사주' 선택 팝업 열림 상태 */
// const isPopMySajuOpen = ref(false);
// /** 선택된 '내 사주' guestUserId */
// const selectedMySajuId = ref(null);



/** 일진달력 선택 여부 - true: 일진달력, false: 역학달력 */
const isDailyFortuneCalendarOpen = ref(false)

/** 일진보기 drawer 열림 상태 */
const isDailyFortuneViewOpen = ref(false);

/** 등록된 '내 사주' 데이터 */
const sajuData = ref({});
/** 내 사주 정보 유무 확인 */
const isSajuDataExist = computed(() => Object.keys(sajuData.value).length !== 0);
// /** '내 사주' 그룹 내 목록 */
// const mySajuList = ref({});

const selectedDateInfo = ref(null)
const dailyLuckData = ref(null);
/** 일진보기 상세 데이터 */
// const dailyFortuneDetail = ref(null);

/** 일진달력 포인트 가격 조회 */
const getDailyFortunePrice = async () => {
  try {
    const result = await getDailyFortunePriceAPI();
    if (result) {
      dailyFortunePrice.value = result.day;
      categoryPrice.value = result.category;
      monthlyFortunePrice.value = result.month;
    }
  } catch (e) {
    console.error('일진달력 서비스별 가격 조회 실패:', e);
  }
}

/** 일진달력 카테고리 조회 */
const getDailyCategory = async (activeDate) => {
  const params = {
    activeDate,
  };

  try {
    const result = await getDailyCategoryAPI(params);
    dailyFortuneDetail.value = result;
    console.log('========================================')
    console.log('result:', result)
    console.log('========================================')
    
  } catch (e) {
    
  }
}

/** 날짜 클릭 시 이너뷰 열기 */
const openInnerView = async ({info, actionType}) => {
  if (!userId.value) return;

  const date = actionType === 'opened' ? info.day : info.date;
  const dataKey = moment(date).format('YYYYMMDD');
  const yyyymmdd = moment(date).format('YYYY-MM-DD');
  
  if (info) selectedDateInfo.value = date;
  if (isDailyFortuneViewOpen) {
    try {
      // await getDailyCategory(yyyymmdd);
      // dailyLuckData.value = monthlyLuckData.value[dataKey];

      console.log('========================================')
      console.log('info:', yyyymmdd)
      console.log('actionType:', actionType)
      console.log('========================================')
      
      if (actionType === 'opened') {
        isDailyFortuneViewOpen.value = true;
        await getDailyFortuneDetail(yyyymmdd);
        
        
      } else if (actionType !== 'past') {
        // monthlyLuckData.value[dataKey].isOpened = true;
        // isDailyFortuneViewOpen.value = true;
        calendarApi.value.render();
      } else {
        showPointConfirm({
          title: moment(date).format('YYYY년 MM월 DD일'),
          subtitle: '운세를 구매 하시겠습니까?',
          point: formatNumber(dailyFortunePrice.value),
          confirmText: '구매',
          onConfirm: async () => await confirmPurchaseFortune({yyyymmdd, dataKey}),
        })
      }
    } catch (e) {
      
    }
  }
};


/** 일진보기 상세 데이터 요청 */
const getDailyFortuneDetail = async (yyyymmdd) => {
  isDailyFortuneLoading.value = true;
  const params = {
    activeDate: yyyymmdd,
  };

  try {
    const result = await getDailyFortuneDetailAPI(params);
    
    if (result) {      
      dailyFortuneDetail.value = result.data;
      dailyCategoryStatus.value = result.status;
      isDailyFortuneViewOpen.value = true;
    }
  } catch (e) {
    
  } finally {
    isDailyFortuneLoading.value = false;
  }
}

/** 이너뷰 닫기 */
const closeInnerView = () => {
  if (isDailyFortuneViewOpen) isDailyFortuneViewOpen.value = false;
};

const dialogSubtitle = ref('')

/** 날짜 별 일진 차감 포인트 */
const dailyFortunePrice = ref(60);
/** '카테고리 별 운세' 차감될 포인트 */
const categoryPrice = ref(20);
/** 달 전체 일진 차감 포인트 */
const monthlyFortunePrice = ref(1000);

const displayPoint = ref('');
const showPointConfirm = ({title, subtitle, point, confirmText, onConfirm = () => {}}) => {
  dialogTitle.value = title;
  dialogSubtitle.value = subtitle;
  dialogText.value = confirmText;
  displayPoint.value = point;
  dialogNextUrl.value = onConfirm;
  pointConfirmRef.value.openConfirmDialog();
}

/** 확인 알림창 표시 */
const showDialogAlert = ({title, text = '', nextUrl = ''}) => {
  dialogTitle.value = title;
  dialogText.value = text;
  dialogAlertRef.value.openAlertDialog();
};

/** 확인/취소 알림창 표시 */
const showDialogConfirm = ({title, title2 = '', text = '', onConfirm = () => {}}) => {
  dialogTitle.value = title
  dialogTitle2.value = title2
  dialogText.value = text
  dialogNextUrl.value = onConfirm // 함수로 전달
  dialogConfirmRef.value.openConfirmDialog()
};

/** 로그인 알림창 표시 */
const showLoginConfirm = () => {
  showDialogConfirm({
    title: '로그인 하시면',
    title2: '일진을 보실 수 있습니다.',
    text: '로그인 하러 가기',
    onConfirm: () => router.push('/auth/login')
  });
};

/** 내 사주 등록 알림창 표시 */
const showSelectConfirm = () => {
  showDialogConfirm({
    title: '내 사주 등록 후,',
    title2: '일진을 보실 수 있습니다.',
    text: '내 사주 등록',
    onConfirm: () => openPopMySaju()
  });
}

/** 달력 필터 */
const {
  isLunarChecked,
  isSeasonChecked,
  isHolidayChecked,
  isKorChecked,
  isColorChecked,
  toggleOptions,
} = useCalendarFilter({isKorCheckedDefault: true});

/** 달력 생성 */
const {
  calendarApi,
  calendarOptions,
  refCalendar,
  viewTitle,
  selectedDate,
  selectedData,
  firstSeason,
  firstSeasonMonth,
  prev,
  next,
  isToday,
  changeViewTitle,
  setPrevSelectedDate,
  getDailyFortuneCalendar,
} = useCalendar({
  isLoading,
  isLunarChecked,
  isColorChecked,
  isSeasonChecked,
  isKorChecked,
  isHolidayChecked,
  calendarType: 'web',
  isDailyFortuneViewOpen,
  isDailyFortuneCalendarOpen,
  userId,
  sajuData,
  isSajuDataExist,
  dailyFortunePrice,
  openInnerView,
  showDialogAlert,
  showLoginConfirm,
  showSelectConfirm,
});

/** 통합 제스처 핸들러 */
const {
  handleStart,
  handleMove,
  handleEnd
} = useGesture({
  onSwipeLeft: next,
  onSwipeRight: prev,
});

/** '내 사주' 등록/관리 로직 */
const {
  isMySajuLoading,
  isPopMySajuOpen,
  mySajuList,
  selectedMySajuId,
  getMySaju,
  getMySajuList,
  openPopMySaju,
  closePopMySaju,
  selectMySaju,
  confirmSelectSaju,
  setMySaju,
  goManse,
} = useMySaju({
  calendarApi,
  userId,
  sajuData,
  showDialogConfirm,
  showLoginConfirm,
});



const purchaseDailyFortune = async ({yyyymmdd, purchaseType}) => {  
  const [year, month, day] = yyyymmdd.split('-');
  const params = {
    year, // 필수
    month,  // 필수
    purchaseType, // "DAY", "MONTH" (필수)
    ...(purchaseType === "DAY" && { day }), // purchaseType: "DAY" 일 때
  }

  try {
    const result = await purchaseDailyFortuneAPI(params);    

    if (result) {
      paymentsStore.getOwnPoint();
      triggerSnackbar('운세가 구매되었습니다.');
      await getDailyFortuneCalendar(`${year} ${month}`);
      calendarApi.value.render();
    }
  } catch (e) {
    console.error('결제 실패:', e.response.data.detail);
    if (e.response.data.detail === '포인트가 부족합니다.') {
      showRechargeConfirm();
    }
  }
}

/** 포인트 충전 팝업 */
const showRechargeConfirm = () => {
  showDialogConfirm({
    title: '보유 낭만 포인트가 부족합니다.',
    title2: '지금 바로 충전하시겠습니까?',
    text: '충전하기',
    onConfirm: () => router.push('/mypage/subscrPoint'),
  });
}

/** 달 전체 일진 구매 */
const purchaseMonthly = () => {
  // 로그인 체크
  if (!userId.value) {
    showLoginConfirm();
    return;
  }
  // 내 사주 체크
  if (!isSajuDataExist.value) {
    showSelectConfirm();
    return;
  }

  const currentDate = refCalendar.value.calendar.currentData.currentDate;
  
  showPointConfirm({
    title: moment(currentDate).format('YYYY년 MM월'),
    subtitle: '운세를 구매 하시겠습니까?',
    point: formatNumber(monthlyFortunePrice.value),
    confirmText: '구매',
    onConfirm: async () => {          
      await purchaseDailyFortune({
        yyyymmdd: moment(currentDate).format('YYYY-MM-DD'),
        purchaseType: 'MONTH',
      });
    },
  })
}

/** '카테고리 별 운세' 구매 */
const purchaseDailyCategory = async ({yyyymmdd, category, categoryPrice}) => {
  const params = {
    activeDate: yyyymmdd,  // yyyy-mm-dd
    category, // "STUDY", "WORK", "WEALTH", "LOVE", "HEALTH", "FAMILY", "MOVE", "RELATIONSHIP", "FAME", "SOCIAL"
    categoryPrice,  // 차감 포인트
  }

  try {
    const result = await purchaseDailyCategoryAPI(params);    
    if (result) {
      paymentsStore.getOwnPoint();
      triggerSnackbar('운세가 구매되었습니다.');
      // dailyLuckData.value.isOpened[category] = true;    
      await getDailyFortuneDetail(yyyymmdd)
      setSelectedCategory(key);
    }
  } catch (e) {
    DEV_MODE && console.error('카테고리 별 운세 구매 실패', e);
    
    // 임시
    // dailyLuckData.value.isOpened[category] = true;    
    // setSelectedCategory(key);
  }
}

/** 일진보기 결제 */
const confirmPurchaseFortune = async ({yyyymmdd, dataKey}) => {  
  // 결제 로직
  await purchaseDailyFortune({yyyymmdd, purchaseType: 'DAY'});

  await getDailyFortuneDetail(yyyymmdd);
}

/** 일진보기 상세 화면 */
const {
  yinYangCount,
  freeCategoryCount,
  dailyFortuneDetail,
  dailyCategoryStatus,
  luckButtonDisabled,
  categoryList,
  selectedCategoryList,
  selectedCategory,
  dailyCategoryDetail,
  clickCategoryButton,
  setSelectedCategory,
} = useDailyFortune({
  isDailyFortuneViewOpen,
  sajuData,
  dailyLuckData,
  categoryPrice,
  selectedDateInfo,
  showPointConfirm,
  getDailyFortuneDetail,
  purchaseDailyCategory,
});

/** 열려있는 팝업/모달 상태 확인 */
const isAnyPopupOpen = computed(() => 
  isPopSajuClock.value
  || isPopMySajuOpen.value
  || isDailyFortuneViewOpen.value
  || isLoading.value
  || (dialogAlertRef.value?.isAlertDialogVisible ?? false)
  || (dialogConfirmRef.value?.isConfirmDialogVisible ?? false)
  || (pointConfirmRef.value?.isConfirmDialogVisible ?? false)
);

/** 스크롤 제어 */
const {} = useScrollLock({activeScrollLock: isAnyPopupOpen});

/** 스낵바 */
const { snackbarRef, triggerSnackbar } = useSnackbar();

onMounted(async () => {
  if (DEV_MODE) {

    getDailyFortunePrice();
    if (userId.value) {
      paymentsStore.getSubscription();
      paymentsStore.getOwnPoint();
    }
    
  } else {
    router.push('/main')
  }
});

// 일진달력 클릭했을 때, 내 사주 정보 확인
watch(isDailyFortuneCalendarOpen, (isOpen) => {
  if (isOpen && userId.value) {
    getMySaju();
  }
})

/** TODAY 버튼 클릭 */
const today = () => {
  refCalendar.value.getApi().gotoDate(new Date());
  setPrevSelectedDate();
  selectedDate.value = moment().format('YYYY-MM-DD');
  changeViewTitle();
}
/** 사주시계 팝업 상태관리 */
const setSajuClock = (state) => (isPopSajuClock.value = state);

/** 일진보기 상세 기본 형식 */
const DEFAULT_DAILY_FORTUNE_DETAIL = {
  iljuSky: '',
  iljuEarth: '',
  iljuSkyClass: '',
  iljuEarthClass: '',
  갑자의미: '',
  점수: '',
  확률: '',
};

/** 일진보기 상세 데이터 객체 */
const dailyDetailObject = computed(() => {
  if (!dailyFortuneDetail.value) return DEFAULT_DAILY_FORTUNE_DETAIL;
  const detail = dailyFortuneDetail.value;
  const [iljuSky, iljuEarth] = detail['명주정보'].today.cg.split('');

  return {
    iljuSky: five.getHanjaTxt(iljuSky, 'sky'),
    iljuEarth: five.getHanjaTxt(iljuEarth, 'earth'),
    iljuSkyClass: getFiveClass(getGanjiColorFive(iljuSky)),
    iljuEarthClass: getFiveClass(getGanjiColorFive(iljuEarth)),
    갑자의미: detail['갑자의미'],
    점수: parseInt(detail['일운종합']['일운']['실제점수']),
    확률: parseInt(detail['일운종합']['일운']['달성확율']),
  };
});


// 지지와 해당하는 지장간 데이터
const branchToHiddenStems = {
  "子": ["계"],
  "丑": ["기", "계", "신"],
  "寅": ["갑", "병", "무"],
  "卯": ["을"],
  "辰": ["무", "을", "계"],
  "巳": ["병", "무", "경"],
  "午": ["정", "기"],
  "未": ["기", "정", "을"],
  "申": ["경", "임", "무"],
  "酉": ["신"],
  "戌": ["무", "신", "정"],
  "亥": ["임", "갑"]
};

/**
 * 오늘의 일주에서 지지에 맞는 지장간을 반환하는 함수
 * @param {string} earthlyBranch - 오늘의 지지 (한 글자: 子, 丑, 寅 등)
 * @returns {string[]} - 해당 지지의 지장간 배열
 */
const getHiddenStems = (earthlyBranch) => {
  if (branchToHiddenStems[earthlyBranch]) {
    return branchToHiddenStems[earthlyBranch];
  } else {
    // throw new Error(`Invalid earthly branch: ${earthlyBranch}`);
    return '';
  }
}
</script>

<template>
  <div class="calendar-wrap v2">
    <SubscribeInfo :userId="userId"/>
    <VNavigationDrawer
      v-if="isDailyFortuneViewOpen && DEV_MODE"
      v-model="isDailyFortuneViewOpen"
      app
      temporary
      location="right"
      width="auto"
    >
      <div class="drawer-container">
        <div class="drawer-header">
          <div class="title">
            {{ moment(selectedDateInfo).format('YYYY년 MM월 DD일') }}
            <div class="day-ganji">
              <div class="ganji-txt" :class="dailyDetailObject.iljuSkyClass">{{ dailyDetailObject.iljuSky }}</div>
              <div class="ganji-txt" :class="dailyDetailObject.iljuEarthClass">{{ dailyDetailObject.iljuEarth }}</div>일
            </div>
          </div>
          <button class="close" @click="closeInnerView"></button>
        </div>

        <div class="drawer-body" style="position: relative">
          <div class="drawer-section">
            <div class="drawer-card-box">
              <div class="drawer-card clear" style="flex-direction: column; gap: 2px">
                <div class="symbol">{{ dailyDetailObject['갑자의미']['상징'] }}</div>
                <div class="meaning">{{ dailyDetailObject['갑자의미']['의미'] }}</div>
              </div>
              <div class="drawer-card clear">
                <i :class="`rate${dailyDetailObject['점수']}`"></i>
                <div>에너지 지수 {{dailyDetailObject['확률']}}%</div>
              </div>
            </div>

            <div class="drawer-content">
              <div class="title">{{ dailyFortuneDetail ? dailyFortuneDetail['사자성어']['four_word'] : ''}}</div>
              <div>
                {{ dailyFortuneDetail ? dailyFortuneDetail['사자성어']['four_mean'] : '' }}
                <!-- "준비가 있으면 근심이 없다"는 뜻으로,<br>에너지를 미리 비축해 두는 것의 중요성을 의미합니다. -->
              </div>
              <div>
                {{ dailyFortuneDetail ? dailyFortuneDetail['사자성어']['four_message'] : '' }}
                <!-- "쉬어 가는 것도 하나의 능력입니다.<br>잠시 멈추고 숨을 고르며 다시 힘을 모아 나아가세요.<br>당신의 에너지는 곧 빛날 거예요." -->
              </div>
            </div>

            <div class="drawer-content">
              <div class="title">
                <div class="ganji-txt" :class="getFiveClass(getGanjiColorFive(sajuData.sajugangi.split('')[1]))">
                  {{ sajuData.sajugangi.split('')[1] }}
                </div>
                <div class="ganji-txt" :class="getFiveClass(getGanjiColorFive(sajuData.sajugangi.split('')[5]))">
                  {{ sajuData.sajugangi.split('')[5] }}
                </div>
                일주가&nbsp;
                <div class="ganji-txt" :class="getFiveClass(getGanjiColorFive(dailyDetailObject.iljuSky))">
                  {{ dailyDetailObject.iljuSky }}
                </div>
                <div class="ganji-txt" :class="getFiveClass(getGanjiColorFive(dailyDetailObject.iljuEarth))">
                  {{ dailyDetailObject.iljuEarth }}
                </div>
                일을 만나면?
              </div>
              <div v-if="dailyFortuneDetail" class="drawer-card">
                <div class="card-subtitle">나의 오행</div>
                <div class="saju-row">
                  <div class="five">
                    <div class="value">
                      <div class="txt five03 ganji-txt">木</div>{{ dailyFortuneDetail['음양오행'] ? dailyFortuneDetail['음양오행']['내사주_오행']['목'] : '' }}
                    </div>
                    <div class="value">
                      <div class="txt five01 ganji-txt">火</div>{{ dailyFortuneDetail['음양오행'] ? dailyFortuneDetail['음양오행']['내사주_오행']['화'] : '' }}
                    </div>
                    <div class="value">
                      <div class="txt five05 ganji-txt">土</div>{{ dailyFortuneDetail['음양오행'] ? dailyFortuneDetail['음양오행']['내사주_오행']['토'] : '' }}
                    </div>
                    <div class="value">
                      <div class="txt five04 ganji-txt">金</div>{{ dailyFortuneDetail['음양오행'] ? dailyFortuneDetail['음양오행']['내사주_오행']['금'] : '' }}
                    </div>
                    <div class="value">
                      <div class="txt five02 ganji-txt">水</div>{{ dailyFortuneDetail['음양오행'] ? dailyFortuneDetail['음양오행']['내사주_오행']['수'] : '' }}
                    </div>
                  </div>
                  <div class="value">
                    <div class="txt yang">양 {{ dailyFortuneDetail['음양오행'] ? dailyFortuneDetail['음양오행']['내사주_음양']['양'] : '' }}</div> /
                    <div class="txt yin">음 {{ dailyFortuneDetail['음양오행'] ? dailyFortuneDetail['음양오행']['내사주_음양']['음'] : '' }}</div>
                  </div>
                </div>
                <div class="card-title">{{ dailyDetailObject.iljuSky }}{{ dailyDetailObject.iljuEarth }}일을 만난 나의 오행</div>
                <div class="saju-row">
                  <div class="five">
                    <div class="value">
                      <div class="txt five03 ganji-txt">木</div>{{ dailyFortuneDetail['음양오행'] ? dailyFortuneDetail['음양오행']['오늘_오행']['목'] : '' }}
                    </div>
                    <div class="value">
                      <div class="txt five01 ganji-txt">火</div>{{ dailyFortuneDetail['음양오행'] ? dailyFortuneDetail['음양오행']['오늘_오행']['목'] : '' }}
                    </div>
                    <div class="value">
                      <div class="txt five05 ganji-txt">土</div>{{ dailyFortuneDetail['음양오행'] ? dailyFortuneDetail['음양오행']['오늘_오행']['목'] : '' }}
                    </div>
                    <div class="value">
                      <div class="txt five04 ganji-txt">金</div>{{ dailyFortuneDetail['음양오행'] ? dailyFortuneDetail['음양오행']['오늘_오행']['목'] : '' }}
                    </div>
                    <div class="value">
                      <div class="txt five02 ganji-txt">水</div>{{ dailyFortuneDetail['음양오행'] ? dailyFortuneDetail['음양오행']['오늘_오행']['목'] : '' }}
                    </div>
                  </div>
                  <div class="value">
                    <div class="txt yang">양 {{ dailyFortuneDetail['음양오행'] ? dailyFortuneDetail['음양오행']['합_음양']['양'] : '' }}</div> /
                    <div class="txt yin">음 {{ dailyFortuneDetail['음양오행'] ? dailyFortuneDetail['음양오행']['합_음양']['음'] : '' }}</div>
                  </div>
                </div>
              </div>

              <div class="drawer-card">
                <div class="title-row">
                  <div class="title-box">
                    <div class="card-title">오늘의 십성</div>
                    <div class="card-subtitle">{{ dailyDetailObject.iljuSky }}{{ dailyDetailObject.iljuEarth }}일을 만난 나, 오늘의 십성은?</div>
                  </div>
                  <!-- <div class="data-box">{{ dailyLuckData['sfb_at']['cc'] }} / {{ dailyLuckData['sfb_at']['gg'] }}</div> -->
                </div>

                <div class="card-content">
                  <div class="saju-container">
                    <svg class="lines">
                      <line id="line1" x1="0" y1="0" x2="0" y2="0" stroke-width="1px" stroke="var(--orange-300-main, #EB4C10)" />
                      <line id="line2" x1="0" y1="0" x2="0" y2="0" stroke-width="1px" stroke="var(--orange-300-main, #EB4C10)" />
                    </svg>
                    <div class="saju-box">
                      <div class="label">일간ㆍ{{ five.getYinYang(sajuData.sajugangi.split('')[1], 'sky') }}</div>
                      <div class="saju-icon" id="ts1" :class="five.getColorFive(sajuData.sajugangi.split('')[1])">
                        <div class="saju-text">{{ sajuData.sajugangi.split('')[1] }}</div>
                      </div>
                    </div>
                    <div class="saju-box">
                      <!-- <div class="label">{{ dailyLuckData['sfb_at']['cc'] }}ㆍ{{ five.getYinYang(dailyDetailObject.iljuSky, 'sky') }}</div> -->
                      <div class="saju-icon" id="ts2" :class="five.getColorFive(dailyDetailObject.iljuSky)">
                        <div class="saju-text">{{ dailyDetailObject.iljuSky }}</div>
                      </div>
                      <div class="saju-icon" id="ts3" :class="five.getColorFive(dailyDetailObject.iljuEarth)">
                        <div class="saju-text">{{ dailyDetailObject.iljuEarth }}</div>
                      </div>
                      <!-- <div class="label">{{ dailyLuckData['sfb_at']['gg'] }}ㆍ{{ five.getYinYang(dailyDetailObject.iljuEarth, 'earth') }}</div> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="drawer-card">
                <div class="title-row">
                  <div class="title-box">
                    <div class="card-title">오늘의 지장간</div>
                    <div class="card-subtitle">나와 오늘의 지장간?</div>
                  </div>
                  <!-- <div class="data-box">
                    <div 
                      class="ganji-txt"
                      :class="five.getColorFive(item)"
                      v-for="(item, index) in dailyLuckData['ggj_at']['ggj']" 
                      :key="index"
                    >
                      {{ item }}
                    </div>
                  </div> -->
                </div>

                <div class="card-content">
                  <div class="saju-container">
                    <div class="saju-box">
                      <div class="saju-icon" :class="five.getColorFive(sajuData.sajugangi.split('')[1])">
                        <div class="saju-text">{{ sajuData.sajugangi.split('')[1] }}</div>
                      </div>
                      <div class="saju-icon" :class="five.getColorFive(sajuData.sajugangi.split('')[5])">
                        <div class="saju-text">{{ sajuData.sajugangi.split('')[5] }}</div>
                      </div>
                      <div class="label">
                        <div 
                          v-for="(item, index) in getHiddenStems(sajuData.sajugangi.split('')[5])" 
                          :key="index"
                        >
                          {{ item }}
                        </div>
                      </div>
                    </div>
                    <div class="saju-box">
                      <div class="saju-icon txt-round" :class="five.getColorFive(dailyDetailObject.iljuSky)">
                        <div class="saju-text">{{ dailyDetailObject.iljuSky }}</div>
                      </div>
                      <div class="saju-icon" :class="five.getColorFive(dailyDetailObject.iljuEarth)">
                        <div class="saju-text">{{ dailyDetailObject.iljuEarth }}</div>
                      </div>
                      <div class="label">
                        <div 
                          v-for="(item, index) in getHiddenStems(dailyDetailObject.iljuEarth)" 
                          :key="index"
                        >
                          {{ item }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="drawer-divider"></div>
          
          <div class="drawer-section">
            <div class="drawer-content">
              <div class="title add">
                카테고리 별 운세도 확인해 보세요!
                <div class="subtitle">
                  (무료&nbsp;<div :class="{count: selectedCategoryList.length >= 3}">{{ Math.min(selectedCategoryList.length, freeCategoryCount) }}/</div><div class="count">{{ freeCategoryCount }}</div>, 이후 {{ categoryPrice }} p)
                </div>
              </div>
              <div class="button-container">
                <button
                  v-for="luck in categoryList"
                  :key="luck.key"
                  class="luck-button"
                  :class="{ active:  dailyCategoryStatus && dailyCategoryStatus[luck.label]['is_active'], disabled: luckButtonDisabled && !(dailyCategoryStatus && dailyCategoryStatus[luck.label]['is_active']) }"
                  @click="clickCategoryButton(luck.label)"
                >
                  <i :class="luck.iconClass"></i>
                  <div class="label">{{ luck.label }}</div>
                  <div class="label-hover" :class="{'full': luckButtonDisabled && !(dailyCategoryStatus && dailyCategoryStatus[luck.label]['is_active'])}">{{ luckButtonDisabled && !(dailyCategoryStatus && dailyCategoryStatus[luck.label]['is_active']) ? `${categoryPrice} p` : '보기' }}</div>
                </button>
              </div>
            </div>

            <div 
              v-if="selectedCategoryList && selectedCategoryList.length === 0"
              class="empty-content"
            >
              <div class="wrapper">
                <div class="title">매일 원하는 3개 주제의 일진을<br>선택해서 보실 수 있어요.</div>
                <div class="subtitle">추가 궁금한 주제는 낭만 포인트를 활용해서 보실 수 있습니다.</div>
              </div>
            </div>

            <div 
              v-if="selectedCategory"
              class="drawer-content luck"
            >
              <div class="title">{{ selectedCategory.label }}</div>
              {{ dailyCategoryDetail }}
              <!-- <div>{{ dailyPersonalData['생활종합'][selectedCategory.label] }}</div>
              <div>{{ dailyPersonalData['생활운세'][selectedCategory.label] }}</div>
              <div>{{ dailyPersonalData['생활추천'][selectedCategory.label] }}</div> -->
            </div>
          </div>

          <div v-if="isDailyFortuneLoading" class="loading-full-wrap drawer">
            <div class="inner-box">
              <div ref="loadingContainer" class="loading-container">
                <v-progress-circular :size="50" :width="3" color="primary" indeterminate />
              </div>
            </div>
          </div>
        </div>
      </div>
    </VNavigationDrawer>

    <div class="content-main mb-large">
      <section class="inner-base-calendar v2">
        <div style="height: 20px"></div>
        <div class="calendar-sidebar-layout">
          <CalendarSidebar
            :isLoading="isMySajuLoading"
            :isDailyFortuneCalendarOpen="isDailyFortuneCalendarOpen"
            :toggleOptions="toggleOptions"
            :sajuData="sajuData"
            :monthlyPrice="monthlyFortunePrice"
            @openDailyFortune="isDailyFortuneCalendarOpen = true"
            @closeDailyFortune="isDailyFortuneCalendarOpen = false"
            @openPopMySaju="openPopMySaju"
            @purchaseMonthly="purchaseMonthly"
          />

          <div class="calendar-main">
            <CalendarHeader
              :calendarType="'v2'"
              :title="viewTitle"
              :isToday="isToday()"
              :solarTerm="firstSeason"
              :solarTermMonth="firstSeasonMonth"
              :isColorChecked="isColorChecked"
              @prev="prev"
              @next="next"
              @today="today"
              @clock="setSajuClock(true)"
            />

            <div class="sec-sch-body">
              <!-- <div class="quick-menu-box">
                <ComLatest @printclick="printManse" />
              </div> -->
              <v-row
                class="fill-height"
                @mousedown="handleStart"
                @mousemove="handleMove"
                @mouseup="handleEnd"
                @touchstart="handleStart"
                @touchmove="handleMove"
                @touchend="handleEnd"
              >
                <v-col>
                  <v-sheet height="700">
                    <FullCalendar
                      ref="refCalendar"
                      class="full-calendar"
                      :options="calendarOptions"
                    />
                  </v-sheet>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </section>
    </div>

    <SajuClockPopup
      v-if="isPopSajuClock"
      ref="currentClockRef"
      @closeClock="setSajuClock(false)"
    />

    <PopMySaju
      :visible="isPopMySajuOpen"
      :mySajuList="mySajuList"
      :selectedMySajuId="selectedMySajuId"
      @close="closePopMySaju"
      @route="goManse"
      @select="selectMySaju"
      @confirm="confirmSelectSaju"
    />

    <AlertComponent
      ref="dialogAlertRef"
      :title="dialogTitle"
      :text="dialogText"
    />

    <ConfirmComponent
      ref="dialogConfirmRef"
      :title="dialogTitle"
      :title2="dialogTitle2"
      :text="dialogText"
      :nextUrl="dialogNextUrl"
    />

    <PointConfirm
      ref="pointConfirmRef"
      :title="dialogTitle"
      :subtitle="dialogSubtitle"
      :point="displayPoint"
      :nextUrl="dialogNextUrl"
      :confirm-text="'구매'"
    />
    
    <Snackbar ref="snackbarRef" />

    <div v-if="isLoading" class="loading-full-wrap">
      <div class="inner-box">
        <div ref="loadingContainer" class="loading-container">
          <v-progress-circular :size="50" :width="3" color="primary" indeterminate />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.v-navigation-drawer__scrim {
  background-color: rgba(0, 0, 0, 0) !important;
}
</style>

<style lang="scss">

.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent;
}
.v-calendar-monthly .v-calendar-weekly__head-weekday {
  height: 38px;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #23252e;
  font-size: 13px;
  font-weight: 500;
  border-bottom: 0.5px solid #e2e2e2;
}
.v-calendar-weekly__day-label {
  margin: 0 !important;
}
.v-calendar-weekly__day-label button.v-btn {
  display: none;
  margin: 0 !important;
}
.datePicker .v-card__text {
  padding: 20px !important;
}

.fc-day-sun {
  .fc-scrollgrid-sync-inner {
    .fc-col-header-cell-cushion {
      color: #eb4f5d;
    }
  }
}
.fc-day-sat {
  .fc-scrollgrid-sync-inner {
    .fc-col-header-cell-cushion {
      color: #5a6ef6;
    }
  }
}
.fc-col-header-cell {
  .fc-scrollgrid-sync-inner {
    .fc-col-header-cell-cushion {
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    a {
      color: inherit;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

.custom-day-cell.other {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  border-radius: 100%;
  cursor: pointer;
}
.custom-day-cell {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  border-radius: 100%;
  color: #23252e;
  cursor: pointer;
}
.custom-day-sunday,
.custom-day-holiday {
  color: #eb4f5d;
}
.custom-day-saturday {
  color: #5a6ef6;
}
.custom-day-highlight {
  background-color: #eb4c10;
  color: white;
}
.custom-day-selected {
  z-index: 100;
  background-color: #e1e1e1;
}
.custom-content-cell {
  height: 100%;
}
.fc-daygrid-event {
  box-shadow: none !important;
}
.fc-event::before,
.fc-event::after,
.custom-event-content::before,
.custom-event-content::after {
  // content: none !important;
  // display: none !important;
  background: transparent !important;
  box-shadow: none !important;
}
a.fc-event:focus {
  outline: none !important;
  box-shadow: none !important;
}

.custom-event-content {
  .custom-content-cell {
    .content-dayju {
      position: absolute;
      top: 12px;
      height: 18px;
      color: #23252e;

      .txt-chn {
        font-size: 18px;
      }
      
      .txt-kor {
        font-size: 14px;
        margin-left: 5px;
      }

      &.today-luck {
        color: var(--Gray-500, #949494);
        text-align: center;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.333px;
      }
    }
  }
}
.content-lunar {
  position: absolute;
  // top: 36px;
  top: 32%;
  left: 0;
  right: 0;
}
.content-holiday {
  font-size: 14px;
  position: absolute;
  left: 0;
  top: 54%;
  right: 0;
  margin-bottom: 0 !important;
}
.content-season {
  position: absolute;
  left: 0;
  top: 76%;
  right: 0;
}

table tbody tr {
  height: 3px;
}

/** FullCalendar 격자 설정 */
.fc-theme-standard .fc-scrollgrid {
  border-left: 1px solid var(--Gray-300, #EBEBEB);
  border-top: 1px solid var(--Gray-300, #EBEBEB);
}
.fc-theme-standard th {
  border: 1px solid var(--Gray-300, #EBEBEB);
}
.fc-theme-standard td {
  border: 1px solid var(--Gray-300, #EBEBEB);
}
</style>