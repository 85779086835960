<script setup>

const { ...five } = fiveMixin();
const { ...useFun } = useFunctions();

const props = defineProps({
  inputdata: {
    type: Object,
  },
  transdata: {
    type: Object,
  }
});

const displayData = reactive({
  name: props.inputdata.name || '-',
  gender: useFun.getGenderTxt(props.inputdata?.gender),
  korAge: props.transdata?.kor_age,
  manAge: props.transdata?.man_age,
  solar: useFun.formatCustomDate(props.transdata?.solar),
  week: props.transdata?.week,
  time: five.getBirthTimeTxt(
    five.getTimeTxt2(props.inputdata?.hour) + ':' + five.getTimeTxt2(props.inputdata?.min)
  ),
  lunar: useFun.formatCustomLunar(props.transdata?.lunar),
  bornArea: props.transdata?.bornArea?.county
    ? `${props.transdata?.bornArea.county}, ${props.transdata?.bornArea.city}, `
    : '',
  options: [
    `${useFun.formatOption4Txt(props.inputdata?.koreantime)} `,
    `${useFun.formatOption1Txt(props.inputdata?.earlytime)} `,
    useFun.formatOption2Txt(props.inputdata?.dongji),
  ].filter(Boolean),
});
</script>

<template>
  <div class="people-box">
    <div class="mem-info">
      <div class="name-box-d">
        <div class="name-d">{{ displayData.name }}</div>
        <div class="age">
          {{ displayData.gender }} {{ displayData.korAge }}세 (만{{ displayData.manAge }}세)
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <div class="birth-box">
      <div class="mb6">
        <div class="mr32">
          <span class="tits orange">양력</span>
          <span class="txts">
            {{ displayData.solar }} ({{ displayData.week }}) {{ displayData.time }}
          </span>
        </div>

        <div>
          <span class="tits blue">음력</span>
          <span class="txts">{{ displayData.lunar }}</span>
        </div>
      </div>

      <div class="w-wide">
        <span class="tits">옵션</span>
        <span class="txts">
          <span v-if="displayData.bornArea">{{ displayData.bornArea }}</span>
          <span v-for="(option, index) in displayData.options" :key="index">
            {{ option }}
          </span>
        </span>
      </div>
    </div>
  </div>
</template>