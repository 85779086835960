<script setup>
import VueApexCharts from 'vue3-apexcharts'

const props = defineProps({
  /** daily, mychart */
  mode: {
    type: String,
  },
});

const donutChartColors = {
  donut: {
    yin: '#757575',
    yang: '#EBEBEB',
    warm: '#FFD374',
    cold: '#75A4FD',
  },
}

const yinYangOptions = {
  chart: {
    type: 'donut',
    height: '160px',
    width: '160px',
    parentHeightOffset: 0,
  },
  labels: [
    '음의 기운',
    '양의 기운',
  ],
  colors: [
    donutChartColors.donut.yin,
    donutChartColors.donut.yang,
  ],
  stroke: { width: 0 },
  dataLabels: {
    enabled: false,
    formatter(val) {
      return `${ Number.parseInt(val) }%`
    },
  },
  legend: {
    show: true,
    position: 'bottom',
    itemMargin: {
      horizontal: 10
    }
  },
  tooltip: { theme: false },
  grid: { padding: { top: 0 } },
  plotOptions: {
    pie: {
      donut: {
        size: '50%',
        labels: {
          show: true,
          value: {
            fontSize: '1.125rem',
            color: 'rgba(var(--v-theme-on-background), var(--v-high-emphasis-opacity))',
            fontWeight: 500,
            offsetY: -15,
            formatter(val) {
              return `${ Number.parseInt(val) }%`
            },
          },
          name: { offsetY: 200 },
          total: {
            show: true,
            fontSize: '.7rem',
            color: 'rgba(var(--v-theme-on-background), var(--v-disabled-opacity))',
            label: '',
            formatter: () => '음/양'
          },
        },
      },
    },
  },
}

const warmColdOptions = {
  chart: {
    type: 'donut',
    height: '160px',
    width: '160px',
    parentHeightOffset: 0,
  },
  labels: [
    '뜨거운 기운',
    '차가운 기운',
  ],
  colors: [
    donutChartColors.donut.warm,
    donutChartColors.donut.cold,
  ],
  stroke: { width: 0 },
  dataLabels: {
    enabled: false,
    formatter(val) {
      return `${ Number.parseInt(val) }%`
    },
  },
  legend: {
    show: true,
    position: 'bottom',
    itemMargin: {
      horizontal: 10
    }
  },
  tooltip: { theme: false },
  grid: { padding: { top: 0 } },
  plotOptions: {
    pie: {
      donut: {
        size: '50%',
        labels: {
          show: true,
          value: {
            fontSize: '1.125rem',
            color: 'rgba(var(--v-theme-on-background), var(--v-high-emphasis-opacity))',
            fontWeight: 500,
            offsetY: -15,
            formatter(val) {
              return `${ Number.parseInt(val) }%`
            },
          },
          name: { offsetY: 200 },
          total: {
            show: true,
            fontSize: '.7rem',
            color: 'rgba(var(--v-theme-on-background), var(--v-disabled-opacity))',
            label: '',
            formatter: () => '조후'
          },
        },
      },
    },
  },
}

const dailyYinYangChartSeries = [
  62.2,
  37.8,
]
const dailyWarmColdChartSeries = [
  81.4,
  18.6,
]
const mychartYinYangChartSeries = [
  55.5,
  44.5,
]
const mychartWarmColdChartSeries = [
  31.4,
  68.6,
]

const chartTitle = props.mode === 'daily' ? '오늘의 음양과 조후' : '나의 음양';
</script>

<template>
  <VCol cols="12" sm="12" md="6" lg="4" xl="4">
    <VCard class="chart-card">
      <div class="chart-header">
        <div class="chart-title">{{ chartTitle }}</div>
      </div>

      <div class="donut-section">
        <div class="chart-item">
          <VueApexCharts
            type="donut"
            height="240px"
            :options="yinYangOptions"
            :series="mode === 'daily' ? dailyYinYangChartSeries : mychartYinYangChartSeries"
          />
        </div>
        <div class="chart-item">
          <VueApexCharts
            type="donut"
            height="240px"
            :options="warmColdOptions"
            :series="mode === 'daily' ? dailyWarmColdChartSeries : mychartWarmColdChartSeries"
          />
        </div>
      </div>
    </VCard>
  </VCol>
</template>
