import { useApiClient } from '@/api/ApiClient'
const { get2, post, post2, del, get, get3 } = useApiClient()

//FIXME: 상품조회
export const getProducts = async (params) => {
  let path = `/payments/products`
  const { data } = await get2(path, params)
  return data
}

//FIXME: 정기구독여부 확인
export const getBilling = async (params) => {
  let path = `/payments/billing`
  const { data } = await get2(path, params)
  return data
}

//TODO: 정기구독 빌링키신청
export const billingReg = async (params) => {
  let path = `/payments/billing/register`
  const { data } = await post(path, params)
  return data
}

//TODO: 정기구독신청
export const subscrReg = async (params) => {
  let path = `/payments/subscriptions/register`
  const data = await post(path, params)
  return data
}

//TODO: 정기구독해지
export const subscrDelete = async (params) => {
  let path = `/payments/billing`
  const { data } = await del(path, params)
  return data
}

//FIXME: 요금제 목록조회
export const getPlans = async (params) => {
  let path = `/payments/plans`
  const { data } = await get2(path, params)
  return data
}

//FIXME: 주문생성
export const createOrder = async (params) => {
  let path = `/payments/orders`
  const { data } = await post2(path, params)
  return data
}

//FIXME: 주문승인처리
export const orderConfirm = async (params) => {
  let path = `/payments/orders/{${params.orderId}}/confirm`
  const { data } = await post2(path, params)
  return data
}

//FIXME: 구독내역 전체 삭제
export const subscrDeleteAll = async (params) => {
  let path = `/payments/subscriptions/terminate`
  const { data } = await post(path, params)
  return data
}

//TODO: 이용중인구독서비스
export const paymentSubsrc = async (params) => {
  let path = `/payments/subscriptions`
  const { data } = await get2(path, params)
  return data
}

//TODO: 이용중인서비스
export const paymentOwn = async (params) => {
  let path = `/payments/own/product?${params}`
  const { data } = await get3(path)
  return data
}

//TODO: 이용중인서비스
export const paymentOwnDetail = async (orderId, params) => {
  let path = `/payments/own/product/${orderId}`
  const { data } = await get(path, params)
  return data
}

//TODO: 포인트내역
export const historyPointDetail = async (orderId, params) => {
  let path = `/payments/own/point/history/${orderId}`
  const { data } = await get(path, params)
  return data
}

//TODO: 결제취소
export const paymentCancel = async (orderId, params) => {
  let path = `/payments/orders/${orderId}/cancel`
  const { data } = await post2(path, params)
  return data
}

//TODO: 해지사유
export const subscrCancelReason = async (params) => {
  let path = `/payments/subscriptions/cancel/reason`
  const { data } = await get(path, params)
  return data
}

//TODO: 해지
export const subscrCancel = async (params) => {
  let path = `/payments/subscriptions/cancel`
  const data = await post(path, params)
  return data
}

//TODO: 보유포인트 조회
export const ownPoint = async (params) => {
  let path = `/payments/own/point`
  const { data } = await get(path, params)
  return data
}

//TODO: 포인트내역
export const historyPoint = async (params) => {
  let path = `/payments/own/point/history`
  const { data } = await get(path, params)
  return data
}
