import { defineStore } from 'pinia';
import moment from 'moment';
import { ownPoint, paymentSubsrc } from '@/api/PaymentApi';

export const usePaymentsStore = defineStore('payments', () => {
  /** 구독중인 서비스 */
  const subInfo = ref(null);

  const formattedSubInfoName = computed(() => {
    if (!subInfo.value) return '-';
    else {
      return subInfo.value.productName;
      // const [type, name] = subInfo.value.productName.replace(' ', '').split('-');
      // return `${name} (${type})`;
    }
  });
  
  const formattedSubInfoPeriod = computed(() => {
    if (!subInfo.value) return '';
    else {
      const startDate = moment(subInfo.value.startDate).format('YYYY.MM.DD');
      const endDate = moment(subInfo.value.endDate).format('YYYY.MM.DD');
      return `${startDate} ~ ${endDate}`;
    }
  });

  /** 보유 포인트 */
  const points = ref(null);

  /** 포인트 포맷팅 */
  const formattedPoints = computed(() => {
    if (points.value === null) return '-';
    else if (points.value < 1000) return points.value;
    else return points.value.toLocaleString('en-US');
  });

  /** 구독중인 서비스 조회 */
  const getSubscription = async () => {
    try {
      const result = await paymentSubsrc({});
      subInfo.value = result;
    } catch (e) {
      subInfo.value = null;
    }
  };

  /** 보유 포인트 조회 */
  const getOwnPoint = async () => {
    try {
      const result = await ownPoint({});
      points.value = result;
    } catch (e) {
      points.value = null;
    }
  };

  /** 구독/포인트 초기화 */
  const clearPaymentsInfo = () => {
    subInfo.value = null;
    points.value = null;
  };

  return {
    subInfo,
    formattedSubInfoName,
    formattedSubInfoPeriod,
    formattedPoints,
    getSubscription,
    getOwnPoint,
    clearPaymentsInfo,
  };
});
