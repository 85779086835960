<script setup>
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'

const { ...five } = fiveMixin()
const { ...useFun } = useFunctions()
const props = defineProps({
  birthTitle: {
    type: Object,
    default: null,
  },
  dataOrder: {
    type: Object,
    default: null,
  },
  rsDataAll: {
    type: Object,
    default: null,
  },
  langMode: {
    type: Object,
    default: null,
  },
  isSkyDay: {
    type: Boolean,
    default: false,
  },
  ilganData: {
    type: String,
    default: '',
  },
  paljaIdx: {
    type: Number,
    default: 0,
  },
})
onMounted(() => {
  console.log('fiveprotop에서', props.rsDataAll.birthganji)
})
const showHanja = (value, position) => {
  return props.langMode === 'CHNKOR' ? five.getHanjaTxt(value, position) : value
}

// getSkyClass 함수: item과 영역(sky/earth) 기반으로 클래스 반환
// const getSEClass = (item, type) => {
//   if (props.paljaIdx === null) return {}
//   const isMatched =
//     type === 'sky'
//       ? props.paljaIdx === Object.keys(props.rsDataAll?.birthganji?.sky).indexOf(item)
//       : props.paljaIdx === Object.keys(props.rsDataAll?.birthganji?.earth).indexOf(item) + 4

//   return {
//     skyDay: props.isSkyDay,
//     [props.ilganData]: isMatched,
//     'not-ilgan': !props.isSkyDay && isMatched,
//   }
// }
const getSEClass = (item, type) => {
  if (props.paljaIdx === null) return {};

  const skyKeys = Object.keys(props.rsDataAll?.birthganji?.sky || {});
  const earthKeys = Object.keys(props.rsDataAll?.birthganji?.earth || {});

  const isMatched =
    type === 'sky'
      ? props.paljaIdx === skyKeys.indexOf(item) // sky에서의 비교
      : props.paljaIdx === earthKeys.indexOf(item) + 4; // earth에서의 비교

  return {
    skyDay: props.isSkyDay,
    [props.ilganData]: isMatched,
    'not-ilgan': !props.isSkyDay && isMatched,
  };
};

</script>
<template>
  <div class="pro-brith-wrap">
    <div class="pro-birth-box">
      <div class="pro-tb-box">
        <table width="100%" class="prtTable">
          <tr>
            <td v-for="(item, index) in birthTitle.split('')" :key="index" class="fntTit">
              {{ item }}
            </td>
          </tr>
          <tr>
            <td v-for="(item, index) in dataOrder" :key="index" class="h-big">
              <div class="ganji-box" :class="getSEClass(item, 'sky')">
                {{ showHanja(rsDataAll?.birthganji?.sky?.[item]?.value, 'sky') }}
              </div>
            </td>
          </tr>
          <tr>
            <td v-for="(item, index) in dataOrder" :key="index" class="h-big">
              <div class="ganji-box" :class="getSEClass(item, 'earth')">
                {{ showHanja(rsDataAll?.birthganji?.earth?.[item]?.value, 'earth') }}
              </div>
              <!-- <div class="ganji-box" :class="getSEClass(item, 'earth')">
                {{ showHanja(rsDataAll?.birthganji?.earth?.[item]?.value, 'earth') }}
              </div> -->
            </td>
          </tr>
          <tr>
            <td v-for="(item, index) in dataOrder" :key="index" class="fntNor">
              {{ rsDataAll?.birthganji?.sky[item]?.six }}
            </td>
          </tr>
          <tr>
            <td v-for="(item, index) in dataOrder" :key="index" class="fntNor">
              {{ rsDataAll?.birthganji?.earth[item]?.six }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="pro-divider"></div>
    <div class="pro-current-box">
      <div class="pro-currunt-list">
        <div
          v-for="(item, index) in rsDataAll?.daeuns?.sky"
          :key="index"
          class="row-item-box"
          width="10%"
          :class="{ active: rsDataAll?.selectdaeun === item.num }"
        >
          <div class="txt-no" :class="{ fntActive: rsDataAll?.selectdaeun === item.num }">
            {{ item.num }}
          </div>
          <div class="txt-year">
            {{ item.year }}
          </div>
          <span class="ganji-txt">
            {{ showHanja(item.value, 'sky') }}
          </span>
          <span class="ganji-txt">
            {{ showHanja(rsDataAll?.daeuns.earth[index].value, 'earth') }}
          </span>
          <div class="txt-siz">
            {{ item.six }}
          </div>
          <div class="txt-earth">
            {{ rsDataAll?.daeuns.earth[index].six }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
