<script setup>
import { hexToRgb } from '@layouts/utils';
import VueApexCharts from 'vue3-apexcharts';
import { useTheme } from 'vuetify';

const props = defineProps({
  /** daily, monthly */
  mode: {
    type: String,
  },
})

const vuetifyTheme = useTheme()

const radarOptions = computed(() => {
  const radarColors = {
    series1: '#5790FC',
    series2: '#FF6E6E',
  }

  // const { legendTextColor, chartBorderColor, categoryTextColor } = colorVariables(themeColors)
  const categoryTextColor = '#757575';
  const chartBorderColor = '#EBEBEB';
  const legendTextColor = '#757575';

  return {
    chart: {
      parentHeightOffset: 0,
      toolbar: { show: false },
      dropShadow: {
        top: 1,
        blur: 8,
        left: 1,
        opacity: 0.2,
        enabled: false,
      },
    },
    markers: {
      size: 3,
      colors: [radarColors.series1, radarColors.series2],
    },
    fill: { opacity: [0.2, 0.2] },
    colors: [radarColors.series1, radarColors.series2],
    stroke: {
      width: 2,
      show: true,
    },
    legend: {
      floating: true,
      labels: {
        colors: legendTextColor,
      },
      markers: {
        offsetX: -4,
        offsetY: 1,
      },
      itemMargin: {
        // vertical: 3,
        // horizontal: 10,
      },
      position: 'right',
      offsetX: -40,
      offsetY: -20
    },
    plotOptions: {
      radar: {
        polygons: {
          strokeColors: chartBorderColor,
          connectorColors: chartBorderColor,
        },
      },
    },
    grid: {
      show: false,
      padding: {
        // top: -20,
        bottom: -40,
      },
    },
    yaxis: { show: false },
    xaxis: {
      categories: ['애정운', '직업운', '학습운', '사회공헌운', '명예운', '인간관계운', '이동운', '건강운', '재물운', '가족운'],
      labels: {
        style: {
          colors: [
            categoryTextColor,
            categoryTextColor,
            categoryTextColor,
            categoryTextColor,
            categoryTextColor,
            categoryTextColor,
            categoryTextColor,
            categoryTextColor,
            categoryTextColor,
            categoryTextColor,
          ],
        },
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
        // formatter: function (category) {
        //   return `항목: ${category}`;
        // },
      },
      y: {
        title: {
          formatter: (seriesName) => {
            // return `${seriesName}`
            return null
          }
        },
        formatter: (val) => {
          return `${val}%`
        }
      },
      // z: {
      //   title: '',
      //   formatter: (val) => {
      //     return `${val}%`
      //   }
      // },
      marker: {
        show: false
      },
      theme: 'dark',
      fixed: {
        enabled: true,
        position: 'topRight',
        offsetY: -1,
        offsetX: -100
      }
    }
  }
})

const series = [
  {
    name: '현재 대운',
    data: [
      96.3,
      57.9,
      57.2,
      26.2,
      32.4,
      76.3,
      57.9,
      77.2,
      86.2,
      62.4,
    ],
  },
  {
    name: '40 대운',
    data: [
      86.2,
      52.4,
      86.3,
      77.9,
      37.2,
      96.2,
      82.4,
      36.3,
      47.9,
      57.2,
    ]
  },
]

const chartTitle = props.mode === 'daily' ? '오늘의 에너지' : '월간 에너지';
</script>

<template>
  <VCol cols="12" sm="12" md="6" lg="4" xl="4">
    <VCard class="chart-card">
      <div class="chart-header">
        <div class="chart-title">{{ chartTitle }}</div>
      </div>
  
      <div class="chart-item chart-radar">
        <VueApexCharts
          type="radar"
          :options="radarOptions"
          :series="series"
          :height="280"
        />
      </div>
    </VCard>
  </VCol>
</template>