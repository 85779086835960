<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'guide' } })

import GuideProductList from '@/pages/guide/components/GuideProductList.vue'

const tabs = [
  { label: '전체', value: 'all' },
  { label: '대시보드', value: 'menu1' },
  { label: '성향분석', value: 'menu2' },
  { label: '사주분석', value: 'menu3' },
  { label: '운세분석', value: 'menu4' },
  { label: '오행도분석', value: 'menu5' },
  { label: '에너지궁합', value: 'menu6' },
  { label: '낭만일지', value: 'menu7' },
  { label: '일진달력', value: 'menu8' },
]

const currentTab = ref(tabs[0].value)

const galleryItems = {
  all: [
    {
      title: '만세력 이야기',
      subtitle: '임대표님이 알려주는',
      type: '학습자용',
      txt1: '성향분석',
      txt2: '사주로 보는 성향분석',
      txt3: '사주 오행 분석으로 나에 대해 알아보기',
      txt4: '#키워드',
      src: '/src/assets/images_manse/thumb01.png',
    },
    {
      title: '만세력 이야기',
      subtitle: '임대표님이 알려주는',
      type: '학습자용',
      txt1: '성향분석',
      txt2: '사주로 보는 성향분석',
      txt3: '사주 오행 분석으로 나에 대해 알아보기',
      txt4: '#키워드',
      src: '/src/assets/images_manse/thumb01.png',
    },
    {
      title: '만세력 이야기',
      subtitle: '임대표님이 알려주는',
      type: '학습자용',
      txt1: '성향분석',
      txt2: '사주로 보는 성향분석',
      txt3: '사주 오행 분석으로 나에 대해 알아보기',
      txt4: '#키워드',
      src: '/src/assets/images_manse/thumb01.png',
    },
  ],
  menu1: [
    {
      title: '만세력 이야기',
      subtitle: '임대표님이 알려주는',
      type: '학습자용',
      txt1: '성향분석',
      txt2: '사주로 보는 성향분석',
      txt3: '사주 오행 분석으로 나에 대해 알아보기',
      txt4: '#키워드',
      src: '/src/assets/images_manse/thumb01.png',
    },
    {
      title: '만세력 이야기',
      subtitle: '임대표님이 알려주는',
      type: '학습자용',
      txt1: '성향분석',
      txt2: '사주로 보는 성향분석',
      txt3: '사주 오행 분석으로 나에 대해 알아보기',
      txt4: '#키워드',
      src: '/src/assets/images_manse/thumb01.png',
    },
    {
      title: '만세력 이야기',
      subtitle: '임대표님이 알려주는',
      type: '학습자용',
      txt1: '성향분석',
      txt2: '사주로 보는 성향분석',
      txt3: '사주 오행 분석으로 나에 대해 알아보기',
      txt4: '#키워드',
      src: '/src/assets/images_manse/thumb01.png',
    },
  ],
  menu2: [
    {
      title: '만세력 이야기',
      subtitle: '임대표님이 알려주는',
      type: '학습자용',
      txt1: '성향분석',
      txt2: '사주로 보는 성향분석',
      txt3: '사주 오행 분석으로 나에 대해 알아보기',
      txt4: '#키워드',
      src: '/src/assets/images_manse/thumb01.png',
    },
    {
      title: '만세력 이야기',
      subtitle: '임대표님이 알려주는',
      type: '학습자용',
      txt1: '성향분석',
      txt2: '사주로 보는 성향분석',
      txt3: '사주 오행 분석으로 나에 대해 알아보기',
      txt4: '#키워드',
      src: '/src/assets/images_manse/thumb01.png',
    },

    {
      title: '만세력 이야기',
      subtitle: '임대표님이 알려주는',
      type: '학습자용',
      txt1: '성향분석',
      txt2: '사주로 보는 성향분석',
      txt3: '사주 오행 분석으로 나에 대해 알아보기',
      txt4: '#키워드',
      src: '/src/assets/images_manse/thumb01.png',
    },
  ],
}
</script>

<template>
  <div class="my-info-wrap">
    <div class="my-tit-box com-bg-my-box h-small">
      <div class="tit">서비스 안내</div>
      <div class="path">
        <ul>
          <li>홈</li>
          <li>서비스 상품 안내</li>
          <li>서비스 안내</li>
        </ul>
      </div>
    </div>
    <div class="myinfo-box com-bg-my-box">
      <div class="category-list-box">
        <button
          v-for="tab in tabs"
          :key="tab.value"
          class="btn-category"
          :class="{ active: currentTab === tab.value }"
          @click="currentTab = tab.value"
        >
          {{ tab.label }}
        </button>
      </div>
      <GuideProductList :prProductList="galleryItems[currentTab]" />
      <GuideProductList :prProductList="galleryItems[currentTab]" />
      <GuideProductList :prProductList="galleryItems[currentTab]" />
    </div>
  </div>
</template>
