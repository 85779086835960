<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'guide' } })
import { useRouter } from 'vue-router'

const router = useRouter()
const emit = defineEmits()

const props = defineProps({
  prProductList: {
    type: Array,
    required: true,
  },
})

const goPayView = () => {
  router.push(`/guide/productDetail`)
}
</script>
<template>
  <div class="product-card-box">
    <div class="row-box" v-for="(item, index) in prProductList" :key="index" @click="goPayView()">
      <div class="img-box">
        <!-- <img :src="item?.src" alt="" /> -->
        <!-- <div class="tits">{{ item.subtitle }}</div>
        <div class="tit">{{ item.title }}</div>
        <div class="type">{{ item.type }}</div> -->
      </div>
      <div class="text-box">
        <button class="txt-like">
          <IconLike class="icon-like active" />
        </button>
        <div class="txt1">
          <span class="txt1-1">{{ item.txt1 }}</span>
        </div>
        <div class="txt2">{{ item.txt2 }}</div>
        <div class="txt3">{{ item.txt3 }}</div>
        <div class="txt4">
          <span>{{ item.txt4 }}</span
          ><span>{{ item.txt4 }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import '/src/assets/nangman_style/product.scss';
</style>
