

import __layout_0 from '/var/www/d25_f_web_svcs/src/layouts/default.vue'
export const layouts = {
'Footer': () => import('/var/www/d25_f_web_svcs/src/layouts/Footer.vue'),
'Header': () => import('/var/www/d25_f_web_svcs/src/layouts/Header.vue'),
'analysis': () => import('/var/www/d25_f_web_svcs/src/layouts/analysis.vue'),
'auth': () => import('/var/www/d25_f_web_svcs/src/layouts/auth.vue'),
'blank': () => import('/var/www/d25_f_web_svcs/src/layouts/blank.vue'),
'customer': () => import('/var/www/d25_f_web_svcs/src/layouts/customer.vue'),
'default': __layout_0,
'empty': () => import('/var/www/d25_f_web_svcs/src/layouts/empty.vue'),
'essay': () => import('/var/www/d25_f_web_svcs/src/layouts/essay.vue'),
'guide': () => import('/var/www/d25_f_web_svcs/src/layouts/guide.vue'),
'login': () => import('/var/www/d25_f_web_svcs/src/layouts/login.vue'),
'main': () => import('/var/www/d25_f_web_svcs/src/layouts/main.vue'),
'manse': () => import('/var/www/d25_f_web_svcs/src/layouts/manse.vue'),
'mypage': () => import('/var/www/d25_f_web_svcs/src/layouts/mypage.vue'),
'print': () => import('/var/www/d25_f_web_svcs/src/layouts/print.vue'),
'white': () => import('/var/www/d25_f_web_svcs/src/layouts/white.vue'),
'components/DefaultLayoutWithHorizontalNav': () => import('/var/www/d25_f_web_svcs/src/layouts/components/DefaultLayoutWithHorizontalNav.vue'),
'components/DefaultLayoutWithVerticalNav': () => import('/var/www/d25_f_web_svcs/src/layouts/components/DefaultLayoutWithVerticalNav.vue'),
'components/Footer': () => import('/var/www/d25_f_web_svcs/src/layouts/components/Footer.vue'),
'components/NavSearchBar': () => import('/var/www/d25_f_web_svcs/src/layouts/components/NavSearchBar.vue'),
'components/NavbarShortcuts': () => import('/var/www/d25_f_web_svcs/src/layouts/components/NavbarShortcuts.vue'),
'components/NavbarThemeSwitcher': () => import('/var/www/d25_f_web_svcs/src/layouts/components/NavbarThemeSwitcher.vue'),
'components/Snackbar': () => import('/var/www/d25_f_web_svcs/src/layouts/components/Snackbar.vue'),
'components/UserProfile': () => import('/var/www/d25_f_web_svcs/src/layouts/components/UserProfile.vue'),
}
export const createGetRoutes = (router, withLayout = false) => {
  const routes = router.getRoutes()
  if (withLayout) {
      return routes
  }
  return () => routes.filter(route => !route.meta.isLayout)
}

export function setupLayouts(routes) {
  function deepSetupLayout(routes, top = true) {
    return routes.map(route => {
      if (route.children?.length > 0) {
        route.children = deepSetupLayout(route.children, false)
      }
      
      if (top && route.meta?.layout !== false) {
        return { 
          path: route.path,
          component: layouts[route.meta?.layout || 'default'],
          children: [ {...route, path: ''} ],
          meta: {
            isLayout: true
          }
        }
      }

      if (route.meta?.layout) {
        return { 
          path: route.path,
          component: layouts[route.meta?.layout],
          children: [ {...route, path: ''} ],
          meta: {
            isLayout: true
          }
        }
      }

      return route
    })
  }

    return deepSetupLayout(routes)

}
