<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'mypage' } })

import { onMounted } from 'vue'
import { useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()
const billResultMsg = ref(null)

billResultMsg.value = '월 이용권 구매가 완료되었습니다!'

onMounted(() => {
  window.gtag('event', 'conversion', {
    'send_to': 'AW-10814485333/UF0SCK_t14waENXm36Qo',
  });
})

const handleSubmit = () => {
  router.push('/manse')
}

const goService = () => {
  router.push('/mypage/subscrPayment')
}
</script>

<template>
  <div class="my-info-wrap">
    <div class="my-tit-box com-bg-my-box h-small">
      <div class="tit">월 이용권 구매</div>
      <div class="path">
        <ul>
          <li>홈</li>
          <li>마이페이지</li>
          <li>낭만 저장소</li>
          <li>월 이용권 구매</li>
        </ul>
      </div>
    </div>
    <div class="myinfo-box com-bg-my-box">
      <div class="finish-box">
        <div class="com-btn-box"><i class="icon-check"></i></div>
        <div class="gap-xxl"></div>
        <div class="fnt-big">{{ billResultMsg }}</div>
        <div class="gap-xxl"></div>
        <div class="com-btn-box">
          <VBtn type="submit" style="width: 100%" @click="handleSubmit"> 만세력 이용하기 </VBtn>
        </div>
        <div class="gap-xxl"></div>
        <div class="">
          결제 완료된 내역은 이용중인 서비스에서 확인할 수 있습니다.
          <button class="btn-txt-line" @click="goService()">이용중인 서비스 바로가기</button>
        </div>
      </div>
    </div>
  </div>
</template>
