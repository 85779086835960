<script setup>
import useFunctions from '@/composables/useFunctions'
import fiveMixin from '@/composables/fiveMixin';

const props = defineProps({
  birthData: {
    type: Object,
  },
  daeunsData: {
    type: Object,
  },
  langMode: {
    type: String,
  },
});


const { ...five } = fiveMixin();
const { ...useFun } = useFunctions();

const birthTitle = ref('시일월년');

function getYearSix(data, pos) {
  let txt = '';
  const posEng = getPosEng(pos);
  txt = data?.[posEng]?.six;
  return txt;
};

function getPosEng(pos) {
  let txt = '';
  if (pos === '년') txt = 'year';
  if (pos === '월') txt = 'month';
  if (pos === '일') txt = 'day';
  if (pos === '시') txt = 'hour';
  return txt;
};

function getValueTxt(data, pos, val) {
  let txt = '';
  const posEng = getPosEng(pos);
  txt = data?.[posEng]?.[val];
  if (val === 'sal') {
    txt = txt ? txt[0] : '';
  }  
  return txt;
}
</script>

<template>
  <VCol>
    <VCard class="chart-card row saju-card">
      <div class="data-0101-box">
        <ul
          v-for="(char, index) in birthTitle"
          :key="index"
          class="birth-item-box birth txt-big"
        >
          <li>
            <span class="txt1">{{ char }}</span>
          </li>

          <li>
            <span
              class="txt-round xl"
              :class="[five.getColorFive(getValueTxt(birthData?.sky, char, 'value' )), langMode === 'CHNKOR' ? 'chnkor' : '', langMode]"
            >
              <em v-if="langMode === 'CHNKOR'" class="chn">
                {{ five.getHanjaTxt(getValueTxt(birthData?.sky, char, 'value' ), 'sky') }}
              </em>
              <em v-if="getValueTxt(birthData?.sky, char, 'value' ) === '-'" class="time-none">{{ getValueTxt(birthData?.sky, char, 'value' ) }}</em>
              <em v-else class="txt-main">{{ getValueTxt(birthData?.sky, char, 'value' ) }}</em>
            </span>

            <span
              class="txt-round xl"
              :class="[five.getColorFive(getValueTxt(birthData?.earth, char, 'value' )), langMode === 'CHNKOR' ? 'chnkor' : '', langMode]"
            >
              <em v-if="langMode === 'CHNKOR'" class="chn">
                {{ five.getHanjaTxt(getValueTxt(birthData?.earth, char, 'value' ), 'earth') }}
              </em>
              <em v-if="getValueTxt(birthData?.sky, char, 'value' ) === '-'" class="time-none">{{ getValueTxt(birthData?.sky, char, 'value' ) }}</em>
              <em v-else class="txt-main">{{ getValueTxt(birthData?.earth, char, 'value' ) }}</em>
            </span>
          </li>
        </ul>
      </div>

      <div style="display: flex; flex: 1; justify-content: center;">
        <div class="divider"></div>
      </div>

      <div class="data-0102-box">
        <ul
          v-for="(item, index) in daeunsData?.sky"
          :key="index"
          class="birth-item-box"
          :class="{'active': prCurNum === item.num }"
          @click="clickDaeun(prType, item)"
        >
          <li>
            <span class="no">{{ item.num }}</span>
          </li>

          <li v-if="item.year">
            <span class="no-year">{{ item.year }}</span>
          </li>

          <li>
            <span
              class="txt-round"
              :class="[five.getColorFive(item.value), langMode === 'CHNKOR' ? 'chnkor' : '']"
            >
              <em v-if="langMode === 'CHNKOR'" class="chn">
                {{ five.getHanjaTxt(item.value, 'sky') }} </em><em class="txt-main">{{ item.value }}
              </em>
            </span>

            <span
              class="txt-round"
              :class="[five.getColorFive(daeunsData?.earth[index].value), langMode === 'CHNKOR' ? 'chnkor' : '']"
            >
              <em v-if="langMode === 'CHNKOR'" class="chn">
                {{ five.getHanjaTxt(daeunsData?.earth[index].value, 'earth') }}
              </em>
              <em class="txt-main">{{ daeunsData?.earth[index].value }}</em>
            </span>
          </li>
        </ul>
      </div>      
    </VCard>
  </VCol>
</template>