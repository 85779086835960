<script setup>
import { cities } from '@/api/Eoway'

const emit = defineEmits()

const popGanjiBox = true
const popAreaCity = ref('')
const areaList = ref({})
const selectAreaItem = ref(null)

const close = () => {
  emit('close')
}
const handleSearchGanji = async () => {
  const formParams = {}
  formParams.search = popAreaCity.value
  const data = await cities(formParams)
  areaList.value = data
}
const selectedArea = (item) => {
  selectAreaItem.value = item
}
const selectAreaFinish = () => {
  emit('selectAreaFinish', selectAreaItem)
}
</script>
<template>
  <VDialog
    v-model="popGanjiBox"
    class="v-dialog-sm dialog-st1"
    max-width="400px"
    @click:outside="close"
  >
    <VCard class="br16">
      <div class="pop-inner padding-tb" style="height: 600px">
        <div class="pop-tit-box tit-default">
          <div class="tit-text-d">지역 검색</div>
          <!-- <DialogCloseBtn variant="text" size="default" @click="close" /> -->
          <button class="dial-close" @click="close"></button>
        </div>
        <div class="pop-body pd-large">
          <div class="pop-search-box-title">지역 검색 (해외 출생지 포함)</div>
          <div class="pop-search-box">
            <div class="com-input-wrap">
              <VTextField
                v-model="popAreaCity"
                class="input-box sajupalza"
                variant="underlined"
                clearable
                maxlength="15"
                placeholder="도시를 검색하세요"
                @keyup.enter="handleSearchGanji()"
                @focus="wordFocus = true"
                @blur="wordFocus = false"
              ></VTextField>
            </div>
            <VBtn
              :class="['obtn', { 'disabled-button': !popAreaCity }]"
              :disabled="!popAreaCity"
              @click="handleSearchGanji()"
            >
              검색
            </VBtn>
          </div>
          <div class="pop-search-list">
            <div v-if="areaList.length > 0" class="address-list scroll">
              <div
                v-for="(item, index) in areaList"
                :key="index"
                class="group-item"
                :class="{ selected: selectAreaItem?.id === item?.id }"
                @click="selectedArea(item)"
              >
                {{ item.korCountryName }}, {{ item.korCityName }}
              </div>
            </div>
            <div v-if="0" class="result-none">
              <div class="pop-none-msg">
                <span class="s-point">{{ popAreaCity }}</span
                >에 대한 검색 결과가 없습니다.<br />
                검색어를 다시 확인해주세요.
              </div>
            </div>
          </div>
          <div v-if="areaList.length > 0">
            <div class="pop-exp">* 영국, 런던은, 표준 시에서 -30분 차감되어 계산됩니다.</div>
            <v-btn
              class="obtn w100 large"
              :class="{ 'disabled-button': !selectAreaItem?.id }"
              @click="selectAreaFinish()"
            >
              완료
            </v-btn>
          </div>
        </div>
      </div>
    </VCard>
  </VDialog>
</template>
