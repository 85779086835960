<script setup>
import { VRow } from 'vuetify/lib/components/index.mjs'

// import {popupData as detailExpList} from '../data/popupExp.js'

const props = defineProps({
  toggleProMode: {
    type: String,
    default: null,
  },
  pgmode: {
    type: String,
    default: null,
  }
})
const emit = defineEmits()
// const props = defineProps({
//     currentCardIndex: {
//         type: Number,
//         required: true
//     }
// })

// const gellaryBackExp = ref(detailExpList);

const PopManseDetailBox = ref(true)
const close = () => {
  emit('close')
}
function open() {
  PopManseDetailBox.value = true
}
// const Titles = ref(['합', '충', '형/파/해', '신살/공망/태월', '육친', '원진/귀문/현침']) // '현침' 임시 비활성화
// const Titles = ref(['합', '충', '형/파/해', '신살', '육친', '원진/귀문/공망/태월'])

// const index = ref(0);

/** 마우스 우클릭 방지 */
const disableRightClick = (event) => {
  event.preventDefault()
}

// watch(
//   () => props.currentCardIndex,
//   (newIndex) => {
//     index.value = newIndex;
//   },
//   { immediate: true }
// );

onMounted(() => {
  document.addEventListener('contextmenu', disableRightClick)
  // index.value = props.currentCardIndex
})

onBeforeUnmount(() => {
  document.removeEventListener('contextmenu', disableRightClick)
})


</script>
<template>
  <VRow
    v-model="PopManseDetailBox"
    width="482px"
    height="500px"
    max-width="100%"
    class="dialog-saju-keyword dialog-ilgan"
    :class="[
      'dialog-saju-keyword',
      toggleProMode === 'pro' ? 'dialog-ilgan-pro' : 'dialog-ilgan-nor',
      pgmode === 'rs' && 'if-rs'
    ]"
  >
    <!-- VDialog dialog-st10 -->
    <VCard class="br16">
      <div class="pop-cd-inner">
        <div class="pop-tit-box flex-sb pop-right-close">
          <div class="detail-tit">일간 설명</div>
          <button class="dial-close" @click="close"></button>
        </div>
        <div class="pop-detail-body scroll" style="height: 400px">
          <div class="" style="padding: 0 0 0 32px">
            <div class="data-exp-box">
              <!-- TODO: 데이터 js 파일로 분리 시 템플릿 데이터 바인딩({{}})으로 수정 -->
              <span class="title">일간 : 토의 오행 戊土(무토)</span>
              <!-- TODO: 데이터 js 파일로 분리 시 v-html로 수정 -->
              <!-- <div v-html="item.content" class="content-d"></div> -->
              <div class="content-d">
                천간의 6번째 글자 기토(己土)에 대해 알아보도록 하겠습니다. 기토는 십신 중
                정인(正印)과 대체적으로 유사한 성격을 가지고 있습니다. 정인의 꾸준하게 지속하고
                자기자신을 제어하는 힘과 유사하다고 볼 수 있겠습니다. 무토까지가 양의 기운이 있는
                상황이었다면 기토부터 음의 기운이 더 강하게 발현되기 시작합니다. 음 기운의 특징은
                실속을 차리고, 현실적이며, 수렴하는 운동을 통해 구체적인 결실을 만들려고 한다는
                것입니다. 나무로 치면 꽃이 거의 지고 열매의 모양이 만들어지는 시기라고 볼 수
                있습니다. 하지만 아직 열매가 완전히 만들어진 것은 아닙니다. 열매가 만들어지는 단계는
                금(金)의 단계 입니다. 기토는 그 금의 단계를 준비하는 단계입니다. 토 답게 포용력이
                있지만 그럼에도 실속을 따지는 음토이기 때문에 자기중심적인 경향이 강하게 나타납니다.
                기토는 철저하게 자기 자신을 통제합니다. 어떻게 보면 자기개발을 잘 한다고도 볼 수
                있습니다. 그렇게 하는 이유는 내가 가지고 있는 것 안에서 최대한의 퍼포먼스를 내기
                위함입니다. 현재 내가 갖고 있는 것을 최대한 가꾸려고 합니다. 그런 의미에서 현재 가장
                내가 잘 통제할 수 있는 것은 바로 나 자신입니다. 또한 이익과 관련해서도 일단 나
                자신이 중요합니다. 내 텃밭이 잘 채워져야 그 다음이 있다고 생각합니다. 기토는
                무토처럼 텃밭을 잘 가꾸지 않고 이곳저곳을 다니는걸 싫어합니다. 하지만 동시에 그런
                무토를 부러워하기도 합니다. 자기가 정해놓은 작은 영역 안에서는 잘 하지만 밖으로
                나가는걸 두려워하기 때문입니다. 자칫 잘못하면 이렇게 내 텃밭을 잘 가꾸는 일이
                무균실과 같은 상태가 될 수 있습니다. 무균실은 좁은 공간을 균이 없는 멸균상태로 만든
                방입니다. 면역력이 떨어져 있는 환자들에게 필요한 곳입니다. 건강을 회복하기 까지 그
                무균실은 너무나 귀중한 역할을 합니다. 그런데 건강을 회복했는데도 무균실에만 있으면
                문제가 됩니다. 만약 계속해서 무균실의 삶을 산다면 바깥의 아름다움은 구경하지 못할
                뿐더러 사람들과 교류하고 일하는걸 할 수가 없습니다. 면역력은 계속 약해집니다. 외부의
                병균을 마주칠 일이 없으니 말입니다. 그렇게 되면 자그마한 충격에도 화들짝 놀라게 되고
                회복하는데 오래걸리는 충격을 받게 됩니다. 그러니 기토는 내 것을 소중하게 여기는것
                자체는 좋지만 시야를 바깥으로 넓게 보는게 필요합니다. 기토는 디테일합니다. 땅을
                비옥하게 하기 위해서는 질좋은 비료도 주어야 하고 잘 솎아내기도 해야합니다. 잡초도
                뽑아줘야 합니다. 이런건 하나하나 세밀하게 보는 눈이 있어야 가능합니다. 이러한
                디테일은 인간관계에도 적용됩니다. 좋게 발현되면 상대를 세심하게 신경써줄 수
                있습니다. 미처 인식하지 못한 부분에 있어서도 상대를 배려해주고 꼼꼼히 준비해서
                만족시켜줍니다. 하지만 안좋게 발현되면 상대와의 관계에서 나의 이득을 위해 디테일한
                부분까지 따집니다. 시야를 좁게 보다 보니 인간관계에서 더 크게 봐야할 관계적인 측면을
                무시할 수 있습니다. 특유의 내것을 소중하게 여기는 마음이 잘못하면 이기적이고
                디테일하게 상대에게서 이득을 취하게 될 수 있는 것입니다. 내가 가지고 있는 땅을 예쁜
                화단으로 만드려면 다른 누군가의 땅에서 꽃을 갖고
              </div>
            </div>
            <div class="next-btn-area"></div>
          </div>
        </div>
      </div>
    </VCard>
  </VRow>
</template>
<style lang="scss" scoped>
.tab-title {
  display: flex;
  flex-direction: row;
}
.keyword-title {
  background-color: #ebebeb;
  margin: 0px 3px;
  cursor: pointer;
  border-radius: 10px;
}
</style>
