// useMenuLinks.js
import { DEV_MODE } from '@/main'

export default function useMenuLinks() {
  const menuLinks = {
    home: {
      name: '홈',
      link: '/main',
      subMenu: [],
    },
    calendar: {
      name: '역학달력',
      link: '/calendar',
      subMenu: [],
    },
    manse: {
      name: '사주입력',
      link: '/manse',
      subMenu: [],
    },
    savelist: {
      name: '저장목록',
      link: '/saju',
      subMenu: [],
    },
    notice: {
      name: '낭만센터',
      link: '/customer/notice',
      subMenu: [
        { name: '공지사항', link: '/customer/notice', bid: 2325 },
        { name: '이벤트', link: '/customer/event', bid: 2480 },
        { name: '낭만 에세이', link: '/customer/essay', bid: 2509 },
        { name: '자주 묻는 질문', link: '/customer/faq', bid: 2434 },
        { name: '1:1 문의', link: '/customer/inquiry', bid: 2336 },
        { name: '명리상담', link: '/customer/counseling', bid: 2508 },
      ],
    },
  }

  // DEV_MODE가 true일 때만 guidelist 추가
  if (DEV_MODE) {
    menuLinks.guidelist = {
      name: '서비스 안내',
      link: '/guide/info',
      subMenu: [
        { name: '서비스 안내', link: '/guide/info' },
        { name: '분석권 상품 소개', link: '/guide/product' },
        { name: '저장소 정기구독', link: '/guide/subsrc' },
        { name: '월 이용권 구매', link: '/guide/month' },
      ],
      className: 'st-guide devOnly',
    }
  }
  return { menuLinks }
}
