<script setup>
const emit = defineEmits(['close'])
const close = () => {
  emit('close')
}
/** 마우스 우클릭 방지 */
const disableRightClick = (event) => {
  event.preventDefault();
};

onMounted(() =>{
    document.addEventListener("contextmenu", disableRightClick);
})
onBeforeUnmount(() => {
  document.removeEventListener("contextmenu", disableRightClick);
});

const PopSummerTimeBox = ref(true);
</script>

<template>
<VDialog
    v-model="PopSummerTimeBox"
    width="800px"
    height="746px"
    max-width="100%"
    class="dialog-st10 dialog-summer"
    :style="{ overflow: 'hidden' }"
  >
  <VCard class="br16">
    <div class="pop-cd-inner">

        <div class="pop-tit-box flex-sb pop-right-close">
          <div class="detail-tit">서머타임 안내</div>
          <!-- <DialogCloseBtn class="dialog-close" variant="text" size="default" @click="close" /> -->
          <button class="dial-close" @click="close"></button>
        </div>

        <div class="pop-detail-body">
            <div class="description">
                <div class="title">
                    서머타임이란?
                </div>
                <div class="content-st">
                    서머타임(Summer time, ST) 또는 일광 절약 시간제(Daylight saving time, DST/DT)는 
                    특히 하절기에 국가의 표준시를 원래 시간보다 한 시간 앞당겨 사용하는 것을 말합니다.
                </div>
            </div>
            <div class="img-box">
              <div class="summertime-img"></div>
            </div>
        </div>
    </div>
  </VCard>
  </VDialog>
</template>
<style lang="scss" scoped>
.pop-detail-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .img-box {
        padding-bottom: 20px;
        overflow: auto;

        .summertime-img{
            background: url('@manseImages/img_summer_time.png') center center no-repeat;
            background-size: cover;
            width: 736px;
            height: 538px;
        }
    }
}
.description{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    .title{
        color: var(--Gray-800, #333);
        font-family: Pretendard;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: -0.375px;
    }
    .content-st{
        color: var(--Gray-800, #333);
        font-family: Pretendard;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
}
</style>