<script setup>
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'

const { ...five } = fiveMixin()
const { ...useFun } = useFunctions()
const props = defineProps({
  prItem: {
    type: Object,
    default: null,
  },
  storedLangMode: {
    type: String,
    default: 'CHNKOR',
  },
  prItemMode: {
    type: String,
    default: 'one',
  },
  prSelectdaeun: {
    type: Number,
    default: 0,
  },
  isPrint: {
    type: Boolean,
    default: false,
  },
})
const currentYear = ref(new Date().getFullYear())
const isActiveYear = (year) => {
  return year === currentYear.value
}
onMounted(() => {})

const showHanjaNew = (value, pos) => {
  if (!value) {
    return ''
  }
  return props.storedLangMode === 'CHNKOR' ? five.getHanjaTxt(value, pos) : value
}
</script>
<template>
  <div>
    <!-- <div
      v-for="(item, index) in prItem"
      :key="index"
      class="pt-row-box"
      :class="{ 'half-line': index % 2 === 1 }"
    > -->
    <div
      v-for="(item, index) in prItem"
      :key="index"
      class="pt-row-box"
      :class="{
        'half-line':
          (prItemMode === 'three' &&
            (prSelectdaeun % 10 === 0 ||
              prSelectdaeun % 10 === 6 ||
              prSelectdaeun % 10 === 7 ||
              prSelectdaeun % 10 === 8 ||
              prSelectdaeun % 10 === 9) &&
            !(index % 2 === 1)) ||
          (prItemMode === 'three' &&
            (prSelectdaeun % 10 === 1 ||
              prSelectdaeun % 10 === 2 ||
              prSelectdaeun % 10 === 3 ||
              prSelectdaeun % 10 === 4 ||
              prSelectdaeun % 10 === 5) &&
            index % 2 === 1) ||
          ((prItemMode === 'two' || prItemMode === 'four') &&
            (prSelectdaeun % 10 === 1 ||
              prSelectdaeun % 10 === 2 ||
              prSelectdaeun % 10 === 3 ||
              prSelectdaeun % 10 === 5 ||
              prSelectdaeun % 10 === 4) &&
            !(index % 2 === 1)) ||
          ((prItemMode === 'two' || prItemMode === 'four') &&
            !(
              prSelectdaeun % 10 === 1 ||
              prSelectdaeun % 10 === 2 ||
              prSelectdaeun % 10 === 3 ||
              prSelectdaeun % 10 === 5 ||
              prSelectdaeun % 10 === 4
            ) &&
            index % 2 === 1) ||
          (prItemMode === 'one' && index % 2 === 1),
      }"
    >
      <div class="tit" :class="{isPrint: isPrint}">
        <span
          v-if="item.value.length > 2"
          class="txt1 ganji-txt"
          :class="[five.getColorFive(item?.daeun)]"
        >
          {{ showHanjaNew(item.daeun, item.skyEarth) }}
          <!-- {{ item.daeun }} -->
        </span>
        <span v-if="item.value.length > 2" class="txt2">
          {{ item.daeunsix }}
        </span>
      </div>
      <div v-for="(item2, index2) in item.value" :key="index2" class="text-box" :class="{isPrint: isPrint}">
        <div :class="['year', { active: isActiveYear(item2.year) }]">
          {{ item2.year }}
        </div>
        <div :class="['txt1', { active: isActiveYear(item2.year) }]">
          <span v-if="isActiveYear(item2.year)" class="txt1-sub">
            <span :class="['ganji-txt', five.getColorFive(item2.yearju[0])]">{{
              showHanjaNew(item2.yearju[0], 'sky')
            }}</span>
            <span :class="['ganji-txt', five.getColorFive(item2.yearju[1])]">{{
              showHanjaNew(item2.yearju[1], 'earth')
            }}</span>
          </span>
          <span v-else class="txt1-sub">
            <span>{{ showHanjaNew(item2.yearju[0], 'sky') }}</span>
            <span>{{ showHanjaNew(item2.yearju[1], 'earth') }}</span>
          </span>
        </div>
        <div :class="['txt2', { active: isActiveYear(item2.year) }]">
          {{ item2.six }}
        </div>
        <div :class="['no', { active: isActiveYear(item2.year) }]">
          {{ item2.age }}
        </div>
      </div>
    </div>
  </div>
</template>
