import axios from "axios";

const endpoint = import.meta.env.VITE_APP_GW_URL;
const systemId = import.meta.env.VITE_APP_SYSTEMID;
const filterService = `${endpoint}/eoway/userFilter`

export const addUserSelectOptionAPI = async (data) => {
  const url = filterService;
  const response = await axios.post(url, data);
  return response.data;
}

export const getUserSelectOptionAPI = async (userId) => {
  const url = `${filterService}/users/${userId}`;
  const params = {
    systemId,
  };
  const response = await axios.get(url, {params});
  return response.data;
}

export const setUserSelectOptionAPI = async (data) => {
  const url = filterService;
  const response = await axios.put(url, data);
  return response.data;
}